import {
  EuiButton,
  EuiCallOut,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiSwitch,
  EuiTextArea,
} from "@elastic/eui";
import { FormikErrors, FormikProps, withFormik } from "formik";
import React from "react";
import {
  Order,
  OrderUpdateAddPost,
  OrderUpdateAddService,
} from "../../../api/resources/order";
import { Organization } from "../../../api/resources/organization";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import { isLoaded } from "../../../api/useService";
import { UserPreferences } from "../../../utils/useUserPreferences";

interface Fields {
  message: string;
  sendUpdateEmail: boolean;
}

type InnerFormProps = {
  authState: AuthStateAuthed;
  organization: Organization;
  order: Order;
} & FormikProps<Fields>;

const InnerForm = ({
  authState,
  organization,
  errors,
  touched,
  handleSubmit,
  isSubmitting,
  getFieldProps,
  order,
  values,
  setFieldValue,
}: InnerFormProps) => (
  <EuiForm component="form" onSubmit={handleSubmit}>
    <EuiFormRow
      label="Meddelande"
      isInvalid={!!touched.message && !!errors.message}
      error={errors.message}
    >
      <EuiTextArea
        isInvalid={!!touched.message && !!errors.message}
        disabled={isSubmitting}
        {...getFieldProps<string>("message")}
      />
    </EuiFormRow>
    {isAdmin(authState) &&
      (order.updates_email || organization.error_case_emails.length ? (
        <EuiFormRow
          isInvalid={!!touched.sendUpdateEmail && !!errors.sendUpdateEmail}
          error={errors.sendUpdateEmail}
        >
          <EuiSwitch
            label={
              "Skicka uppdatering till " +
              (order.updates_email || organization.order_emails.join(", "))
            }
            checked={values.sendUpdateEmail}
            onChange={(e) => setFieldValue("sendUpdateEmail", e.target.checked)}
          />
        </EuiFormRow>
      ) : (
        <EuiFormRow>
          <EuiCallOut
            title="Statusuppdatering kan inte skickas"
            color="warning"
            iconType="iInCircle"
          >
            Ordern och organisationen saknar mejladress.
          </EuiCallOut>
        </EuiFormRow>
      ))}

    <EuiSpacer />

    <EuiButton type="submit" fill isLoading={isSubmitting}>
      Lägg till
    </EuiButton>
  </EuiForm>
);

interface Props {
  authState: AuthStateAuthed;
  organization: Organization;
  order: Order;
  onSubmit: OrderUpdateAddPost;
  orderUpdateAddService: OrderUpdateAddService;
  userPreferences: UserPreferences;
  updateUserPreferences(values: Partial<UserPreferences>): void;
}

export const OrderAddUpdateForm = withFormik<Props, Fields>({
  displayName: "AddUpdateForm",
  mapPropsToValues: ({ authState, userPreferences }) => ({
    message: "",
    sendUpdateEmail: isAdmin(authState)
      ? userPreferences.sendOrderUpdateEmail
      : true,
  }),
  validate: (values, { order }) => {
    const errors: FormikErrors<Fields> = {};

    if (!values.message) {
      errors.message = "Saknas";
    } else {
      const charsOver = values.message.length - 10240;
      if (charsOver > 0) {
        errors.message = `${charsOver} tecken för långt. Försök att korta ned texten.`;
      }
    }

    return errors;
  },
  handleSubmit: async (values, { props, setValues, resetForm }) => {
    const normalized: typeof values = {
      message: values.message.trim(),
      sendUpdateEmail: values.sendUpdateEmail,
    };

    setValues(normalized);
    props.updateUserPreferences({
      sendOrderUpdateEmail: normalized.sendUpdateEmail,
    });

    const result = await props.onSubmit(normalized);

    if (isLoaded(result)) {
      resetForm();
    }
  },
})(InnerForm);

export default OrderAddUpdateForm;
