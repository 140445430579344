import { useCallback, useEffect } from "react";
import useCache from "../../utils/useCache";
import { AuthStateAuthed, AuthStateAdmin } from "../useAuthService";
import {
  buildApiHref,
  isLoaded,
  useAutoGetService,
  useService,
} from "../useService";

export interface Organization {
  id: number;
  name: string;
  customer_number: number | null;
  contact_details: string;
  number_prefix: string;
  order_emails: string[];
  error_case_emails: string[];
}

interface OrganizationsResponse {
  items: Organization[];
}

export const organizationsApiHref = () => buildApiHref("organizations");

export const useOrganizationsService = (authState: AuthStateAdmin) =>
  useAutoGetService<OrganizationsResponse>(authState, organizationsApiHref());

export const organizationApiHref = (id: number) =>
  buildApiHref(`organizations/${id}`);

export const useOrganizationService = (
  authState: AuthStateAuthed,
  id: number
) => useAutoGetService<Organization>(authState, organizationApiHref(id));

export interface OrganizationAddParams {
  name: string;
  customerNumber: number;
  contactDetails: string;
  orderEmails: string[];
  errorCaseEmails: string[];
  numberPrefix: string;
}

export const useOrganizationAddService = (authState: AuthStateAdmin) => {
  const { service, post: origPost } = useService<Organization>(authState);

  const post = useCallback(
    (params: OrganizationAddParams) =>
      origPost(organizationsApiHref(), {
        name: params.name,
        customer_number: params.customerNumber,
        contact_details: params.contactDetails,
        order_emails: params.orderEmails,
        error_case_emails: params.errorCaseEmails,
        number_prefix: params.numberPrefix,
      }),
    [origPost]
  );

  const { setCache, removeCache } = useCache();
  useEffect(() => {
    if (isLoaded(service)) {
      setCache(organizationApiHref(service.payload.id), service);
    }
  }, [service, setCache, removeCache]);

  return [service, post] as const;
};

type UseOrganizationAddReturn = ReturnType<typeof useOrganizationAddService>;
export type OrganizationAddService = UseOrganizationAddReturn[0];
export type OrganizationAddPost = UseOrganizationAddReturn[1];

export interface OrganizationUpdateParams {
  name: string;
  customerNumber: number | null;
  contactDetails: string;
  orderEmails: string[];
  errorCaseEmails: string[];
}

export const useOrganizationUpdateService = (
  authState: AuthStateAdmin,
  id: number
) => {
  const { service, patch: origPatch } = useService<Organization>(authState);

  const patch = useCallback(
    (params: OrganizationUpdateParams) =>
      origPatch(organizationApiHref(id), {
        name: params.name,
        customer_number: params.customerNumber,
        contact_details: params.contactDetails,
        order_emails: params.orderEmails,
        error_case_emails: params.errorCaseEmails,
      }),
    [origPatch, id]
  );

  const { setCache, removeCache } = useCache();
  useEffect(() => {
    if (isLoaded(service)) {
      setCache(organizationApiHref(service.payload.id), service);
      // Extra cache invalidation
      removeCache(organizationsApiHref(), true);
    }
  }, [service, setCache, removeCache]);

  return [service, patch] as const;
};
