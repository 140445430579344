import {
  EuiButton,
  EuiErrorBoundary,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPageTemplate,
  EuiSpacer,
  EuiSwitch,
} from "@elastic/eui";
import { FormikErrors, FormikProps, withFormik } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { AuthState } from "../api/useAuthService";
import propertyOf from "../utils/propertyOf";

interface Props {
  authState: AuthState;
  onLogin2fa: (
    code: string,
    trustDeviceName: string | null
  ) => Promise<unknown>;
  onCancel(): void;
}

export function Login2fa({ authState, onLogin2fa, onCancel }: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Logga in med SMS-kod - Portalen";
  });

  useEffect(() => {
    if (authState.status === "authed") {
      // Navigate to the requested page, if any
      navigate(location.state?.redirect || "/");
    } else if (authState.status === "password-expired") {
      // Navigate to password change page
      navigate("/byt-lösenord", { state: location.state });
    } else if (
      authState.status === "unauthed" ||
      authState.status === "error"
    ) {
      // Needs a regular login first
      navigate("/logga-in", { state: location.state });
    }
  }, [authState.status, location.state, navigate]);

  return (
    <EuiFlexGroup gutterSize="none" style={{ minHeight: "70vh" }}>
      <EuiErrorBoundary>
        <EuiPageTemplate>
          <EuiPageTemplate.EmptyPrompt>
            <Login2faForm
              authState={authState}
              onLogin2fa={onLogin2fa}
              onCancel={onCancel}
            />
          </EuiPageTemplate.EmptyPrompt>
        </EuiPageTemplate>
      </EuiErrorBoundary>
    </EuiFlexGroup>
  );
}

interface Login2faFormFields {
  code: string;
  trustThisDevice: boolean;
  trustDeviceName: string;
}

type InnerLogin2faFormProps = {
  authState: AuthState;
  onCancel(): void;
} & FormikProps<Login2faFormFields>;

function InnerLogin2faForm({
  authState,
  errors,
  touched,
  handleSubmit,
  isSubmitting,
  getFieldProps,
  setTouched,
  onCancel,
  values,
  setFieldValue,
}: InnerLogin2faFormProps) {
  useEffect(() => {
    if (!authState.isLoading && authState.status === "error") {
      // Setting to touched will force a revalidation and show backend errors
      setTouched({ code: true });
    }
  }, [authState.status, authState.isLoading, setTouched]);

  return (
    <EuiForm component="form" onSubmit={handleSubmit} noValidate>
      <EuiFormRow
        label="SMS-kod"
        isInvalid={!!touched.code && !!errors.code}
        error={errors.code}
      >
        <EuiFieldText
          autoComplete="one-time-code"
          disabled={isSubmitting}
          isInvalid={!!touched.code && !!errors.code}
          inputMode="numeric"
          {...getFieldProps<string>("code")}
        />
      </EuiFormRow>
      <EuiFormRow helpText="Lägger till enheten som betrodd, vilket gör att SMS-kod inte behövs vid inloggning. Du kan redigera dina betrodda enheter under kontoinställningar.">
        <EuiSwitch
          label="Fråga inte igen på denna enheten"
          disabled={isSubmitting}
          checked={values.trustThisDevice}
          onChange={(e) =>
            setFieldValue(
              propertyOf<Login2faFormFields>("trustThisDevice"),
              e.target.checked
            )
          }
        />
      </EuiFormRow>
      {values.trustThisDevice && (
        <EuiFormRow
          label="Enhetsnamn (valfritt)"
          helpText="Enhetsnamn gör det lättare att känna igen dina betrodda enheter."
          isInvalid={!!touched.trustDeviceName && !!errors.trustDeviceName}
          error={errors.trustDeviceName}
        >
          <EuiFieldText
            disabled={isSubmitting}
            isInvalid={!!errors.trustDeviceName}
            placeholder="Ex. Jobblaptop, Mobilen"
            {...getFieldProps<string>(
              propertyOf<Login2faFormFields>("trustDeviceName")
            )}
          />
        </EuiFormRow>
      )}

      <EuiSpacer />

      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiButton type="submit" fill isLoading={isSubmitting}>
            Logga in
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButton type="button" iconType="arrowLeft" onClick={onCancel}>
            Börja om
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiForm>
  );
}

interface Login2faFormProps {
  authState: AuthState;
  onLogin2fa(code: string, trustDeviceName: string | null): Promise<unknown>;
  onCancel(): void;
}

const Login2faForm = withFormik<Login2faFormProps, Login2faFormFields>({
  displayName: "Login2faForm",
  mapPropsToValues: () => ({
    code: "",
    trustThisDevice: false,
    trustDeviceName: "",
  }),
  handleSubmit: async (values, { props, setValues, setTouched }) => {
    const normalized: typeof values = {
      code: values.code,
      trustThisDevice: values.trustThisDevice,
      trustDeviceName: values.trustDeviceName.trim(),
    };

    setValues(normalized);

    await props.onLogin2fa(
      normalized.code,
      normalized.trustThisDevice ? normalized.trustDeviceName : null
    );

    // Set to touched so that backend errors are shown
    setTouched({ code: true }, true);
  },
  validate: (values, { authState }) => {
    const errors: FormikErrors<Login2faFormFields> = {};

    // Check errors from backend
    if (
      authState.status === "require-2fa" &&
      authState.invalidCode === values.code
    ) {
      errors.code = "Felaktig kod";
    }

    if (!values.code) {
      errors.code = "Saknas";
    } else if (values.code.length < 4) {
      errors.code = "För kort";
    }

    if (
      values.trustThisDevice &&
      values.trustDeviceName &&
      values.trustDeviceName.length > 128
    ) {
      const charsOver = values.trustDeviceName.length - 128;
      errors.trustDeviceName = `${charsOver} tecken för långt`;
    }

    return errors;
  },
})(InnerLogin2faForm);

export default Login2fa;
