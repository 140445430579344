import {
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiTextArea,
} from "@elastic/eui";
import emailValidator from "email-validator";
import { FormikErrors, FormikProps, withFormik } from "formik";
import React from "react";
import { OrganizationAddPost } from "../../../api/resources/organization";
import { AuthStateAuthed } from "../../../api/useAuthService";
import ComboRow from "../../../utils/form/ComboBoxRow";
import propertyOf from "../../../utils/propertyOf";

interface Fields {
  name: string;
  customerNumber: string;
  contactDetails: string;
  orderEmails: string[];
  errorCaseEmails: string[];
  numberPrefix: string;
}

type InnerFormProps = FormikProps<Fields>;

const InnerForm = ({
  errors,
  touched,
  handleSubmit,
  isSubmitting,
  getFieldProps,
}: InnerFormProps) => (
  <EuiForm component="form" onSubmit={handleSubmit}>
    <EuiFormRow
      label="Namn"
      isInvalid={!!touched.name && !!errors.name}
      error={errors.name}
    >
      <EuiFieldText {...getFieldProps<string>("name")} />
    </EuiFormRow>
    <EuiFormRow
      label="Kundnummer"
      isInvalid={!!touched.customerNumber && !!errors.customerNumber}
      error={errors.customerNumber}
    >
      <EuiFieldText
        inputMode="numeric"
        {...getFieldProps<string>("customerNumber")}
      />
    </EuiFormRow>
    <EuiFormRow
      label="Nummerprefix"
      helpText="Prefix för genererade förbindelse/order/felärende-nummer."
      isInvalid={!!touched.numberPrefix && !!errors.numberPrefix}
      error={errors.numberPrefix}
    >
      <EuiFieldText {...getFieldProps<string>("numberPrefix")} />
    </EuiFormRow>
    <EuiFormRow
      label="Kontaktuppgifter"
      isInvalid={!!touched.contactDetails && !!errors.contactDetails}
      error={errors.contactDetails}
    >
      <EuiTextArea {...getFieldProps<string>("contactDetails")} />
    </EuiFormRow>
    <ComboRow
      name={propertyOf<Fields>("orderEmails")}
      label="Mejl för orderstatus"
      placeholder="Lägga till mejladresser"
    />
    <ComboRow
      name={propertyOf<Fields>("errorCaseEmails")}
      label="Mejl för felärenden"
      placeholder="Lägga till mejladresser"
    />

    <EuiSpacer />

    <EuiButton type="submit" iconType="plus" fill isLoading={isSubmitting}>
      Skapa
    </EuiButton>
  </EuiForm>
);

interface Props {
  onSubmit: OrganizationAddPost;
  authState: AuthStateAuthed;
}

export const OrganizationCreateForm = withFormik<Props, Fields>({
  displayName: "OrganizationCreateForm",
  mapPropsToValues: () => ({
    name: "",
    customerNumber: "",
    contactDetails: "",
    orderEmails: [],
    errorCaseEmails: [],
    numberPrefix: "",
  }),
  validate: (values) => {
    const errors: FormikErrors<Fields> = {};

    if (!values.name) {
      errors.name = "Saknas";
    } else {
      const charsOver = values.name.length - 128;
      if (charsOver > 0) {
        errors.name = `${charsOver} tecken för långt`;
      }
    }

    if (values.customerNumber) {
      if (!/^\d*$/.test(values.customerNumber)) {
        errors.customerNumber = "Måste vara ett positivt heltal";
      } else if (values.customerNumber.length > 9) {
        errors.customerNumber = "För högt";
      }
    }

    if (values.contactDetails) {
      const charsOver = values.contactDetails.length - 10240;
      if (charsOver > 0) {
        errors.contactDetails = `${charsOver} tecken för långt`;
      }
    }

    for (const email of values.orderEmails) {
      const charsOver = email.length - 128;
      if (charsOver > 0) {
        errors.orderEmails = `Adressen '${email}' är ${charsOver} tecken för lång`;
      } else if (!emailValidator.validate(email)) {
        errors.orderEmails = `Adressen '${email}' har ogiltigt format`;
      }
    }

    for (const email of values.errorCaseEmails) {
      const charsOver = email.length - 128;
      if (charsOver > 0) {
        errors.errorCaseEmails = `Adressen '${email}' är ${charsOver} tecken för lång`;
      } else if (!emailValidator.validate(email)) {
        errors.errorCaseEmails = `Adressen '${email}' har ogiltigt format`;
      }
    }

    if (!values.numberPrefix) {
      errors.numberPrefix = "Saknas";
    } else {
      const charsOver = values.numberPrefix.length - 16;
      if (charsOver > 0) {
        errors.numberPrefix = `${charsOver} tecken för långt`;
      }
    }

    return errors;
  },
  handleSubmit: async (values, { props, setValues }) => {
    const normalized: typeof values = {
      name: values.name.trim(),
      customerNumber: values.customerNumber,
      contactDetails: values.contactDetails.trim(),
      orderEmails: values.orderEmails.map((e) => e.trim()).filter((e) => !!e),
      errorCaseEmails: values.errorCaseEmails
        .map((e) => e.trim())
        .filter((e) => !!e),
      numberPrefix: values.numberPrefix.trim(),
    };

    setValues(normalized);

    await props.onSubmit({
      ...normalized,
      customerNumber: parseInt(normalized.customerNumber, 10),
    });
  },
})(InnerForm);

export default OrganizationCreateForm;
