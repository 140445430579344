import { EuiFormRow, EuiSelect, EuiSelectOption } from "@elastic/eui";
import { useField } from "formik";
import React from "react";

interface Props {
  name: string;
  label: string;
  helpText?: string;
  options: EuiSelectOption[];
  isLoading?: boolean;
}

export function SelectRow({
  name,
  label,
  helpText,
  options,
  isLoading,
}: Props) {
  const [field, meta, helper] = useField(name);

  return (
    <EuiFormRow
      label={label}
      helpText={helpText}
      isInvalid={meta.touched && !!meta.error}
      error={meta.error}
    >
      <EuiSelect
        options={options}
        value={field.value}
        isLoading={isLoading}
        onBlur={() => helper.setTouched(true)}
        onChange={(e) => helper.setValue(e.target.value)}
      />
    </EuiFormRow>
  );
}

export default SelectRow;
