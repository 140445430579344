import {
  EuiButton,
  EuiCallOut,
  EuiDelayRender,
  EuiDescriptionList,
  EuiDescriptionListDescription,
  EuiDescriptionListTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import React, { useMemo, useRef } from "react";
import {
  ErrorCase,
  ErrorCaseStatus,
  useErrorCaseUpdateAddService,
} from "../../../api/resources/errorCase";
import { useOrganizationService } from "../../../api/resources/organization";
import { useServiceService } from "../../../api/resources/service";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../../api/useService";
import Page from "../../../Page";
import assertNever from "../../../utils/assertNever";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import useUserPreferences from "../../../utils/useUserPreferences";
import ServiceDescription from "../../../widgets/ServiceDescription";
import ErrorCaseAssignedUser from "./ErrorCaseAssignedUser";
import ErrorCaseUpdateAdd from "./ErrorCaseUpdateAdd";
import ErrorCaseUpdates from "./ErrorCaseUpdates";

export const errorCaseHref = (errorCaseId: number) =>
  `/felärenden/${errorCaseId}`;

export function errorCaseStatusToString(status: ErrorCaseStatus): string {
  switch (status) {
    case "open":
      return "Pågår";
    case "closed":
      return "Avslutad";
    default:
      return status;
  }
}

interface Props {
  errorCase: ErrorCase;
  authState: AuthStateAuthed;
}

export function ErrorCasePage({ errorCase, authState }: Props) {
  const [addUpdateService, addUpdate] = useErrorCaseUpdateAddService(
    authState,
    errorCase.organization_id,
    errorCase.id
  );
  const [userPreferences, updateUserPreferences] = useUserPreferences();
  const organizationService = useOrganizationService(
    authState,
    errorCase.organization_id
  );

  const updateFormRef = useRef<HTMLDivElement>(null);

  const actions = useMemo(() => {
    const actions = [];

    if (authState.user.errorCasePrivileges === "manage") {
      actions.push(
        <EuiButton onClick={() => updateFormRef.current?.scrollIntoView()}>
          Lägg till meddelande
        </EuiButton>
      );
    }

    return actions;
  }, [authState.user.errorCasePrivileges]);

  return (
    <Page title="Felärende" actionItems={actions}>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText>
            <h3>Ärendeinfo</h3>
          </EuiText>
          <EuiDescriptionList>
            <EuiDescriptionListTitle>Ärende</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {errorCase.number}
            </EuiDescriptionListDescription>
            <EuiDescriptionListTitle>Status</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {errorCaseStatusToString(errorCase.status)}
            </EuiDescriptionListDescription>
            <EuiDescriptionListTitle>
              Mejladress för statusuppdateringar
            </EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {isLoaded(organizationService) &&
                (errorCase.updates_email
                  ? errorCase.updates_email
                  : organizationService.payload.error_case_emails.length
                  ? organizationService.payload.error_case_emails.map(
                      (email, index) => <div key={index}>{email}</div>
                    )
                  : "Saknas")}
              {isLoading(organizationService) && (
                <EuiDelayRender>
                  <EuiLoadingSpinner size="s" />
                </EuiDelayRender>
              )}
              {isError(organizationService) && (
                <ServiceErrorMessage error={organizationService} />
              )}
            </EuiDescriptionListDescription>
            {isAdmin(authState) && (
              <>
                <EuiDescriptionListTitle>Ansvarig</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>
                  <ErrorCaseAssignedUser
                    authState={authState}
                    errorCase={errorCase}
                  />
                </EuiDescriptionListDescription>
              </>
            )}
          </EuiDescriptionList>
        </EuiFlexItem>
        {errorCase.service_id && (
          <EuiFlexItem>
            <ServiceColumn
              authState={authState}
              serviceId={errorCase.service_id}
            />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      <EuiSpacer />
      <ErrorCaseUpdates errorCase={errorCase} />
      {isError(addUpdateService) && (
        <EuiCallOut
          title="Kunde inte spara uppdatering"
          color="danger"
          iconType="alert"
        >
          <ServiceErrorMessage error={addUpdateService} />
        </EuiCallOut>
      )}
      <div ref={updateFormRef}>
        {authState.user.errorCasePrivileges === "manage" &&
          isLoaded(organizationService) && (
            <ErrorCaseUpdateAdd
              authState={authState}
              organization={organizationService.payload}
              errorCase={errorCase}
              addUpdateService={addUpdateService}
              onSubmit={addUpdate}
              userPreferences={userPreferences}
              updateUserPreferences={updateUserPreferences}
            />
          )}
      </div>
    </Page>
  );
}

interface ServiceColumnProps {
  authState: AuthStateAuthed;
  serviceId: number;
}

function ServiceColumn({ authState, serviceId }: ServiceColumnProps) {
  const serviceService = useServiceService(authState, serviceId);

  switch (serviceService.status) {
    case "error":
      return (
        <EuiCallOut
          title="Förbindelsen gick inte att hämta"
          color="danger"
          iconType="alert"
        >
          <ServiceErrorMessage error={serviceService} />
        </EuiCallOut>
      );
    case "loading":
      return (
        <EuiDelayRender>
          <EuiLoadingSpinner />
        </EuiDelayRender>
      );
    case "loaded":
      return (
        <ServiceDescription
          authState={authState}
          service={serviceService.payload}
        />
      );
    default:
      assertNever(serviceService);
  }
}

export default ErrorCasePage;
