import { useCallback, useEffect } from "react";
import useCache from "../../utils/useCache";
import { AuthStateAuthed } from "../useAuthService";
import {
  buildApiHref,
  isLoaded,
  useAutoGetService,
  useService,
} from "../useService";

export interface TrustedDevice {
  id: number;
  user_id: number;
  ttl: number;
  created: string;
  last_use: string;
  name: string;
  user_agent: string;
}

interface ListResponse {
  items: TrustedDevice[];
}

export const trustedDevicesApiHref = () => buildApiHref("trusted-devices");

export const useTrustedDevicesService = (authState: AuthStateAuthed) =>
  useAutoGetService<ListResponse>(authState, trustedDevicesApiHref());

export const trustedDeviceApiHref = (trustedDevicesId: number) =>
  buildApiHref(`trusted-devices/${trustedDevicesId}`);

export interface TrustedDeviceUpdateParams {
  name: string;
}

export const useTrustedDeviceUpdateService = (
  authState: AuthStateAuthed,
  id: number
) => {
  const { service, patch: origPatch } = useService<TrustedDevice>(authState);

  const patch = useCallback(
    (params: TrustedDeviceUpdateParams) =>
      origPatch(trustedDeviceApiHref(id), params),
    [origPatch, id]
  );

  const { removeCache } = useCache();
  useEffect(() => {
    if (isLoaded(service)) {
      // Extra cache invalidation
      removeCache(trustedDevicesApiHref());
    }
  }, [service, id, removeCache]);

  return [service, patch] as const;
};

export const useTrustedDeviceDeleteService = (authState: AuthStateAuthed) => {
  const { service, remove: origRemove } = useService(authState);

  const remove = useCallback(
    (id: number) => origRemove(trustedDeviceApiHref(id)),
    [origRemove]
  );

  const { removeCache } = useCache<ListResponse>();
  useEffect(() => {
    if (isLoaded(service)) {
      // Extra cache invalidation
      removeCache(trustedDevicesApiHref());
    }
  }, [service, removeCache]);

  return [service, remove] as const;
};
