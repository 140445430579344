import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  htmlIdGenerator,
} from "@elastic/eui";
import React, { useEffect } from "react";
import { Organization } from "../../../api/resources/organization";
import { useUserAddService } from "../../../api/resources/user";
import { AuthStateAdmin } from "../../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../../api/useService";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import AddUserForm from "./AddUserForm";

interface Props {
  authState: AuthStateAdmin;
  organization: Organization;
  onClose(): void;
}

const formId = htmlIdGenerator()();

export function AddUserModal({ authState, organization, onClose }: Props) {
  const [createService, onSubmit] = useUserAddService(authState);

  useEffect(() => {
    if (isLoaded(createService)) {
      onClose();
    }
  }, [createService, onClose]);

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Lägg till användare</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        {isError(createService) && (
          <>
            <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
              {createService.message === "username-taken" ? (
                "Användarnamnet är redan upptaget."
              ) : (
                <ServiceErrorMessage error={createService} />
              )}
            </EuiCallOut>
            <EuiSpacer />
          </>
        )}
        <AddUserForm
          onSubmit={onSubmit}
          formId={formId}
          organization={organization}
        />
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose} disabled={isLoading(createService)}>
          Avbryt
        </EuiButtonEmpty>
        <EuiButton
          fill
          iconType="plus"
          type="submit"
          form={formId}
          disabled={isLoading(createService)}
          isLoading={isLoading(createService)}
        >
          Lägg till
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

export default AddUserModal;
