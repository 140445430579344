import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ServiceLoaded } from "../api/fetchFromApi";

interface CacheItem<T> {
  /** UNIX timestamp in ms, after wich this item is expired */
  expire: number;
  readonly value: ServiceLoaded<T>;
}

/** Helper to use the cache context with a specific type T */
export const useCache = <T extends unknown>() => {
  const contextValue = useContext(CacheContext);
  if (!contextValue) {
    throw Error("Can't use cache outside CacheContext");
  }

  return contextValue as CacheContextValue<T>;
};

/** Use specific cache href */
export const useCacheItem = <T extends unknown>(href: string) =>
  useCache<T>().cache.get(href)?.value;

const CacheContext = createContext<CacheContextValue<any> | null>(null);
CacheContext.displayName = "CacheContext";

interface CacheContextValue<T> {
  cache: Map<string, CacheItem<T>>;
  getCache(href: string): ServiceLoaded<T> | undefined;
  setCache(href: string, value: ServiceLoaded<T>): void;
  removeCache(href: string, ignoreSearch?: boolean): void;
}

function withoutSearch(href: string): string {
  const url = new URL(href);
  url.search = "";

  return url.toString();
}

interface ProviderProps {
  /** Default TTL in seconds */
  defaultTtl: number;
  children: React.ReactNode;
}

export function CacheContextProvider({ defaultTtl, children }: ProviderProps) {
  const [cache, setCache] = useState(() => new Map<string, CacheItem<any>>());

  // Save latest cache Map in a ref, so that getCache won't rerender everytime.
  const cacheRef = useRef(cache);
  useEffect(() => {
    cacheRef.current = cache;
  }, [cache]);

  // Periodicly invalidate expired items
  useEffect(() => {
    const interval = setInterval(
      () =>
        setCache((prevCache) => {
          const now = Date.now();

          const expiredHrefs: string[] = [];
          for (const [href, item] of prevCache) {
            if (now > item.expire) {
              expiredHrefs.push(href);
            }
          }

          if (expiredHrefs.length === 0) {
            return prevCache;
          }

          const newCache = new Map(prevCache);
          for (const href of expiredHrefs) {
            newCache.delete(href);
          }
          return newCache;
        }),
      2000
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getCache = useCallback(
    (href: string) => cacheRef.current.get(href)?.value,
    []
  );

  const set = useCallback(
    (href: string, value: ServiceLoaded<any>) =>
      setCache((prevCache) => {
        if (prevCache.get(href)?.value === value) {
          return prevCache;
        }

        return new Map(prevCache).set(href, {
          expire: Date.now() + defaultTtl * 1000,
          value,
        });
      }),
    [defaultTtl]
  );

  const removeCache = useCallback(
    (href: string, ignoreSearch?: boolean) =>
      setCache((prevCache) => {
        if (ignoreSearch) {
          href = withoutSearch(href);
          const nextCache = new Map(
            [...prevCache].filter(([key]) => withoutSearch(key) !== href)
          );

          return nextCache.size === prevCache.size ? prevCache : nextCache;
        } else {
          const nextCache = new Map(prevCache);
          return nextCache.delete(href) ? nextCache : prevCache;
        }
      }),
    []
  );

  const contextValue = useMemo(
    (): CacheContextValue<unknown> => ({
      cache,
      getCache,
      setCache: set,
      removeCache,
    }),
    [cache, getCache, set, removeCache]
  );

  return (
    <CacheContext.Provider value={contextValue}>
      {children}
    </CacheContext.Provider>
  );
}

export default useCache;
