import {
  Criteria,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiLink,
  EuiTableSortingType,
  EuiText,
} from "@elastic/eui";
import React, { useCallback, useMemo, useState } from "react";
import { OrderListItem, useOrdersService } from "../../../api/resources/order";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../../api/useService";
import { parseDateAndFormatShort } from "../../../utils/date";
import RouterLinkAdapter from "../../../utils/RouterLinkAdapter";
import { usePaginate } from "../../../utils/table-helpers";
import {
  orderStatusToString,
  orderTypeToString,
} from "../../orders/order-utils";
import { orderHref } from "../../orders/view/OrderPage";
import UserNameCell from "../../users/UserNameCell";

type Column = EuiBasicTableColumn<OrderListItem> & {
  adminOnly?: boolean;
};

const COLUMNS: Column[] = [
  {
    field: "number",
    name: "Order",
    dataType: "string",
    sortable: true,
    render: (_, order) => (
      <RouterLinkAdapter to={orderHref(order.id)}>
        {(linkProps) => <EuiLink {...linkProps}>{order.number}</EuiLink>}
      </RouterLinkAdapter>
    ),
  },
  {
    field: "assigned_user",
    name: "Ansvarig",
    dataType: "string",
    sortable: true,
    render: (_, order) =>
      order.assigned_user ? <UserNameCell user={order.assigned_user} /> : "",
    adminOnly: true,
  },
  {
    field: "type",
    name: "Typ",
    dataType: "string",
    sortable: true,
    render: (_, order) => orderTypeToString(order.type),
  },
  {
    field: "status",
    name: "Status",
    dataType: "string",
    sortable: true,
    render: (_, order) => orderStatusToString(order.status),
  },
  {
    field: "order_date",
    name: "Skapad",
    dataType: "date",
    sortable: true,
    render: (_, order) => parseDateAndFormatShort(order.order_date),
  },
  {
    field: "requested_delivery_date",
    name: "Önskad leveransdag",
    dataType: "string",
    sortable: true,
    render: (_, order) => order.requested_delivery_date || "Snarast",
  },
  {
    field: "expected_delivery_date",
    name: "Beräknad leveransdag",
    dataType: "string",
    sortable: true,
  },
  {
    field: "actual_delivery_date",
    name: "Leveransdag",
    dataType: "string",
    sortable: true,
  },
];

const emptyOrders: OrderListItem[] = [];

interface Props {
  authState: AuthStateAuthed;
  serviceId: number;
}

export function ServiceOrdersTable({ authState, serviceId }: Props) {
  const ordersService = useOrdersService(authState, { serviceId });
  const orders = isLoaded(ordersService)
    ? ordersService.payload.items
    : emptyOrders;

  const [sortField, setSortField] = useState<keyof OrderListItem>("order_date");
  const [sortDirection, setSortDirection] = useState<"desc" | "asc">("asc");

  const {
    paginated: paginatedOrders,
    pagination,
    setPageIndex,
    setPageSize,
  } = usePaginate(orders);

  const visibleColumns = useMemo(() => {
    // Remove column where adminOnly=true for non-admins
    const filteredColumns = isAdmin(authState)
      ? COLUMNS
      : COLUMNS.filter((col) => !col.adminOnly);

    // Then remove the adminOnly property (React complains otherwise)
    return filteredColumns.map(
      ({ adminOnly, ...col }): EuiBasicTableColumn<OrderListItem> => col
    );
  }, [authState]);

  const handleTableChange = useCallback(
    (criteria: Criteria<OrderListItem>) => {
      if (criteria.page) {
        setPageIndex(criteria.page.index);
        setPageSize(criteria.page.size);
      }
      if (criteria.sort) {
        console.debug("Changed", criteria);
        setSortField(criteria.sort.field);
        setSortDirection(criteria.sort.direction);
      }
    },
    [setPageIndex, setPageSize]
  );

  const sorting: EuiTableSortingType<OrderListItem> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  };

  return (
    <>
      <EuiText>
        <h2>Ordrar</h2>
      </EuiText>
      <EuiBasicTable
        loading={isLoading(ordersService)}
        error={
          isError(ordersService)
            ? ordersService.message +
              (ordersService.track ? ` (track ${ordersService.track})` : "")
            : undefined
        }
        items={paginatedOrders}
        noItemsMessage="Inga relaterade ordrar"
        columns={visibleColumns}
        pagination={pagination}
        sorting={sorting}
        onChange={handleTableChange}
      />
    </>
  );
}

export default ServiceOrdersTable;
