import { EuiBreadcrumb, EuiCallOut } from "@elastic/eui";
import React, { useCallback, useState } from "react";
import { Navigate } from "react-router-dom";
import { useServiceAddService } from "../../../api/resources/service";
import { AuthStateAdmin } from "../../../api/useAuthService";
import { isError, isLoaded } from "../../../api/useService";
import Page from "../../../Page";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import useBreadcrumb from "../../../utils/useBreadcrumb";
import useUserPreferences from "../../../utils/useUserPreferences";
import AddMonitoredInterface from "../../../widgets/AddMonitoredInterface";
import { serviceHref } from "../view/ServicePage";
import { ServiceCreateFields, ServiceCreateForm } from "./ServiceCreateForm";

interface Props {
  authState: AuthStateAdmin;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

export function ServiceCreatePage({ authState, onBreadcrumbs }: Props) {
  const [createService, submitService] = useServiceAddService(authState);
  const [userPreferences, updateUserPreferences] = useUserPreferences();
  const [doMonGraphIface, setDoMonGraphIface] = useState(false);

  const onSubmit = useCallback(
    async (fields: ServiceCreateFields) => {
      if (fields.monitorAGraphInterface && fields.aGraphInterface) {
        setDoMonGraphIface(true);
      }

      if (fields.organization === null) {
        // The form also already validates that an organization is selected
        throw new Error("Organization is null");
      }

      await submitService({
        ...fields,
        organizationId: fields.organization.id,
        aGraphInterfaceId: fields.aGraphInterface && fields.aGraphInterface.id,
      });
    },
    [submitService]
  );

  useBreadcrumb(onBreadcrumbs, "Ny");

  return (
    <Page title="Ny förbindelse">
      {isError(createService) && (
        <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
          <ServiceErrorMessage error={createService} />
        </EuiCallOut>
      )}
      {isLoaded(createService) ? (
        doMonGraphIface && createService.payload.a_graph_interface_id ? (
          <AddMonitoredInterface
            authState={authState}
            serviceId={createService.payload.id}
            interfaceId={createService.payload.a_graph_interface_id}
            redirectTo={serviceHref(createService.payload.id)}
          />
        ) : (
          <Navigate to={serviceHref(createService.payload.id)} />
        )
      ) : (
        <ServiceCreateForm
          authState={authState}
          serviceCreateService={createService}
          onSubmit={onSubmit}
          userPreferences={userPreferences}
          updateUserPreferences={updateUserPreferences}
        />
      )}
    </Page>
  );
}

export default ServiceCreatePage;
