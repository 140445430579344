import { To, useHref } from "react-router";
import { useLinkClickHandler } from "react-router-dom";

/** Combine Elastic UI with react-router.
 *
 * Returns onClick and href props that can be used in EUI links.
 */
export function useRouteLinkProps(to: To) {
  const onClick = useLinkClickHandler(to);

  // Generate the correct link href (with basename accounted for)
  const href = useHref(to);

  return { href, onClick };
}

export interface RouterLinkAdapterChildProps {
  href: string;
  onClick: (event: any) => void;
}

interface RouterLinkAdapterProps {
  to: To;
  children(props: RouterLinkAdapterChildProps): JSX.Element;
}

/** Combine Elastic UI with react-router.
 *
 * Returns onClick and href props that can be used in EUI links.
 *
 * Ex. <EuiLink {...getRouterLinkProps('/location')}>Link</EuiLink>
 */
function RouterLinkAdapter({ to, children }: RouterLinkAdapterProps) {
  const linkProps = useRouteLinkProps(to);

  return children(linkProps);
}

export default RouterLinkAdapter;
