import { EuiDelayRender, EuiLoadingSpinner } from "@elastic/eui";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { AuthState } from "../api/useAuthService";
import Page from "../Page";

interface Props {
  authState: AuthState;
  onLogout(): Promise<unknown>;
}

export function LogoutPage({ authState, onLogout }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedOut =
    authState.status === "unauthed" || authState.status === "error";

  useEffect(() => {
    const logout = async () => {
      await onLogout();
    };

    if (isLoggedOut) {
      navigate(location.state?.redirect || "/");
    } else {
      logout();
    }
  }, [isLoggedOut, onLogout, navigate, location.state]);

  return (
    <EuiDelayRender>
      <Page title="Loggar ut">
        <p>Du loggas nu ut...</p>
        <EuiLoadingSpinner />
      </Page>
    </EuiDelayRender>
  );
}

export default LogoutPage;
