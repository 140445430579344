import { useCallback } from "react";
import useUserPreferences, {
  TablePreferences,
} from "../utils/useUserPreferences";

const defaultTablePrefs: TablePreferences = {
  columns: [],
  sort: undefined,
};

export function useTablePreferences(tableId: string) {
  const [userPrefs, setUserPrefs] = useUserPreferences();
  const prefs = userPrefs.tables[tableId] || defaultTablePrefs;

  const setPrefs = useCallback(
    (nextState: Partial<TablePreferences>) => {
      setUserPrefs({
        tables: {
          ...userPrefs.tables,
          [tableId]: { ...prefs, ...nextState },
        },
      });
    },
    [userPrefs.tables, prefs, tableId, setUserPrefs]
  );

  return [prefs, setPrefs] as const;
}

export default useTablePreferences;
