import { OrderStatus, OrderType } from "../../api/resources/order";
import assertNever from "../../utils/assertNever";

export function orderTypeToString(type: OrderType) {
  switch (type) {
    case "new":
      return "Ny";
    case "change":
      return "Ändring";
    case "end":
      return "Uppsägning";
    default:
      assertNever(type);
  }
}

export function orderStatusToString(status: OrderStatus) {
  switch (status) {
    case "sent":
      return "Skickad";
    case "canceling-sent":
      return "Avbryter skickad";
    case "needs-tender":
      return "Väntar offert";
    case "tendered":
      return "Offererad";
    case "delivering":
      return "Levereras";
    case "canceling-delivery":
      return "Avbryter leverans";
    case "delivered":
      return "Levererad";
    case "delivery-not-possible":
      return "Leverans ej möjlig";
    case "removed":
      return "Borttagen";
    default:
      return assertNever(status);
  }
}
