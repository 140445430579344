import { EuiAvatar, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { User } from "../../api/resources/user";

interface Props {
  user: Pick<User, "full_name">;
}

export const UserNameCell = ({ user }: Props) => (
  <EuiFlexGroup justifyContent="flexStart" gutterSize="s">
    <EuiFlexItem grow={false}>
      <EuiAvatar name={user.full_name} initialsLength={2} size="s" />
    </EuiFlexItem>
    <EuiFlexItem>{user.full_name}</EuiFlexItem>
  </EuiFlexGroup>
);

export default UserNameCell;
