import { EuiButton } from "@elastic/eui";
import React, { ReactElement, useMemo } from "react";
import { AuthStateAdmin } from "../../../api/useAuthService";
import Page from "../../../Page";
import RouterLinkAdapter from "../../../utils/RouterLinkAdapter";
import OrganizationsTable from "./OrganizationsTable";

interface Props {
  authState: AuthStateAdmin;
}

export function OrganizationsPage({ authState }: Props) {
  const actions = useMemo(() => {
    const actions: ReactElement[] = [
      <RouterLinkAdapter to="ny">
        {(linkProps) => (
          <EuiButton iconType="plus" fill {...linkProps}>
            Ny organisation
          </EuiButton>
        )}
      </RouterLinkAdapter>,
    ];

    return actions;
  }, []);

  return (
    <Page title="Organisationer" actionItems={actions}>
      <OrganizationsTable authState={authState} />
    </Page>
  );
}

export default OrganizationsPage;
