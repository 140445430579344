import { EuiButton, EuiSpacer, EuiToolTip } from "@elastic/eui";
import React, { ReactElement, useMemo } from "react";
import { Service } from "../../../api/resources/service";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import Page from "../../../Page";
import RouterLinkAdapter from "../../../utils/RouterLinkAdapter";
import ServiceDescription from "./ServiceDescription";
import ServiceErrorCasesTable from "./ServiceErrorCasesTable";
import ServiceMonitoredInterfaces from "./ServiceMonitoredInterfaces";
import ServiceOrdersTable from "./ServiceOrdersTable";
import ServiceTrafficCharts from "./ServiceTrafficCharts";

export const serviceHref = (serviceId: number) => `/förbindelser/${serviceId}`;

interface Props {
  authState: AuthStateAuthed;
  service: Service;
}

export function ServicePage({ authState, service }: Props) {
  const actions = useMemo(() => {
    const actions: ReactElement[] = [];

    if (authState.user.errorCasePrivileges === "manage") {
      // Create error case
      actions.push(
        <RouterLinkAdapter to="felanmäl">
          {(linkProps) => (
            <EuiToolTip
              content={
                service.status === "active"
                  ? "Skapa ny felanmälan"
                  : "Förbindelsen är inaktiv"
              }
            >
              <EuiButton
                iconType="alert"
                isDisabled={service.status === "inactive"}
                {...linkProps}
              >
                Felanmäl
              </EuiButton>
            </EuiToolTip>
          )}
        </RouterLinkAdapter>
      );
    }

    if (authState.user.orderPrivileges === "manage") {
      // Create change order
      actions.push(
        <RouterLinkAdapter to="beställ-ändring">
          {(linkProps) => (
            <EuiToolTip
              content={
                service.status === "active"
                  ? "Beställ förändring av förbindelsen"
                  : "Förbindelsen är inaktiv"
              }
            >
              <EuiButton
                iconType="documentEdit"
                isDisabled={service.status === "inactive"}
                {...linkProps}
              >
                Beställ ändring
              </EuiButton>
            </EuiToolTip>
          )}
        </RouterLinkAdapter>
      );

      // Create end order
      actions.push(
        <RouterLinkAdapter to="säg-upp">
          {(linkProps) => (
            <EuiToolTip
              content={
                service.status === "active"
                  ? "Säg upp förbindelsen"
                  : "Förbindelsen är inaktiv"
              }
            >
              <EuiButton
                iconType="minusInCircle"
                isDisabled={service.status === "inactive"}
                {...linkProps}
              >
                Säg upp
              </EuiButton>
            </EuiToolTip>
          )}
        </RouterLinkAdapter>
      );
    }

    if (isAdmin(authState)) {
      // Create report
      actions.push(
        <RouterLinkAdapter to="skapa-rapport">
          {(linkProps) => (
            <EuiToolTip
              content={
                <div>
                  Skapa en förbindelserapport (PDF).
                  <br />
                  Förbindelsen måste ha ett leveransdatum.
                </div>
              }
            >
              <EuiButton
                iconType="pageSelect"
                title=""
                isDisabled={!service.delivery_date}
                {...linkProps}
              >
                Rapport
              </EuiButton>
            </EuiToolTip>
          )}
        </RouterLinkAdapter>
      );

      // Edit service
      actions.push(
        <RouterLinkAdapter to="ändra">
          {(linkProps) => (
            <EuiButton iconType="documentEdit" {...linkProps}>
              Ändra
            </EuiButton>
          )}
        </RouterLinkAdapter>
      );
    }

    return actions;
  }, [authState, service.status, service.delivery_date]);

  return (
    <Page title="Förbindelse" actionItems={actions}>
      <ServiceDescription authState={authState} service={service} />
      {authState.user.errorCasePrivileges !== "none" && (
        <ServiceErrorCasesTable authState={authState} serviceId={service.id} />
      )}
      {isAdmin(authState) && (
        <>
          <EuiSpacer />
          <ServiceMonitoredInterfaces authState={authState} service={service} />
        </>
      )}
      {service.delivery_date !== null && (
        <>
          <EuiSpacer />
          <ServiceTrafficCharts authState={authState} service={service} />
        </>
      )}
      {authState.user.orderPrivileges !== "none" && (
        <ServiceOrdersTable authState={authState} serviceId={service.id} />
      )}
    </Page>
  );
}

export default ServicePage;
