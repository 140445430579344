import {
  Axis,
  Chart,
  CurveType,
  LineSeries,
  Position,
  RectAnnotation,
  RectAnnotationDatum,
  ScaleType,
  Settings,
} from "@elastic/charts";
import "@elastic/charts/dist/theme_only_light.css";
import {
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingChart,
  EuiSpacer,
} from "@elastic/eui";
import { EUI_CHARTS_THEME_LIGHT } from "@elastic/eui/dist/eui_charts_theme";
import React, { useMemo } from "react";
import {
  ServiceError,
  ServiceLoaded,
  ServiceLoading,
} from "../api/fetchFromApi";
import * as graph from "../api/graph";
import { isError, isLoading } from "../api/useService";
import { ServiceErrorMessage } from "../utils/ErrorMessage";

interface Props {
  graphService: ServiceLoading | ServiceLoaded<graph.Data> | ServiceError;
  rangeName: graph.Range;
}

function rangeDuration(range: graph.Range): number {
  switch (range) {
    case "hour":
      return 60 * 60;
    case "day":
      return 60 * 60 * 24;
    case "week":
      return 60 * 60 * 24 * 7;
    case "month":
      return 60 * 60 * 24 * 30;
    case "year":
      return 60 * 60 * 24 * 365;
  }
}

export function TrafficChart({ graphService, rangeName }: Props) {
  const ifOperDataValues = useMemo((): RectAnnotationDatum[] => {
    if (graphService.status !== "loaded") {
      return [];
    }

    return graphService.payload.if_oper_data
      .map(([timestamp, ifOper], index, array) => ({
        coordinates: {
          x0: timestamp,
          x1: (array[index + 1] || [Number.MAX_VALUE])[0],
        },
        details: ifOper === 2 ? "Ej länk" : "Länk",
      }))
      .filter(({ details }) => details === "Ej länk");
  }, [graphService]);

  if (isError(graphService)) {
    return (
      <EuiCallOut
        title="Det gick inte att hämta grafdata"
        iconType="alert"
        color="danger"
      >
        <ServiceErrorMessage error={graphService} />
      </EuiCallOut>
    );
  }

  if (isLoading(graphService)) {
    return (
      <EuiFlexGroup
        justifyContent="center"
        alignItems="center"
        style={{ height: 300 }}
      >
        <EuiFlexItem grow={false}>
          <EuiSpacer size="xxl" />
          <EuiLoadingChart size="xl" />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  /** Round to nice SI-units */
  function bpsTickFormat(bps: number): string {
    if (bps === 0) {
      return "0 bps";
    }

    // Use three significant digits  (ex. 123456 => 12300)
    const v = parseFloat(bps.toPrecision(3));

    const magnitude = Math.log10(v);
    if (magnitude < 3) {
      return `${v} bps`;
    } else if (magnitude < 6) {
      return `${v / 1000} kbps`;
    } else if (magnitude < 9) {
      return `${v / 1000 / 1000} Mbps`;
    } else if (magnitude < 12) {
      return `${v / 1000 / 1000 / 1000} Gbps`;
    } else {
      return `${v / 1000 / 1000 / 1000 / 1000} Tbps`;
    }
  }

  // Elastic-chart expects timestamps in milliseconds, so convert from seconds
  const traffic_data = graphService.payload.traffic_data.map(
    ([t, a_to_b, b_to_a]) => [t * 1000, a_to_b, b_to_a]
  );

  const now = Date.now();

  return (
    <Chart size={{ height: 300 }}>
      <Settings
        showLegend
        legendPosition={Position.Bottom}
        theme={EUI_CHARTS_THEME_LIGHT.theme}
        xDomain={{
          min: now - rangeDuration(rangeName) * 1000,
          max: now,
        }}
      />
      <RectAnnotation
        id="link-down-annotation"
        dataValues={ifOperDataValues}
        style={{ fill: "red" }}
      />
      <Axis
        id="bps-axis"
        position={Position.Left}
        ticks={4}
        tickFormat={bpsTickFormat}
      />
      <Axis
        id="time-axis"
        position={Position.Bottom}
        tickFormat={(d) => new Date(d).toLocaleString()}
        timeAxisLayerCount={2}
      />
      <LineSeries
        id="b_to_a"
        name="Nedströms (B till A)"
        timeZone="local"
        xScaleType={ScaleType.Time}
        yScaleType={ScaleType.Linear}
        xAccessor={0}
        yAccessors={[2]}
        lineSeriesStyle={{ point: { visible: false } }}
        data={traffic_data}
        curve={CurveType.LINEAR}
      />
      <LineSeries
        id="a_to_b"
        name="Uppströms (A till B)"
        timeZone="local"
        xScaleType={ScaleType.Time}
        yScaleType={ScaleType.Linear}
        xAccessor={0}
        yAccessors={[1]}
        lineSeriesStyle={{ point: { visible: false } }}
        data={traffic_data}
        curve={CurveType.LINEAR}
      />
    </Chart>
  );
}

export default TrafficChart;
