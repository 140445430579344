import { EuiCallOut, EuiLink } from "@elastic/eui";
import React from "react";
import Page from "../../Page";
import RouterLinkAdapter from "../../utils/RouterLinkAdapter";

export const OrderNotFoundPage = () => (
  <Page title="Order">
    <EuiCallOut title="Ordern finns inte" color="danger" iconType="alert">
      <p>Det gick inte att hitta denna order.</p>
      <RouterLinkAdapter to="/order">
        {(linkProps) => (
          <EuiLink color="warning" {...linkProps}>
            Visa alla order
          </EuiLink>
        )}
      </RouterLinkAdapter>
    </EuiCallOut>
  </Page>
);

export default OrderNotFoundPage;
