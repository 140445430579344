/** Compile time assert that a property name exists on object T, and
 * returns the name unchanged.
 *
 * Usage:
 *
 * propertyOf<MyInterface>("myProperty");
 */
export function propertyOf<T>(name: keyof T) {
  return name as typeof name;
}

export default propertyOf;
