import Ajv, { JSONSchemaType } from "ajv";
import { useCallback, useEffect, useState } from "react";

export interface UserPreferences {
  sendOrderUpdateEmail: boolean;
  sendErrorCaseUpdateEmail: boolean;
  tablePageSize: number;
  tables: Record<string, TablePreferences>;
}

const defaultPreferences: UserPreferences = {
  sendOrderUpdateEmail: true,
  sendErrorCaseUpdateEmail: true,
  tablePageSize: 30,
  tables: {},
};

export interface ColumnPreferences {
  field: string;
  isVisible: boolean;
}

export interface TablePreferences {
  columns: ColumnPreferences[];
  sort?: ColumnSortPreferences;
}

interface ColumnSortPreferences {
  field: string;
  direction: "asc" | "desc";
}

const preferencesSchema: JSONSchemaType<UserPreferences> = {
  type: "object",
  properties: {
    sendOrderUpdateEmail: {
      type: "boolean",
      default: defaultPreferences.sendOrderUpdateEmail,
    },
    sendErrorCaseUpdateEmail: {
      type: "boolean",
      default: defaultPreferences.sendErrorCaseUpdateEmail,
    },
    tablePageSize: {
      type: "integer",
      default: defaultPreferences.tablePageSize,
    },
    tables: {
      type: "object",
      additionalProperties: {
        type: "object",
        properties: {
          columns: {
            type: "array",
            items: {
              type: "object",
              properties: {
                field: { type: "string" },
                isVisible: { type: "boolean" },
              },
              required: ["field", "isVisible"],
              additionalProperties: false,
            },
            default: [],
          },
          sort: {
            type: "object",
            properties: {
              field: { type: "string" },
              direction: { type: "string", enum: ["asc", "desc"] },
            },
            required: ["field", "direction"],
            additionalProperties: false,
            nullable: true,
          },
        },
        required: ["columns"],
      },
      default: defaultPreferences.tables,
      required: [],
    },
  },
  required: [
    "sendOrderUpdateEmail",
    "sendErrorCaseUpdateEmail",
    "tablePageSize",
    "tables",
  ],
  additionalProperties: false,
};

const ajv = new Ajv({ useDefaults: true, removeAdditional: true });
const validate = ajv.compile(preferencesSchema);

const localStorageKey = "fb_portal.user_preferences";

function readOrDefault(): UserPreferences {
  const userPreferences = localStorage.getItem(localStorageKey);
  if (!userPreferences) {
    return defaultPreferences;
  }

  try {
    const obj = JSON.parse(userPreferences);
    if (!validate(obj)) {
      throw new Error(JSON.stringify(validate.errors));
    }

    return obj;
  } catch (e) {
    console.error("User config parse failure: ", e);
    localStorage.removeItem(localStorageKey);

    return defaultPreferences;
  }
}

function save(preferences: UserPreferences) {
  localStorage.setItem(localStorageKey, JSON.stringify(preferences));
}

export function useUserPreferences() {
  const [preferences, setPreferences] = useState(readOrDefault);

  useEffect(() => save(preferences), [preferences]);

  const update = useCallback(
    (values: Partial<UserPreferences>) =>
      setPreferences((prev) => ({ ...prev, ...values })),
    []
  );

  return [preferences, update] as const;
}

export default useUserPreferences;
