import {
  EuiAvatar,
  EuiBadge,
  EuiCommentList,
  EuiCommentProps,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from "@elastic/eui";
import React, { ReactNode } from "react";
import { Order, OrderUpdateStatusChange } from "../../../api/resources/order";
import assertNever from "../../../utils/assertNever";
import { parseDateAndFormatShort } from "../../../utils/date";

interface Props {
  order: Order;
}

export function OrderUpdates({ order }: Props) {
  let comments: EuiCommentProps[] = [];

  comments.push({
    username: order.by_user.full_name,
    timestamp: order.order_date && parseDateAndFormatShort(order.order_date),
    timelineAvatar: (
      <EuiAvatar name={order.by_user.full_name} initialsLength={2}></EuiAvatar>
    ),
    event: (
      <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
        <EuiFlexItem grow={false}>
          <EuiBadge color="success">Skickad</EuiBadge>
        </EuiFlexItem>
      </EuiFlexGroup>
    ),
    children: (
      <EuiText size="s" style={{ whiteSpace: "pre-wrap" }}>
        <p>{order.customer_message}</p>
      </EuiText>
    ),
  });

  comments = comments.concat(
    order.updates.map((update) => ({
      username: update.by_user.full_name,
      timestamp: parseDateAndFormatShort(update.time),
      timelineIcon: (
        <EuiAvatar name={update.by_user.full_name} initialsLength={2} />
      ),
      event: (
        <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
          {update.status_change !== "no-change" && (
            <EuiFlexItem grow={false}>
              {statusChangeToBadge(update.status_change)}
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      ),
      type: update.message ? "regular" : "update",
      children: update.message ? (
        <EuiText size="s" style={{ whiteSpace: "pre-wrap" }}>
          <p>{update.message}</p>
        </EuiText>
      ) : undefined,
    }))
  );

  switch (order.status) {
    case "sent":
      comments.push({
        timelineAvatar: "inspect",
        username: "Avvaktar orderbekräftelse",
      });
      break;
    case "canceling-sent":
      comments.push({
        timelineAvatar: "inspect",
        username: "Avvaktar svar på att stoppa ordern",
      });
      break;
    case "needs-tender":
      comments.push({
        timelineAvatar: "inspect",
        username: "Avvaktar att offert tas fram",
      });
      break;
    case "tendered":
      comments.push({
        timelineAvatar: "document",
        username: "Offert skapad. Avvaktar accept.",
      });
      break;
    case "delivering":
      comments.push({
        timelineAvatar: "wrench",
        username: "Leverans pågår.",
      });
      break;
    case "canceling-delivery":
      comments.push({
        timelineAvatar: "inspect",
        username: "Avvaktar svar på att avbryta pågående leverans",
      });
      break;
    case "delivered":
    case "delivery-not-possible":
    case "removed":
      break;
    default:
      assertNever(order.status);
  }

  return <EuiCommentList comments={comments} />;
}

function statusChangeToBadge(statusChange: OrderUpdateStatusChange): ReactNode {
  switch (statusChange) {
    case "no-change":
      return <EuiBadge color="mute">Meddelande</EuiBadge>;
    case "sent":
      return <EuiBadge color="primary">Skickar</EuiBadge>;
    case "canceling-sent":
      return <EuiBadge color="warning">Avbryter</EuiBadge>;
    case "needs-tender":
      return <EuiBadge color="primary">Avvaktar offert</EuiBadge>;
    case "tendered":
      return <EuiBadge color="primary">Offererad</EuiBadge>;
    case "delivering":
      return <EuiBadge color="primary">Påbörjar leverans</EuiBadge>;
    case "canceling-delivery":
      return <EuiBadge color="warning">Avbryter</EuiBadge>;
    case "delivered":
      return <EuiBadge color="primary">Levererad</EuiBadge>;
    case "delivery-not-possible":
      return <EuiBadge color="danger">Leverans ej möjlig</EuiBadge>;
    case "removed":
      return <EuiBadge color="danger">Borttagen</EuiBadge>;
    default:
      assertNever(statusChange);
  }
}

export default OrderUpdates;
