import { EuiButton } from "@elastic/eui";
import React, { ReactElement, useMemo } from "react";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import Page from "../../../Page";
import RouterLinkAdapter from "../../../utils/RouterLinkAdapter";
import ServicesTable from "./ServicesTable";

interface Props {
  authState: AuthStateAuthed;
}

export const ServicesPage = ({ authState }: Props) => {
  const actions = useMemo(() => {
    const actions: ReactElement[] = [];

    if (isAdmin(authState)) {
      actions.push(
        <RouterLinkAdapter to="ny">
          {(linkProps) => (
            <EuiButton iconType="plus" fill {...linkProps}>
              Skapa ny förbindelse
            </EuiButton>
          )}
        </RouterLinkAdapter>
      );
    }

    return actions;
  }, [authState]);

  return (
    <Page title="Förbindelser" actionItems={actions}>
      <ServicesTable authState={authState} />
    </Page>
  );
};

export default ServicesPage;
