import { createContext, useContext } from "react";

export interface Config {
  publicUrl: string;
  version: string;
}

const defaultConfig: Config = {
  publicUrl: "",
  version: "version-unset",
};

export function getInitialConfig(): Config {
  return {
    publicUrl: process.env.PUBLIC_URL || defaultConfig.publicUrl,
    version: process.env.REACT_APP_VERSION || defaultConfig.version,
  };
}

export const ConfigContext = createContext(defaultConfig);
ConfigContext.displayName = "ConfigContext";

export const useConfig = () => useContext(ConfigContext);
