import { Moment } from "moment";
import { useCallback, useEffect } from "react";
import useCache from "../../utils/useCache";
import * as graph from "../graph";
import { AuthStateAuthed, AuthStateAdmin } from "../useAuthService";
import {
  buildApiHref,
  isLoaded,
  useAutoGetService,
  useService,
} from "../useService";
import { NetmonCoreInterface } from "./netmon_core_interface";
import { ordersApiHref } from "./order";
import { statisticsApiHref } from "./statistics";

export type ServiceStatus = "inactive" | "active";
export type AlarmPriority = "high" | "low" | "off";

export interface Service {
  id: number;
  organization_id: number;
  organization_name: string;
  number: string;
  status: ServiceStatus;
  product_name: string;
  product_capacity: string;
  product_technology: string;
  internal_note?: string; // Only for admins
  alarm_priority?: AlarmPriority; // Only for admins
  monitored_interface_count?: number; // Only for admins
  customer_ref: string;
  a_graph_interface_id: number | null;
  a_graph_interface: NetmonCoreInterface | null; // Only for admins
  a_location_name: string;
  a_address: string;
  a_property_designation: string;
  a_demarcation: string;
  a_to_b_measurement: string;
  b_location_name: string;
  b_address: string;
  b_property_designation: string;
  b_demarcation: string;
  b_to_a_measurement: string;
  delivery_date?: string;
  termination_date?: string;
}

interface ServicesResponse {
  items: Service[];
}

export const servicesApiHref = (search?: { organizationId?: number }) =>
  buildApiHref(
    "services",
    search?.organizationId
      ? { organization_id: search.organizationId.toString() }
      : {}
  );

export const useServicesService = (
  authState: AuthStateAuthed,
  organizationId?: number
) =>
  useAutoGetService<ServicesResponse>(
    authState,
    servicesApiHref({ organizationId })
  );

export const serviceApiHref = (id: number) => buildApiHref(`services/${id}`);

export const useServiceService = (authState: AuthStateAuthed, id: number) =>
  useAutoGetService<Service>(authState, serviceApiHref(id));

export type ServiceService = ReturnType<typeof useServiceService>;

export interface ServiceAddParams {
  organizationId: number;
  number: string | null;
  generateNumber: boolean;
  status: ServiceStatus;
  productName: string;
  productCapacity: string;
  productTechnology: string;
  internalNote: string;
  alarmPriority: AlarmPriority;
  customerRef: string;
  aGraphInterfaceId: number | null;
  aLocationName: string;
  aAddress: string;
  aDemarcation: string;
  aPropertyDesignation: string;
  aToBMeasurement: string;
  bLocationName: string;
  bAddress: string;
  bDemarcation: string;
  bPropertyDesignation: string;
  bToAMeasurement: string;
  deliveryDate: Moment | null;
  terminationDate: Moment | null;
}

export const useServiceAddService = (authState: AuthStateAdmin) => {
  const { service, post: origPost } = useService<Service>(authState);

  const post = useCallback(
    (params: ServiceAddParams) =>
      origPost(servicesApiHref(), {
        organization_id: params.organizationId,
        number: params.number,
        generate_number: params.generateNumber,
        status: params.status,
        product_name: params.productName,
        product_capacity: params.productCapacity,
        product_technology: params.productTechnology,
        internal_note: params.internalNote,
        alarm_priority: params.alarmPriority,
        customer_ref: params.customerRef,
        a_graph_interface_id: params.aGraphInterfaceId,
        a_location_name: params.aLocationName,
        a_address: params.aAddress,
        a_demarcation: params.aDemarcation,
        a_property_designation: params.aPropertyDesignation,
        a_to_b_measurement: params.aToBMeasurement,
        b_location_name: params.bLocationName,
        b_address: params.bAddress,
        b_demarcation: params.bDemarcation,
        b_property_designation: params.bPropertyDesignation,
        b_to_a_measurement: params.bToAMeasurement,
        delivery_date:
          params.deliveryDate && params.deliveryDate.format("YYYY-MM-DD"),
        termination_date:
          params.terminationDate && params.terminationDate.format("YYYY-MM-DD"),
      }),
    [origPost]
  );

  const { setCache, removeCache } = useCache();
  useEffect(() => {
    if (isLoaded(service)) {
      setCache(serviceApiHref(service.payload.id), service);
      // Extra cache invalidation
      removeCache(statisticsApiHref(), true);
      removeCache(servicesApiHref(), true);
    }
  }, [service, setCache, removeCache]);

  return [service, post] as const;
};

export type ServiceAddService = ReturnType<typeof useServiceAddService>[0];

export interface ServiceUpdateParams {
  status: ServiceStatus;
  productName: string;
  productCapacity: string;
  productTechnology: string;
  internalNote: string;
  alarmPriority: AlarmPriority;
  customerRef: string;
  aGraphInterfaceId: number | null;
  aLocationName: string;
  aAddress: string;
  aDemarcation: string;
  aPropertyDesignation: string;
  aToBMeasurement: string;
  bLocationName: string;
  bAddress: string;
  bDemarcation: string;
  bPropertyDesignation: string;
  bToAMeasurement: string;
  deliveryDate: Moment | null;
  terminationDate: Moment | null;
}

export const useServiceUpdateService = (
  authState: AuthStateAdmin,
  id: number
) => {
  const { service, patch: origPatch } = useService<Service>(authState);

  const patch = useCallback(
    (params: ServiceUpdateParams) =>
      origPatch(serviceApiHref(id), {
        status: params.status,
        product_name: params.productName,
        product_capacity: params.productCapacity,
        product_technology: params.productTechnology,
        internal_note: params.internalNote,
        alarm_priority: params.alarmPriority,
        customer_ref: params.customerRef,
        a_graph_interface_id: params.aGraphInterfaceId,
        a_location_name: params.aLocationName,
        a_address: params.aAddress,
        a_demarcation: params.aDemarcation,
        a_property_designation: params.aPropertyDesignation,
        a_to_b_measurement: params.aToBMeasurement,
        b_location_name: params.bLocationName,
        b_address: params.bAddress,
        b_demarcation: params.bDemarcation,
        b_property_designation: params.bPropertyDesignation,
        b_to_a_measurement: params.bToAMeasurement,
        delivery_date:
          params.deliveryDate && params.deliveryDate.format("YYYY-MM-DD"),
        termination_date:
          params.terminationDate && params.terminationDate.format("YYYY-MM-DD"),
      }),
    [origPatch, id]
  );

  const { setCache, removeCache } = useCache();
  useEffect(() => {
    if (isLoaded(service)) {
      setCache(serviceApiHref(service.payload.id), service);
      // Extra cache invalidation
      removeCache(statisticsApiHref(), true);
      removeCache(servicesApiHref(), true);
      removeCache(ordersApiHref(), true);
    }
  }, [service, id, setCache, removeCache]);

  return [service, patch] as const;
};

export const serviceGraphApiHref = (serviceId: number, range: graph.Range) =>
  buildApiHref(`services/${serviceId}/graph/${range}`);

export const useServiceGraphService = (
  authState: AuthStateAuthed,
  serviceId: number,
  range: graph.Range
) =>
  useAutoGetService<graph.Data>(
    authState,
    serviceGraphApiHref(serviceId, range)
  );
