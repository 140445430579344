import React from "react";
import Page from "../Page";

export const NotFoundPage = () => (
  <Page title="Sidan finns inte">
    <p>Denna sida finns inte.</p>
  </Page>
);

export default NotFoundPage;
