import { EuiLink } from "@elastic/eui";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { OrderListItem, useOrdersService } from "../../../api/resources/order";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import { isLoaded, isLoading } from "../../../api/useService";
import TableCellJson from "../../../table/TableCellJson";
import { parseDateAndFormatShort } from "../../../utils/date";
import RouterLinkAdapter from "../../../utils/RouterLinkAdapter";
import Table, { TableColumn } from "../../../widgets/Table";
import { organizationHref } from "../../organizations/view/OrganizationPage";
import CombinedAddressCell from "../../services/list/CombinedAddressCell";
import CombinedProductCell from "../../services/list/CombinedProductCell";
import {
  combinedAddressString,
  combinedProductString,
} from "../../services/service-utils";
import { serviceHref } from "../../services/view/ServicePage";
import UserNameCell from "../../users/UserNameCell";
import { orderStatusToString, orderTypeToString } from "../order-utils";
import { orderHref } from "../view/OrderPage";

const COLUMNS: Column[] = [
  {
    type: "field",
    field: "number",
    name: "Order",
    dataType: "string",
    render: (_, order) => (
      <RouterLinkAdapter to={orderHref(order.id)}>
        {(linkProps) => <EuiLink {...linkProps}>{order.number}</EuiLink>}
      </RouterLinkAdapter>
    ),
  },
  {
    type: "field",
    field: "organization_name",
    name: "Organisation",
    dataType: "string",
    adminOnly: true,
    hideByDefault: true,
    render: (_, order) => (
      <RouterLinkAdapter to={organizationHref(order.organization_id)}>
        {(linkProps) => (
          <EuiLink {...linkProps}>{order.organization_name}</EuiLink>
        )}
      </RouterLinkAdapter>
    ),
  },
  {
    type: "field",
    field: "assigned_user",
    name: "Ansvarig",
    render: (_, order) =>
      order.assigned_user ? <UserNameCell user={order.assigned_user} /> : "",
    csvValueFn: (order) =>
      order.assigned_user ? order.assigned_user.full_name : "",
    dataType: "string",
    adminOnly: true,
  },
  {
    type: "field",
    field: "order_date",
    name: "Skapad",
    hideByDefault: true,
    render: (_, order) => parseDateAndFormatShort(order.order_date),
    csvValueFn: (order) => parseDateAndFormatShort(order.order_date),
    // defaultSortDirection: "desc",
    dataType: "date",
  },
  {
    type: "field",
    field: "type",
    name: "Typ",
    render: (_, order) => orderTypeToString(order.type),
    csvValueFn: (order) => orderTypeToString(order.type),
    dataType: "string",
  },
  {
    type: "field",
    field: "status",
    name: "Status",
    render: (_, order) => orderStatusToString(order.status),
    csvValueFn: (order) => orderStatusToString(order.status),
    dataType: "string",
  },
  {
    type: "field",
    field: "customer_ref",
    name: "Orderreferens",
    dataType: "string",
  },
  {
    type: "field",
    field: "service.number",
    name: "Förbindelse",
    dataType: "string",
    render: (_, order) =>
      order.service.number ? (
        <RouterLinkAdapter to={serviceHref(order.service.id)}>
          {(linkProps) => (
            <EuiLink {...linkProps}>{order.service.number}</EuiLink>
          )}
        </RouterLinkAdapter>
      ) : (
        <>-</>
      ),
  },
  {
    // Combined product description from name, capacity and technology
    type: "computed",
    field: "product_combined",
    name: "Produkt (kombinerad)",
    render: (order) => (
      <CombinedProductCell service={order.service} isDetails={false} />
    ),
    csvValueFn: (order) => combinedProductString(order.service),
  },
  {
    type: "field",
    name: "Produkt",
    field: "service.product_name",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "service.product_capacity",
    name: "Kapacitet",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "service.product_technology",
    name: "Teknik",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "service.customer_ref",
    name: "Förbindelsereferens",
    dataType: "string",
  },
  {
    // Combined A and B-side (if any) address
    type: "computed",
    field: "address_combined",
    name: "Adress (kombinerad)",
    render: (order) => (
      <CombinedAddressCell service={order.service} isDetails={false} />
    ),
    csvValueFn: (order) => combinedAddressString(order.service),
  },
  {
    type: "field",
    field: "service.a_location_name",
    name: "A-namn",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "service.a_address",
    name: "A-adress",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "service.a_demarcation",
    name: "A-avlämning",
    adminOnly: true,
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "service.a_property_designation",
    name: "A-fast.bet.",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "service.a_to_b_measurement",
    name: "Mätresultat A→B",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "service.b_location_name",
    name: "B-namn",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "service.b_address",
    name: "B-adress",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "service.b_demarcation",
    name: "B-avlämning",
    adminOnly: true,
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "service.b_property_designation",
    name: "B-fast.bet.",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "service.b_to_a_measurement",
    name: "Mätresultat B→A",
    sortable: false,
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "requested_delivery_date",
    name: "Önskad leveransdag",
    // defaultSortDirection: "desc",
    dataType: "date",
    render: (_, order) => order.requested_delivery_date || "Snarast",
  },
  {
    type: "field",
    field: "expected_delivery_date",
    name: "Beräknad leveransdag",
    // defaultSortDirection: "desc",
    dataType: "date",
    render: (_, order) => order.expected_delivery_date,
  },
  {
    type: "field",
    field: "actual_delivery_date",
    name: "Leveransdag",
    // defaultSortDirection: "desc",
    dataType: "date",
    render: (_, order) => order.actual_delivery_date,
  },
  {
    type: "field",
    field: "by_user.full_name",
    name: "Skapad av",
    dataType: "string",
    hideByDefault: true,
    render: (_, order) => <UserNameCell user={order.by_user} />,
  },
  {
    type: "field",
    field: "internal_note",
    name: "Intern information",
    adminOnly: true,
    hideByDefault: true,
    sortable: false,
    dataType: "string",
  },
  {
    type: "field",
    field: "service.monitored_interface_count",
    name: "Övervakade portar",
    adminOnly: true,
    hideByDefault: true,
    dataType: "number",
  },
  {
    type: "field",
    field: "json",
    name: "JSON",
    dataType: "string",
    adminOnly: true,
    hideByDefault: true,
    render: (_, order) => <TableCellJson data={order} />,
    csvValueFn: JSON.stringify,
    sortable: false,
  },
];

type Column = TableColumn<OrderListItem> & {
  /** If true, column is only visible for user.group FB-PORTAL-ADMIN */
  adminOnly?: boolean;
};

function orderToKeywords(order: OrderListItem): string[] {
  return [
    [order.number],
    order.organization_name.split(/\s+/),
    order.by_user.full_name.split(/\s+/),
    order.assigned_user ? order.assigned_user.full_name.split(/\s+/) : [],
    [order.order_date],
    [orderTypeToString(order.type)],
    order.customer_ref.split(/\s+/),
    order.service.number ? [order.service.number] : [],
    order.service.product_name.split(/\s+/),
    [order.service.product_capacity],
    [order.service.product_technology],
    order.service.customer_ref.split(/\s+/),
    [orderStatusToString(order.status)],
    order.service.a_location_name.split(/\s+/),
    order.service.a_address.split(/\s+/),
    order.service.a_property_designation.split(/\s+/),
    order.service.b_location_name.split(/\s+/),
    order.service.b_address.split(/\s+/),
    order.service.b_property_designation.split(/\s+/),
    order.requested_delivery_date ? [order.requested_delivery_date] : [],
    order.expected_delivery_date ? [order.expected_delivery_date] : [],
    order.actual_delivery_date ? [order.actual_delivery_date] : [],
  ].flat();
}

interface Props {
  authState: AuthStateAuthed;
}

const emptyOrders: OrderListItem[] = [];

export function OrdersTable({ authState }: Props) {
  const ordersService = useOrdersService(authState);
  const orders = isLoaded(ordersService)
    ? ordersService.payload.items
    : emptyOrders;

  // Apply filters from search params
  const { search } = useLocation();
  const filteredOrders = useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    let result = orders;

    // Filter for assigned_user_id
    const assignedUserFilter = urlSearchParams.getAll("assigned_user_id");
    if (assignedUserFilter.length > 0) {
      result = result.filter((order) => {
        const id = order.assigned_user
          ? order.assigned_user.id.toString()
          : "null";
        return assignedUserFilter.includes(id);
      });
    }

    // Apply filter for organization
    const organizationFilter = urlSearchParams.getAll("organization_id");
    if (organizationFilter.length > 0) {
      result = result.filter((order) =>
        organizationFilter.includes(order.organization_id.toString())
      );
    }

    // Apply filter for status
    const statusFilter = urlSearchParams.getAll("status");
    if (statusFilter.length > 0) {
      result = result.filter((order: OrderListItem) =>
        statusFilter.includes(order.status)
      );
    }

    return result;
  }, [orders, search]);

  // Filter out column that are only useful for admins
  const columns = useMemo(() => {
    if (isAdmin(authState)) {
      return COLUMNS;
    } else {
      return COLUMNS.filter((column) => !column?.adminOnly);
    }
  }, [authState]);

  return (
    <Table
      items={filteredOrders}
      itemToKeywords={orderToKeywords}
      columns={columns}
      preferencesKey="orders"
      loading={isLoading(ordersService)}
    />
  );
}

export default OrdersTable;
