import { EuiSelectOption } from "@elastic/eui";
import {
  AlarmPriority,
  Service,
  ServiceStatus,
} from "../../api/resources/service";
import { serviceHref } from "./view/ServicePage";

export const createReportHref = (serviceId: number) =>
  `${serviceHref(serviceId)}/skapa-rapport`;

export function serviceStatusToString(status: ServiceStatus) {
  switch (status) {
    case "active":
      return "Aktiv";
    case "inactive":
      return "Inaktiv";
    default:
      return status;
  }
}

export function alarmPriorityToString(prio: AlarmPriority) {
  switch (prio) {
    case "high":
      return "Hög (SMS)";
    case "low":
      return "Låg (mejl)";
    case "off":
      return "Avstängt";
  }
}

export const alarmPriorityOptions: EuiSelectOption[] = [
  { value: "high", text: alarmPriorityToString("high") },
  { value: "low", text: alarmPriorityToString("low") },
  { value: "off", text: alarmPriorityToString("off") },
];

export function combinedProductString({
  product_name,
  product_capacity,
  product_technology,
}: Pick<Service, "product_name" | "product_capacity" | "product_technology">) {
  return [product_name, product_capacity, product_technology]
    .filter((v) => !!v)
    .join(" ");
}

export function combinedAAddressString({
  a_location_name,
  a_address,
}: Pick<Service, "a_location_name" | "a_address">) {
  return [a_location_name, a_address].filter((v) => v).join(", ");
}

export function combinedBAddressString({
  b_location_name,
  b_address,
}: Pick<Service, "b_location_name" | "b_address">) {
  return [b_location_name, b_address].filter((v) => v).join(", ");
}

/** Combine address from a-side and b-side (if any) inte one string */
export function combinedAddressString(
  service: Pick<
    Service,
    "a_location_name" | "a_address" | "b_location_name" | "b_address"
  >
) {
  const aSide = combinedAAddressString(service);
  const bSide = combinedBAddressString(service);

  if (bSide.length > 0) {
    return `${aSide} ↔ ${bSide}`;
  } else {
    return aSide;
  }
}
