import {
  EuiBadge,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiButtonIcon,
  EuiCallOut,
  EuiConfirmModal,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  RIGHT_ALIGNMENT,
} from "@elastic/eui";
import React, { ReactNode, useCallback, useState } from "react";
import {
  MonitoredInterface,
  useMonitoredInterfaceAddService,
  useMonitoredInterfaceDeleteService,
  useMonitoredInterfacesService,
} from "../../../api/resources/monitored_interface";
import { NetmonCoreInterface } from "../../../api/resources/netmon_core_interface";
import { Service } from "../../../api/resources/service";
import { AuthStateAdmin } from "../../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../../api/useService";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import { netmonCoreInterfaceToString } from "../../../utils/netmonCoreInterface";
import InterfaceTrafficChart from "../../../widgets/InterfaceTrafficChart";
import NetmonCoreInterfaceSelectFlyout from "../../../widgets/NetmonCoreInterfaceSelectFlyout";

interface Props {
  authState: AuthStateAdmin;
  service: Service;
}

export function ServiceMonitoredInterfaces({ authState, service }: Props) {
  const listService = useMonitoredInterfacesService(authState, service.id);
  const items = isLoaded(listService) ? listService.payload.items : [];

  const [addIsOpen, setAddIsOpen] = useState(false);
  const [addService, addInterface] = useMonitoredInterfaceAddService(
    authState,
    service.id
  );

  const [isAboutToDelete, setAboutToDelete] =
    useState<MonitoredInterface | null>(null);
  const [deleteService, deleteInterface] = useMonitoredInterfaceDeleteService(
    authState,
    service.id
  );

  const onAdd = useCallback(
    (iface: NetmonCoreInterface | null) => {
      if (iface) {
        addInterface(iface.id);
      }
      setAddIsOpen(false);
    },
    [addInterface]
  );

  const onConfirmDelete = useCallback(
    (item: MonitoredInterface) => {
      deleteInterface(item.interface.id);
      setAboutToDelete(null);
    },
    [deleteInterface]
  );

  const [itemIdToExpandedRow, setItemIdToExpandedRow] = useState<{
    [id: string]: ReactNode;
  }>({});

  const toggleDetails = (item: MonitoredInterface) => {
    const newItemIdToExpandedRow = { ...itemIdToExpandedRow };
    if (newItemIdToExpandedRow[item.interface.id]) {
      delete newItemIdToExpandedRow[item.interface.id];
    } else {
      newItemIdToExpandedRow[item.interface.id] = (
        <InterfaceTrafficChart
          authState={authState}
          iface={item.interface}
          rangeName="day"
        />
      );
    }
    setItemIdToExpandedRow(newItemIdToExpandedRow);
  };

  const columns: EuiBasicTableColumn<MonitoredInterface>[] = [
    {
      field: "interface.node_name",
      name: "Nod",
    },
    {
      field: "interface.device_name",
      name: "Switch",
    },
    {
      field: "interface.device_ip4_str",
      name: "IP",
    },
    {
      field: "interface.descr",
      name: "Descr",
    },
    {
      field: "interface.alias",
      name: "Alias",
    },
    {
      actions: [
        {
          name: "Ta bort",
          description: "Ta bort",
          icon: "trash",
          color: "danger",
          type: "icon",
          onClick: setAboutToDelete,
          isPrimary: true,
          "data-test-subj": "action-delete",
        },
      ],
    },
    {
      align: RIGHT_ALIGNMENT,
      width: "40px",
      isExpander: true,
      render: (item: MonitoredInterface) => (
        <EuiButtonIcon
          onClick={() => toggleDetails(item)}
          aria-label={
            itemIdToExpandedRow[item.interface.id] ? "Minimera" : "Expandera"
          }
          iconType={
            itemIdToExpandedRow[item.interface.id.toString()]
              ? "arrowUp"
              : "arrowDown"
          }
        />
      ),
    },
  ];

  const isAnyLoading =
    isLoading(listService) || isLoading(addService) || isLoading(deleteService);

  return (
    <>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText>
            <h2>
              Övervakade switchportar{" "}
              {service.alarm_priority === "off" &&
                isLoaded(listService) &&
                listService.payload.items.length > 0 && (
                  <EuiBadge iconType="bellSlash" color="warning">
                    Larm avstängda
                  </EuiBadge>
                )}
            </h2>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiText>
            <EuiButton
              iconType="plus"
              onClick={() => setAddIsOpen(true)}
              isLoading={isLoading(addService)}
            >
              Lägg till
            </EuiButton>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      {isError(addService) && <ServiceErrorMessage error={addService} />}
      {isError(deleteService) && <ServiceErrorMessage error={deleteService} />}
      <EuiBasicTable<MonitoredInterface>
        items={items}
        rowHeader="id"
        columns={columns}
        loading={isAnyLoading}
        error={isError(listService) ? listService.message : ""}
        noItemsMessage="Inga portar övervakas"
        itemId={(item) => item.interface.id.toString()}
        itemIdToExpandedRowMap={itemIdToExpandedRow}
        tableLayout="auto"
      />
      {addIsOpen && (
        <NetmonCoreInterfaceSelectFlyout
          authState={authState}
          onSelect={onAdd}
          onClose={() => setAddIsOpen(false)}
        />
      )}
      {isAboutToDelete && (
        <EuiConfirmModal
          title="Ta bort övervakning?"
          onCancel={() => setAboutToDelete(null)}
          onConfirm={() => onConfirmDelete(isAboutToDelete)}
          cancelButtonText="Avbryt"
          confirmButtonText="Ta bort"
          buttonColor="danger"
          defaultFocusedButton="confirm"
        >
          <EuiCallOut
            iconType="trash"
            color="danger"
            title={netmonCoreInterfaceToString(isAboutToDelete.interface)}
          />
        </EuiConfirmModal>
      )}
    </>
  );
}

export default ServiceMonitoredInterfaces;
