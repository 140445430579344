import React from "react";
import { AuthStateAuthed } from "../../../api/useAuthService";
import Page from "../../../Page";
import ErrorCasesTable from "./ErrorCasesTable";

interface Props {
  authState: AuthStateAuthed;
}

export const ErrorCasesPage = ({ authState }: Props) => (
  <Page title="Felärenden">
    <ErrorCasesTable authState={authState} />
  </Page>
);

export default ErrorCasesPage;
