import {
  EuiBreadcrumb,
  EuiCallOut,
  EuiDelayRender,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
} from "@elastic/eui";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useErrorCaseAddService } from "../../../api/resources/errorCase";
import { useOrganizationService } from "../../../api/resources/organization";
import { Service } from "../../../api/resources/service";
import { AuthStateAuthed } from "../../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../../api/useService";
import Page from "../../../Page";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import useBreadcrumb from "../../../utils/useBreadcrumb";
import { useUserPreferences } from "../../../utils/useUserPreferences";
import ServiceDescription from "../../../widgets/ServiceDescription";
import { errorCaseHref } from "../view/ErrorCasePage";
import {
  ErrorCaseCreateFields,
  ErrorCaseCreateForm,
} from "./ErrorCaseCreateForm";

interface Props {
  authState: AuthStateAuthed;
  service: Service;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

export function ErrorCaseCreatePage({
  authState,
  service,
  onBreadcrumbs,
}: Props) {
  const [createService, submit] = useErrorCaseAddService(authState);
  const [userPreferences, updateUserPreferences] = useUserPreferences();
  const navigate = useNavigate();
  const organizationService = useOrganizationService(
    authState,
    service.organization_id
  );

  useBreadcrumb(onBreadcrumbs, "Felanmäl");

  const onSubmit = useCallback(
    async (fields: ErrorCaseCreateFields) => {
      await submit({
        organizationId: service.organization_id,
        serviceId: service.id,
        assignedUserId:
          fields.assignedUserId === "" ? null : parseInt(fields.assignedUserId),
        errorDescription: fields.errorDescription,
        updatesEmail: fields.updatesEmail || "",
        sendUpdateEmail: fields.sendUpdateEmail,
      });
    },
    [submit, service.id, service.organization_id]
  );

  useEffect(() => {
    if (isLoaded(createService)) {
      navigate(errorCaseHref(createService.payload.id), { replace: true });
    }
  }, [createService, navigate]);

  return (
    <Page title="Skapa felanmälan">
      {isError(createService) && (
        <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
          <ServiceErrorMessage error={createService} />
        </EuiCallOut>
      )}
      {isError(organizationService) && (
        <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
          <ServiceErrorMessage error={organizationService} />
        </EuiCallOut>
      )}
      <EuiFlexGroup>
        <EuiFlexItem>
          {isLoaded(organizationService) && (
            <ErrorCaseCreateForm
              authState={authState}
              organization={organizationService.payload}
              createErrorCaseService={createService}
              onSubmit={onSubmit}
              userPreferences={userPreferences}
              updateUserPreferences={updateUserPreferences}
            />
          )}
          {isLoading(organizationService) && (
            <EuiDelayRender>
              <EuiLoadingSpinner size="l" />
            </EuiDelayRender>
          )}
        </EuiFlexItem>
        <EuiFlexItem>
          <ServiceDescription authState={authState} service={service} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </Page>
  );
}

export default ErrorCaseCreatePage;
