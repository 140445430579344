import { EuiCallOut, EuiSelectOption } from "@elastic/eui";
import { useField } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { User, useUsersService } from "../../api/resources/user";
import { AuthStateAdmin } from "../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../api/useService";
import { ServiceErrorMessage } from "../ErrorMessage";
import SelectRow from "./SelectRow";

interface Props {
  authState: AuthStateAdmin;
  label: string;
  /** Formik form field name */
  name: string;
  defaultUserId?: number;
}

const emptyUsers: User[] = [];

/** A form row with a select input with options for all admins and no user.
 *
 * Fields value will be set to a string representation of user id, or empty
 * string if "no user" is selected. */
export function SelectUserRow({
  authState,
  name,
  label,
  defaultUserId,
}: Props) {
  const [, , fieldHelper] = useField<string>(name);

  const usersService = useUsersService(authState, { group: "FB-PORTAL-ADMIN" });
  const users = isLoaded(usersService)
    ? usersService.payload.items
    : emptyUsers;

  const options = useMemo(() => {
    const options = users.map(
      (user): EuiSelectOption => ({
        text: user.full_name,
        value: user.id.toString(),
      })
    );
    options.push({ text: "- Ingen -", value: "" });

    return options;
  }, [users]);

  // When options are loaded, select the one matching initial user id
  const [initialUsed, setInitialUsed] = useState(false);
  useEffect(() => {
    if (!initialUsed && defaultUserId !== undefined) {
      fieldHelper.setValue(defaultUserId.toString());
      setInitialUsed(true);
    }
  }, [initialUsed, fieldHelper, authState.user.id, defaultUserId]);

  return (
    <>
      <SelectRow
        name={name}
        label={label}
        options={options}
        isLoading={isLoading(usersService)}
      />
      {isError(usersService) && (
        <EuiCallOut
          title="Kunde inte hämta användare"
          color="danger"
          iconType="alert"
        >
          <ServiceErrorMessage error={usersService} />
        </EuiCallOut>
      )}
    </>
  );
}

export default SelectUserRow;
