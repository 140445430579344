import { EuiButtonIcon, EuiCodeBlock, EuiPopover } from "@elastic/eui";
import { useState } from "react";

interface Props {
  /** Data to be serialized with JSON.stringify() */
  data: any;
}

export function TableCellJson({ data }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const button = (
    <EuiButtonIcon
      aria-label="Visa JSON"
      iconType="editorCodeBlock"
      onClick={() => setIsOpen((prev) => !prev)}
    />
  );

  return (
    <EuiPopover
      button={button}
      isOpen={isOpen}
      anchorPosition="leftCenter"
      closePopover={() => setIsOpen(false)}
      panelPaddingSize="s"
    >
      <EuiCodeBlock language="json" whiteSpace="pre" paddingSize="s">
        {JSON.stringify(data, undefined, 2)}
      </EuiCodeBlock>
    </EuiPopover>
  );
}

export default TableCellJson;
