import { useCallback, useEffect, useReducer } from "react";
import assertNever from "../utils/assertNever";
import { fetchFromApi, ServiceError, ServiceResponse } from "./fetchFromApi";
import { Group, User } from "./resources/user";
import { buildApiHref } from "./useService";

const localStoreKey = "fb-portal-auth-service";
const apiUrl =
  process.env.REACT_APP_AUTH_API_URL || "REACT_APP_AUTH_API_URL unset";

function buildAuthApiHref(resource: string): string {
  return new URL(resource, apiUrl).toString();
}

type ErrorKind =
  | "unexpected-2fa-login"
  | "2fa-login-timeout"
  | "password-change-while-not-authed"
  | "token-refresh-while-not-authed"
  | "token-refresh-unauthorized";

interface StateGenericError {
  status: "error";
  kind: ErrorKind;
}

interface StateBadUsernameOrPasswordError {
  status: "error";
  kind: "bad-username-or-password";
  username: string;
  password: string;
}

interface StateServiceError {
  status: "error";
  kind: "service-error";
  serviceError: ServiceError;
}

type AuthStateError = (
  | StateGenericError
  | StateBadUsernameOrPasswordError
  | StateServiceError
) & { token: null };

interface AuthStateUnauthed {
  status: "unauthed";
  token: null;
  passwordIsChanged?: boolean;
}

/** Authenticated user in user or admin group */
export interface AuthedAnyGrp {
  id: number;
  organizationId: number;
  username: string;
  fullName: string;
  group: Group;
  phone: string;
  email: string;
  servicePrivileges: "view" | "manage";
  errorCasePrivileges: "none" | "view" | "manage";
  orderPrivileges: "none" | "view" | "manage";
}

/** Authenticated user in "FB-PORTAL-USER" group */
export interface AuthedUser extends AuthedAnyGrp {
  group: "FB-PORTAL-USER";
}

/** Authenticated user in "FB-PORTAL-ADMIN" group */
export interface AuthedAdmin extends AuthedAnyGrp {
  group: "FB-PORTAL-ADMIN";
}

/** Auth state for user in group admin or user */
export interface AuthStateAuthed {
  status: "authed";
  user: AuthedAnyGrp;
  token: string;
  expire: number; // UNIX-timestamp in milliseconds
}

/** Auth state for regular user */
export interface AuthStateUser extends AuthStateAuthed {
  user: AuthedUser;
}

/** Auth state for admin user */
export interface AuthStateAdmin extends AuthStateAuthed {
  user: AuthedAdmin;
}

interface AuthStateRequire2fa {
  status: "require-2fa";
  username: string;
  invalidCode: string | null; // 2fa code of last failed attempt
  token: string;
  expire: number; // UNIX-timestamp in milliseconds
}

interface AuthStatePasswordExpired {
  status: "password-expired";
  username: string;
  token: string;
  expire: number; // UNIX-timestamp in milliseconds
}

export type AuthStateBase =
  | AuthStateRequire2fa
  | AuthStatePasswordExpired
  | AuthStateUnauthed
  | AuthStateAuthed
  | AuthStateError;

export type AuthState = AuthStateBase & {
  isLoading: boolean;
};

// Subset of types that is stored in localStorage
type StoredState = AuthStateRequire2fa | AuthStateAuthed;

interface LoginUserOrganizationResponse {
  id: number;
  name: string;
}

interface LoginUserResponse extends User {
  organization: LoginUserOrganizationResponse;
}

interface SuccessfulLoginResponse {
  auth_state: "AUTHED";
  user: LoginUserResponse;
  token: string;
  token_ttl: number;
}

interface Require2FALoginResponse {
  auth_state: "REQUIRE_2FA";
  token: string;
  token_ttl: number;
}

interface PasswordExpiredLoginResponse {
  auth_state: "PASSWORD_EXPIRED";
  token: string;
  token_ttl: number;
}

export type LoginStatusResponse =
  | SuccessfulLoginResponse
  | Require2FALoginResponse
  | PasswordExpiredLoginResponse;

interface TokenRefreshResponse {
  token: string;
  token_ttl: number;
}

function reducerInit(initialAuthState: AuthState): AuthState {
  const storedAuthStateJson = localStorage.getItem(localStoreKey);
  if (!storedAuthStateJson) {
    return initialAuthState;
  }

  const storedAuthState = JSON.parse(storedAuthStateJson) as StoredState;

  if (Date.now() >= storedAuthState.expire) {
    // Expired, use default state instead
    localStorage.removeItem(localStoreKey);
    return initialAuthState;
  }

  return { ...storedAuthState, isLoading: false };
}

interface ActionSimple {
  type: "set-is-loading" | "set-unauthed";
}

interface ActionLoginSuccess {
  type: "login-success";
  user: AuthedAnyGrp;
  token: string;
  tokenTtl: number;
}

interface ActionBadUsernameOrPassword {
  type: "bad-username-or-password";
  username: string;
  password: string;
}

interface ActionRequire2fa {
  type: "require-2fa";
  username: string;
  token: string;
  tokenTtl: number;
}

interface ActionPasswordExpired {
  type: "password-expired";
  username: string;
  token: string;
  tokenTtl: number;
}

interface ActionLogin2faCancel {
  type: "login-2fa-cancel";
}

interface ActionLogin2faSuccess {
  type: "login-2fa-success";
  user: AuthedAnyGrp;
  token: string;
  tokenTtl: number;
}

interface ActionLogin2faTimeout {
  type: "login-2fa-timeout";
}

interface ActionInvalid2faCode {
  type: "invalid-2fa-code";
  code: string;
}

interface ActionPasswordChangeSuccess {
  type: "password-change-success";
}

interface ActionPasswordChangeCancel {
  type: "password-change-cancel";
}

interface ActionTokenRefreshSuccess {
  type: "token-refresh-success";
  token: string;
  tokenTtl: number;
}

interface ActionError {
  type: "error";
  kind: ErrorKind;
}

interface ActionServiceError {
  type: "service-error";
  response: ServiceError;
}

type Action =
  | ActionSimple
  | ActionLoginSuccess
  | ActionBadUsernameOrPassword
  | ActionRequire2fa
  | ActionPasswordExpired
  | ActionLogin2faCancel
  | ActionPasswordChangeSuccess
  | ActionPasswordChangeCancel
  | ActionLogin2faSuccess
  | ActionLogin2faTimeout
  | ActionInvalid2faCode
  | ActionTokenRefreshSuccess
  | ActionError
  | ActionServiceError;

function authReducer(state: AuthState, action: Action): AuthState {
  const expireTimestamp = (tokenTtl: number) =>
    Date.now() + tokenTtl * 1000 * 0.75;

  switch (action.type) {
    case "login-success":
      return {
        status: "authed",
        user: action.user,
        token: action.token,
        expire: expireTimestamp(action.tokenTtl),
        isLoading: false,
      };
    case "bad-username-or-password":
      return {
        status: "error",
        kind: "bad-username-or-password",
        username: action.username,
        password: action.password,
        isLoading: false,
        token: null,
      };
    case "require-2fa":
      return {
        status: "require-2fa",
        username: action.username,
        invalidCode: null,
        token: action.token,
        expire: expireTimestamp(action.tokenTtl),
        isLoading: false,
      };
    case "password-expired":
      return {
        status: "password-expired",
        username: action.username,
        token: action.token,
        expire: expireTimestamp(action.tokenTtl),
        isLoading: false,
      };
    case "login-2fa-cancel":
      if (state.status === "require-2fa" || state.status === "error") {
        return {
          status: "unauthed",
          isLoading: false,
          token: null,
        };
      }

      return state;
    case "login-2fa-success":
      if (state.status !== "require-2fa") {
        // This should be rare..
        return {
          status: "error",
          kind: "service-error",
          serviceError: {
            status: "error",
            message: "2FA-authentication tried when not expected.",
            track: null,
          },
          isLoading: false,
          token: null,
        };
      }

      return {
        status: "authed",
        user: action.user,
        token: action.token,
        expire: expireTimestamp(action.tokenTtl),
        isLoading: false,
      };
    case "invalid-2fa-code":
      if (state.status !== "require-2fa") {
        // This should be rare..
        return {
          status: "error",
          kind: "service-error",
          serviceError: {
            status: "error",
            message: "2FA-invalid code when not expected.",
            track: null,
          },
          isLoading: false,
          token: null,
        };
      }

      return {
        status: "require-2fa",
        username: state.username,
        token: state.token,
        expire: state.expire,
        invalidCode: action.code,
        isLoading: false,
      };
    case "login-2fa-timeout":
      return {
        status: "error",
        token: null,
        kind: "2fa-login-timeout",
        isLoading: false,
      };
    case "password-change-cancel":
      return {
        status: "unauthed",
        isLoading: false,
        token: null,
      };
    case "password-change-success":
      return {
        status: "unauthed",
        passwordIsChanged: true,
        isLoading: false,
        token: null,
      };
    case "token-refresh-success":
      if (
        state.status !== "authed" &&
        state.status !== "require-2fa" &&
        state.status !== "password-expired"
      ) {
        return state;
      }

      return { ...state, expire: expireTimestamp(action.tokenTtl) };
    case "error":
      return {
        status: "error",
        kind: action.kind,
        isLoading: false,
        token: null,
      };
    case "service-error":
      return {
        status: "error",
        kind: "service-error",
        serviceError: action.response,
        isLoading: false,
        token: null,
      };
    case "set-unauthed":
      localStorage.removeItem(localStoreKey);
      return { status: "unauthed", isLoading: false, token: null };
    case "set-is-loading":
      return { ...state, isLoading: true };
    default:
      return assertNever(action);
  }
}

export const useAuthService = () => {
  const [authState, dispatch] = useReducer(
    authReducer,
    {
      status: "unauthed",
      isLoading: false,
      token: null,
    },
    reducerInit
  );

  // Save changed authState to localStorage
  useEffect(() => {
    switch (authState.status) {
      case "unauthed":
      case "error":
        localStorage.removeItem(localStoreKey);
        break;
      case "authed":
      case "require-2fa":
      case "password-expired":
        localStorage.setItem(localStoreKey, JSON.stringify(authState));
        break;
      default:
        assertNever(authState);
    }
  }, [authState]);

  const createToken = useCallback(() => {
    interface CreateTokenResponse {
      token: string;
      token_ttl: number;
    }

    return fetchFromApi<CreateTokenResponse>({
      href: buildApiHref("create-token"),
      method: "POST",
    });
  }, []);

  const login = useCallback(
    async (
      username: string,
      password: string
    ): Promise<ServiceResponse<LoginStatusResponse>> => {
      dispatch({ type: "set-is-loading" });

      // We first need a login token from the web api
      const createTokenResponse = await createToken();
      if (createTokenResponse.status === "error") {
        dispatch({ type: "service-error", response: createTokenResponse });
        return createTokenResponse;
      }

      // TODO: Remove this when all old device tokens have expired
      // Migrate old style localStorage key to new style
      const oldDeviceToken = localStorage.getItem("deviceToken");
      if (oldDeviceToken) {
        localStorage.setItem(`deviceToken-${username}`, oldDeviceToken);
        localStorage.removeItem("deviceToken");
      }

      const response = await fetchFromApi<LoginStatusResponse>({
        href: buildAuthApiHref("login"),
        method: "POST",
        token: createTokenResponse.payload.token,
        body: {
          username,
          password,
          device_token: localStorage.getItem(`deviceToken-${username}`),
        },
      });

      if (response.status === "error") {
        if (response.httpStatus === 401) {
          dispatch({
            type: "bad-username-or-password",
            username,
            password,
          });
        } else {
          dispatch({ type: "service-error", response });
        }
        return response;
      }

      switch (response.payload.auth_state) {
        case "AUTHED":
          dispatch({
            type: "login-success",
            user: {
              id: response.payload.user.id,
              organizationId: response.payload.user.organization_id,
              username: response.payload.user.username,
              fullName: response.payload.user.full_name,
              group: response.payload.user.group,
              phone: response.payload.user.phone,
              email: response.payload.user.email,
              servicePrivileges: response.payload.user.service_privileges,
              errorCasePrivileges: response.payload.user.error_case_privileges,
              orderPrivileges: response.payload.user.order_privileges,
            },
            token: response.payload.token,
            tokenTtl: response.payload.token_ttl,
          });
          break;
        case "REQUIRE_2FA":
          dispatch({
            type: "require-2fa",
            username,
            token: response.payload.token,
            tokenTtl: response.payload.token_ttl,
          });
          break;
        case "PASSWORD_EXPIRED":
          dispatch({
            type: "password-expired",
            username,
            token: response.payload.token,
            tokenTtl: response.payload.token_ttl,
          });
          break;
        default:
          assertNever(response.payload);
      }

      return response;
    },
    [createToken]
  );

  const login2fa = useCallback(
    async (code: string, trustDeviceName: string | null) => {
      if (authState.status !== "require-2fa") {
        dispatch({ type: "error", kind: "unexpected-2fa-login" });
        return;
      }

      dispatch({ type: "set-is-loading" });

      type Login2faResponse = (
        | SuccessfulLoginResponse
        | PasswordExpiredLoginResponse
      ) & {
        device_token: string | null;
      };

      const response = await fetchFromApi<Login2faResponse>({
        href: buildAuthApiHref("token-auth-2fa"),
        method: "POST",
        token: authState.token,
        body: {
          code,
          trust_device_name: trustDeviceName,
        },
      });

      if (response.status === "error") {
        if (response.httpStatus === 401) {
          dispatch({ type: "invalid-2fa-code", code });
        } else {
          dispatch({ type: "service-error", response });
        }
        return;
      }

      if (response.payload.device_token) {
        localStorage.setItem(
          `deviceToken-${authState.username}`,
          response.payload.device_token
        );
      }

      switch (response.payload.auth_state) {
        case "AUTHED":
          dispatch({
            type: "login-2fa-success",
            user: {
              id: response.payload.user.id,
              organizationId: response.payload.user.organization_id,
              username: response.payload.user.username,
              fullName: response.payload.user.full_name,
              group: response.payload.user.group,
              phone: response.payload.user.phone,
              email: response.payload.user.email,
              servicePrivileges: response.payload.user.service_privileges,
              errorCasePrivileges: response.payload.user.error_case_privileges,
              orderPrivileges: response.payload.user.order_privileges,
            },
            token: response.payload.token,
            tokenTtl: response.payload.token_ttl,
          });
          break;
        case "PASSWORD_EXPIRED":
          dispatch({
            type: "password-expired",
            username: authState.username,
            token: response.payload.token,
            tokenTtl: response.payload.token_ttl,
          });
          break;
        default:
          assertNever(response.payload);
      }
    },
    [authState]
  );

  const login2faCancel = useCallback((): void => {
    dispatch({ type: "login-2fa-cancel" });
  }, []);

  const passwordChangeCancel = useCallback((): void => {
    dispatch({ type: "password-change-cancel" });
  }, []);

  const passwordChange = useCallback(
    async (newPassword: string): Promise<ServiceResponse<unknown> | void> => {
      if (authState.status === "unauthed" || authState.status === "error") {
        dispatch({ type: "error", kind: "password-change-while-not-authed" });
        return;
      }

      dispatch({ type: "set-is-loading" });

      const response = await fetchFromApi<unknown>({
        href: buildAuthApiHref("password-change"),
        method: "POST",
        token: authState.token,
        body: {
          new_password: newPassword,
        },
      });

      switch (response.status) {
        case "error":
          dispatch({ type: "service-error", response });
          break;
        case "loaded":
          dispatch({ type: "password-change-success" });
          break;
        default:
          assertNever(response);
      }

      return response;
    },
    [authState.status, authState.token]
  );

  const tokenRefresh = useCallback(async () => {
    if (!authState.token) {
      dispatch({ type: "error", kind: "token-refresh-while-not-authed" });
      return;
    }

    const response = await fetchFromApi<TokenRefreshResponse>({
      href: buildAuthApiHref("token-refresh"),
      method: "POST",
      token: authState.token,
    });

    if (response.status === "error") {
      if (response.httpStatus === 401) {
        dispatch({ type: "error", kind: "token-refresh-unauthorized" });
      } else {
        dispatch({ type: "service-error", response });
      }
      return;
    }

    dispatch({
      type: "token-refresh-success",
      token: response.payload.token,
      tokenTtl: response.payload.token_ttl,
    });
  }, [authState.token]);

  const logout = useCallback(async () => {
    if (!authState.token) {
      dispatch({ type: "set-unauthed" });
      return;
    }

    dispatch({ type: "set-is-loading" });

    const response = await fetchFromApi<void>({
      href: buildAuthApiHref("logout"),
      method: "POST",
      token: authState.token,
    });

    if (response.status === "error") {
      dispatch({ type: "service-error", response });
    } else {
      dispatch({ type: "set-unauthed" });
    }
  }, [authState.token]);

  useEffect(() => {
    if (authState.status === "error" || authState.status === "unauthed") {
      return; // Not authed
    }

    const timeLeft = authState.expire - Date.now();
    if (timeLeft < 0) {
      // Token already expired
      dispatch({ type: "set-unauthed" });
      return;
    }

    function onExpiry() {
      if (authState.status === "require-2fa") {
        // Not allowed to refresh token for 2fa
        dispatch({ type: "login-2fa-timeout" });
        return;
      }

      if (authState.token) {
        tokenRefresh();
      }
    }

    const refreshDelay = timeLeft * 0.75;
    const timerId = setTimeout(onExpiry, refreshDelay);

    return () => clearTimeout(timerId);
  }, [authState, tokenRefresh]);

  return {
    authState,
    login,
    login2fa,
    login2faCancel,
    logout,
    passwordChange,
    passwordChangeCancel,
  };
};

/** Assertion function to narrow down AuthStateAuthed to AuthStateAdmin */
export function isAdmin(a: AuthStateAuthed): a is AuthStateAdmin {
  return a.user.group === "FB-PORTAL-ADMIN";
}
