/** Convert a Date to a short locale dependent string.
 *
 * This is "YYYY-MM-DD HH:MM" in locale "sv", and "MM/DD/YY HH:MM (AM|PM)" in
 * locale "en".
 */
export function dateToShortString(date: Date): string {
  return date.toLocaleString(undefined, {
    dateStyle: "short",
    timeStyle: "short",
  });
}

/** Convert a ISO date string to a short locale dependent string.
 *
 * This is "YYYY-MM-DD HH:MM" in locale "sv", and "MM/DD/YY HH:MM (AM|PM)" in
 * locale "en".
 */
export function parseDateAndFormatShort(date: string): string {
  return dateToShortString(new Date(date));
}
