import {
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
} from "@elastic/eui";
import moment from "moment";
import React, { useState } from "react";
import { Order, useOrderUpdateService } from "../../../api/resources/order";
import { AuthStateAdmin } from "../../../api/useAuthService";
import { isError, isLoading } from "../../../api/useService";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import { UserSelectModal } from "../../../widgets/UserSelectModal";
import UserNameCell from "../../users/UserNameCell";

interface Props {
  authState: AuthStateAdmin;
  order: Order;
}

export function OrderAssignedUser({ authState, order }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [updateService, postUpdate] = useOrderUpdateService(
    authState,
    order.id
  );

  function handleClick() {
    setIsOpen((last) => !last);
  }

  function handleSelected(userId: number | null) {
    setIsOpen(false);
    if (
      order.service.alarm_priority === undefined ||
      order.service.internal_note === undefined ||
      order.service.delivery_date === undefined ||
      order.service.termination_date === undefined
    ) {
      // Only users have undefined in these fields, and they should no get here
      throw new Error("Can't post order update: undefined fields");
    }

    postUpdate({
      assignedUserId: userId,
      status: order.status,
      internalNote: order.internal_note || "",
      customerRef: order.customer_ref,
      technicalContact: order.technical_contact,
      propertyOwnerContact: order.property_owner_contact,
      updatesEmail: order.updates_email,
      requestedDeliveryDate: order.requested_delivery_date
        ? moment(order.requested_delivery_date)
        : null,
      expectedDeliveryDate: order.expected_delivery_date
        ? moment(order.expected_delivery_date)
        : null,
      actualDeliveryDate: order.actual_delivery_date
        ? moment(order.actual_delivery_date)
        : null,
      productName: order.service.product_name,
      productCapacity: order.service.product_capacity,
      productTechnology: order.service.product_technology,
      serviceNumber: order.service.number,
      serviceGenerateNumber: false,
      serviceStatus: order.service.status,
      serviceCustomerRef: order.service.customer_ref,
      serviceInternalNote: order.service.internal_note,
      serviceAlarmPriority: order.service.alarm_priority,
      aGraphInterfaceId: order.service.a_graph_interface_id,
      aLocationName: order.service.a_location_name,
      aAddress: order.service.a_address,
      aDemarcation: order.service.a_demarcation,
      aPropertyDesignation: order.service.a_property_designation,
      aToBMeasurement: order.service.a_to_b_measurement,
      bLocationName: order.service.b_location_name,
      bAddress: order.service.b_address,
      bDemarcation: order.service.b_demarcation,
      bPropertyDesignation: order.service.b_property_designation,
      bToAMeasurement: order.service.b_to_a_measurement,
      serviceDeliveryDate: order.service.delivery_date
        ? moment(order.service.delivery_date)
        : null,
      serviceTerminationDate: order.service.termination_date
        ? moment(order.service.termination_date)
        : null,
    });
  }

  return (
    <>
      <EuiFlexGroup alignItems="center">
        {order.assigned_user && (
          <EuiFlexItem grow={false}>
            <UserNameCell user={order.assigned_user} />
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            onClick={handleClick}
            iconType="user"
            isLoading={isLoading(updateService)}
            size="s"
          >
            {order.assigned_user === null ? "Tilldela order" : "Ändra"}
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
      {isError(updateService) && (
        <EuiCallOut
          title="Kunde inte spara ansvarig"
          color="danger"
          iconType="alert"
        >
          <ServiceErrorMessage error={updateService} />
        </EuiCallOut>
      )}
      {isOpen && (
        <UserSelectModal
          authState={authState}
          initialUserId={order.assigned_user?.id || null}
          onClose={() => setIsOpen(false)}
          onSelected={handleSelected}
        />
      )}
    </>
  );
}

export interface UserSelectModalProps {
  authState: AuthStateAdmin;
  initialUserId: number | null;
  onClose(): void;
  onSelected(userId: number | null): void;
}

export default OrderAssignedUser;
