import {
  DropResult,
  EuiButtonEmpty,
  EuiDragDropContext,
  euiDragDropReorder,
  EuiDraggable,
  EuiDroppable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPopover,
  EuiPopoverFooter,
  EuiSwitch,
  EuiSwitchEvent,
  htmlIdGenerator,
  ResponderProvided,
} from "@elastic/eui";
import { useCallback, useMemo, useState } from "react";
import { TableColumnConfed } from "./useConfiguredColumns";

const makeId = htmlIdGenerator("option");

interface Props<Item> {
  columns: TableColumnConfed<Item>[];
  onChange(cols: TableColumnConfed<Item>[]): void;
  onReset(): void;
}

export const ColumnSelector = <Item extends unknown>({
  columns,
  onChange,
  onReset,
}: Props<Item>) => {
  const [isOpen, setIsOpen] = useState(false);

  const onDragEnd = useCallback(
    (result: DropResult, _provided: ResponderProvided) => {
      if (!result.destination) {
        return;
      }
      const reordered = euiDragDropReorder(
        columns,
        result.source.index,
        result.destination.index
      );
      onChange(reordered);
    },
    [onChange, columns]
  );

  const handleViewAll = useCallback(() => {
    const newCols = columns.map((col) => {
      return col.isVisible ? col : { ...col, isVisible: true };
    });
    onChange(newCols);
  }, [columns, onChange]);

  const handleHideAll = useCallback(() => {
    const newCols = columns.map((col) => {
      return col.isVisible ? { ...col, isVisible: false } : col;
    });
    onChange(newCols);
  }, [columns, onChange]);

  const handleSetVisibility = useCallback(
    (field: string, isVisible: boolean) => {
      const newCols = columns.map((col) => {
        if (col.field === field && col.isVisible !== isVisible) {
          return { ...col, isVisible };
        } else {
          return col;
        }
      });
      onChange(newCols);
    },
    [columns, onChange]
  );

  // Create options from columns and preferences
  const options = useMemo(() => {
    return columns.map((col) => ({
      id: makeId(col.field),
      label: col.name,
      checked: col.isVisible,
      onChange: (event: EuiSwitchEvent) =>
        handleSetVisibility(col.field, event.target.checked),
    }));
  }, [columns, handleSetVisibility]);

  return (
    <EuiPopover
      isOpen={isOpen}
      closePopover={() => setIsOpen(false)}
      panelPaddingSize="s"
      display="block"
      // Any transform in parents to EuiDraggable will mess with the positioning
      // while dragging.
      panelStyle={{ transform: "none" }}
      button={
        <div style={{ display: "flex" }}>
          <EuiButtonEmpty
            iconType="indexOpen"
            color="text"
            onClick={() => setIsOpen((last) => !last)}
          >
            Kolumner
          </EuiButtonEmpty>
        </div>
      }
    >
      <EuiDragDropContext onDragEnd={onDragEnd}>
        <EuiDroppable droppableId="columnOrder" spacing="s">
          {options.map(({ id, label, checked, onChange }, index) => (
            <EuiDraggable key={id} draggableId={id} index={index} spacing="s">
              <EuiFlexGroup
                responsive={false}
                gutterSize="m"
                alignItems="center"
              >
                <EuiFlexItem>
                  <EuiSwitch
                    label={label}
                    checked={checked}
                    compressed
                    onChange={onChange}
                    className="euiSwitch--mini"
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiIcon type="grab" />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiDraggable>
          ))}
        </EuiDroppable>
      </EuiDragDropContext>
      <EuiPopoverFooter paddingSize="none">
        <EuiButtonEmpty onClick={handleViewAll}>Visa alla</EuiButtonEmpty>
        <EuiButtonEmpty onClick={handleHideAll}>Visa ingen</EuiButtonEmpty>
        <EuiButtonEmpty onClick={onReset}>Återställ</EuiButtonEmpty>
      </EuiPopoverFooter>
    </EuiPopover>
  );
};

export default ColumnSelector;
