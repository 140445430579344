import {
  Criteria,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
} from "@elastic/eui";
import React, { useCallback, useMemo, useState } from "react";
import {
  ErrorCaseListItem,
  ErrorCaseStatus,
  useErrorCasesService,
} from "../../../api/resources/errorCase";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../../api/useService";
import { parseDateAndFormatShort } from "../../../utils/date";
import RouterLinkAdapter from "../../../utils/RouterLinkAdapter";
import { usePaginate } from "../../../utils/table-helpers";
import {
  errorCaseHref,
  errorCaseStatusToString,
} from "../../error-cases/view/ErrorCasePage";
import UserNameCell from "../../users/UserNameCell";

type Column = EuiBasicTableColumn<ErrorCaseListItem> & {
  adminOnly?: boolean;
};

const COLUMNS: Column[] = [
  {
    field: "number",
    name: "Ärende",
    dataType: "string",
    sortable: true,
    render: (_, errorCase) => (
      <RouterLinkAdapter to={errorCaseHref(errorCase.id)}>
        {(linkProps) => <EuiLink {...linkProps}>{errorCase.number}</EuiLink>}
      </RouterLinkAdapter>
    ),
  },
  {
    field: "assigned_user",
    name: "Ansvarig",
    render: (_, errorCase) =>
      errorCase.assigned_user ? (
        <UserNameCell user={errorCase.assigned_user} />
      ) : (
        ""
      ),
    dataType: "string",
    adminOnly: true,
  },
  {
    field: "status",
    name: "Status",
    sortable: true,
    render: (_, errorCase) => errorCaseStatusToString(errorCase.status),
    dataType: "string",
  },
  {
    field: "by_user.full_name",
    name: "Skapad av",
    sortable: true,
    render: (_, errorCase) => <UserNameCell user={errorCase.by_user} />,
    dataType: "string",
  },
  {
    field: "error_description",
    name: "Felbeskrivning",
    dataType: "string",
    sortable: false,
  },
  {
    field: "creation_time",
    name: "Skapad",
    dataType: "date",
    sortable: true,
    render: (_, errorCase) => parseDateAndFormatShort(errorCase.creation_time),
  },
  {
    field: "close_time",
    name: "Stängd",
    dataType: "date",
    sortable: true,
    render: (_, errorCase) =>
      errorCase.close_time ? parseDateAndFormatShort(errorCase.close_time) : "",
  },
];

const emptyErrorCases: ErrorCaseListItem[] = [];

interface Props {
  authState: AuthStateAuthed;
  serviceId: number;
}

export function ServiceErrorCasesTable({ authState, serviceId }: Props) {
  const errorCasesService = useErrorCasesService(authState, { serviceId });
  const errorCases = isLoaded(errorCasesService)
    ? errorCasesService.payload.items
    : emptyErrorCases;

  const [statusFilter, setStatusFilter] = useState<ErrorCaseStatus | null>(
    "open"
  );
  const [sortField, setSortField] = useState<keyof ErrorCaseListItem>("number");
  const [sortDirection, setSortDirection] = useState<"desc" | "asc">("asc");

  const openErrorCases = useMemo(
    () => errorCases.filter((e) => e.status === "open"),
    [errorCases]
  );
  const closedErrorCases = useMemo(
    () => errorCases.filter((e) => e.status === "closed"),
    [errorCases]
  );

  const filteredErrorCases = useMemo(() => {
    if (statusFilter === "open") {
      return openErrorCases;
    } else if (statusFilter === "closed") {
      return closedErrorCases;
    } else {
      return errorCases;
    }
  }, [statusFilter, errorCases, openErrorCases, closedErrorCases]);

  const {
    paginated: paginatedErrorCases,
    pagination,
    setPageIndex,
    setPageSize,
  } = usePaginate(filteredErrorCases);

  const visibleColumns = useMemo(() => {
    // Remove column where adminOnly=true for non-admins
    const filteredColumns = isAdmin(authState)
      ? COLUMNS
      : COLUMNS.filter((col) => !col.adminOnly);

    // Then remove the adminOnly property (React complains otherwise)
    return filteredColumns.map(
      ({ adminOnly, ...col }): EuiBasicTableColumn<ErrorCaseListItem> => col
    );
  }, [authState]);

  const handleTableChange = useCallback(
    (criteria: Criteria<ErrorCaseListItem>) => {
      if (criteria.page) {
        setPageIndex(criteria.page.index);
        setPageSize(criteria.page.size);
      }
      if (criteria.sort) {
        console.debug("Changed", criteria);
        setSortField(criteria.sort.field);
        setSortDirection(criteria.sort.direction);
      }
    },
    [setPageIndex, setPageSize]
  );

  const filterOpenClick = useCallback(() => {
    setStatusFilter((prev) => (prev === "open" ? null : "open"));
  }, []);

  const filterClosedClick = useCallback(() => {
    setStatusFilter((prev) => (prev === "closed" ? null : "closed"));
  }, []);

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText>
            <h2>Felärenden</h2>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFilterGroup>
            <EuiFilterButton
              hasActiveFilters={statusFilter === "open"}
              numActiveFilters={openErrorCases.length}
              onClick={filterOpenClick}
              withNext
            >
              Pågående
            </EuiFilterButton>
            <EuiFilterButton
              hasActiveFilters={statusFilter === "closed"}
              numActiveFilters={closedErrorCases.length}
              onClick={filterClosedClick}
            >
              Avslutade
            </EuiFilterButton>
          </EuiFilterGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiBasicTable
        loading={isLoading(errorCasesService)}
        error={
          isError(errorCasesService)
            ? errorCasesService.message +
              (errorCasesService.track
                ? ` (track ${errorCasesService.track})`
                : "")
            : undefined
        }
        items={paginatedErrorCases}
        noItemsMessage={
          statusFilter === "open"
            ? "Inga öppna felärenden"
            : statusFilter === "closed"
            ? "Inga stängda felärenden"
            : "Inga felärenden"
        }
        columns={visibleColumns}
        pagination={pagination}
        sorting={{
          sort: {
            field: sortField,
            direction: sortDirection,
          },
        }}
        onChange={handleTableChange}
      />
    </>
  );
}

export default ServiceErrorCasesTable;
