import {
  EuiAvatar,
  EuiBadge,
  EuiCommentList,
  EuiCommentProps,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from "@elastic/eui";
import { ErrorCase } from "../../../api/resources/errorCase";
import { parseDateAndFormatShort } from "../../../utils/date";

interface Props {
  errorCase: ErrorCase;
}

export function ErrorCaseUpdates({ errorCase }: Props) {
  let comments: EuiCommentProps[] = [];

  comments.push({
    username: errorCase.by_user.full_name,
    timestamp: parseDateAndFormatShort(errorCase.creation_time),
    timelineAvatar: (
      <EuiAvatar
        name={errorCase.by_user.full_name}
        initialsLength={2}
      ></EuiAvatar>
    ),
    event: (
      <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
        <EuiFlexItem grow={false}>
          <EuiBadge color="success">Skapat</EuiBadge>
        </EuiFlexItem>
      </EuiFlexGroup>
    ),
    children: (
      <EuiText size="s" style={{ whiteSpace: "pre-wrap" }}>
        <p>{errorCase.error_description}</p>
      </EuiText>
    ),
  });

  comments = comments.concat(
    errorCase.updates.map((update) => ({
      username: update.by_user.full_name,
      timestamp: parseDateAndFormatShort(update.time),
      timelineIcon: (
        <EuiAvatar
          name={update.by_user.full_name}
          initialsLength={2}
        ></EuiAvatar>
      ),
      event: (
        <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
          {update.status_change === "open" && (
            <EuiFlexItem grow={false}>
              <EuiBadge color="warning">Återöppnat</EuiBadge>
            </EuiFlexItem>
          )}
          {update.status_change === "closed" && (
            <EuiFlexItem grow={false}>
              <EuiBadge color="success">Avslutat</EuiBadge>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      ),
      type: "regular",
      children: (
        <EuiText size="s" style={{ whiteSpace: "pre-wrap" }}>
          <p>{update.message}</p>
        </EuiText>
      ),
    }))
  );

  if (errorCase.status === "open") {
    comments.push({
      timelineAvatar: "inspect",
      username: "Pågår",
    });
  }

  if (errorCase.status === "closed" && errorCase.close_time) {
    comments.push({
      timelineAvatar: "check",
      username: "Avslutat",
      timestamp: parseDateAndFormatShort(errorCase.close_time),
    });
  }

  return <EuiCommentList comments={comments} />;
}

export default ErrorCaseUpdates;
