import {
  EuiButton,
  EuiCallOut,
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSelectOption,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTextArea,
} from "@elastic/eui";
import emailValidator from "email-validator";
import { FormikErrors, FormikProps, withFormik } from "formik";
import moment, { Moment } from "moment";
import "moment/locale/sv";
import React from "react";
import { NetmonCoreInterface } from "../../../api/resources/netmon_core_interface";
import {
  Order,
  OrderStatus,
  OrderUpdateParams,
} from "../../../api/resources/order";
import { Organization } from "../../../api/resources/organization";
import { AlarmPriority, ServiceStatus } from "../../../api/resources/service";
import { AuthStateAdmin } from "../../../api/useAuthService";
import DefaultOrTextRow from "../../../utils/form/DefaultOrTextRow";
import SelectRow from "../../../utils/form/SelectRow";
import propertyOf from "../../../utils/propertyOf";
import NetmonCoreInterfaceSelect from "../../../widgets/NetmonCoreInterfaceSelect";
import {
  alarmPriorityOptions,
  serviceStatusToString,
} from "../../services/service-utils";
import { orderStatusToString } from "../order-utils";

export interface Fields {
  status: OrderStatus;
  internalNote: string;
  customerRef: string;
  technicalContact: string;
  propertyOwnerContact: string;
  updatesEmail: string | null;
  requestedDeliveryDate: Moment | null;
  expectedDeliveryDate: Moment | null;
  actualDeliveryDate: Moment | null;
  productName: string;
  productCapacity: string;
  productTechnology: string;
  serviceNumber: string;
  serviceGenerateNumber: boolean;
  serviceStatus: ServiceStatus;
  serviceCustomerRef: string;
  serviceInternalNote: string;
  serviceAlarmPriority: AlarmPriority;
  aGraphInterface: NetmonCoreInterface | null;
  monitorAGraphInterface: boolean;
  aLocationName: string;
  aAddress: string;
  aDemarcation: string;
  aPropertyDesignation: string;
  aToBMeasurement: string;
  bLocationName: string;
  bAddress: string;
  bDemarcation: string;
  bPropertyDesignation: string;
  bToAMeasurement: string;
  serviceDeliveryDate: Moment | null;
  serviceTerminationDate: Moment | null;
}

interface InnerFormProps extends FormikProps<Fields> {
  authState: AuthStateAdmin;
  organization: Organization;
  order: Order;
}

const orderStatusOptions: EuiSelectOption[] = [
  "sent",
  "canceling-sent",
  "needs-tender",
  "tendered",
  "delivering",
  "canceling-delivery",
  "delivered",
  "delivery-not-possible",
  "removed",
].map((value) => ({
  value,
  text: orderStatusToString(value as OrderStatus),
}));

const serviceStatusOptions: EuiSelectOption[] = [
  { value: "active", text: serviceStatusToString("active") },
  { value: "inactive", text: serviceStatusToString("inactive") },
];

const InnerForm = ({
  authState,
  organization,
  order,
  values,
  errors,
  handleSubmit,
  isSubmitting,
  getFieldProps,
  setFieldValue,
  setFieldTouched,
}: InnerFormProps) => (
  <EuiForm component="form" onSubmit={handleSubmit}>
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiText>
          <h3>Orderinfo</h3>
        </EuiText>
        <EuiFormRow label="Organisation">
          <EuiFieldText value={order.organization_name} readOnly />
        </EuiFormRow>
        <EuiFormRow label="Ordernummer">
          <EuiFieldText value={order.number} readOnly />
        </EuiFormRow>
        <SelectRow
          name={propertyOf<Fields>("status")}
          label="Orderstatus"
          options={orderStatusOptions}
        />
        <EuiFormRow
          label="Orderreferens"
          helpText="Fyll i om ni vill ha en egen referens på ordern."
          isInvalid={!!errors.customerRef}
          error={errors.customerRef}
        >
          <EuiFieldText {...getFieldProps<string>("customerRef")} />
        </EuiFormRow>
        <EuiFormRow
          label="Önskat leveransdatum"
          helpText="Lämna tomt för snarast."
          isInvalid={!!errors.requestedDeliveryDate}
          error={errors.requestedDeliveryDate}
        >
          <EuiDatePicker
            selected={values.requestedDeliveryDate}
            onClear={() => setFieldValue("requestedDeliveryDate", null)}
            onChange={(v) => setFieldValue("requestedDeliveryDate", v)}
            onBlur={() => setFieldTouched("requestedDeliveryDate", true)}
            dateFormat="YYYY-MM-DD"
          />
        </EuiFormRow>
        <EuiFormRow
          label="Beräknad leveransdag"
          helpText="Uppskattning som ges till kunden."
          isInvalid={!!errors.expectedDeliveryDate}
          error={errors.expectedDeliveryDate}
        >
          <EuiDatePicker
            selected={values.expectedDeliveryDate}
            onClear={() => setFieldValue("expectedDeliveryDate", null)}
            onChange={(v) => setFieldValue("expectedDeliveryDate", v)}
            onBlur={() => setFieldTouched("expectedDeliveryDate", true)}
            dateFormat="YYYY-MM-DD"
          />
        </EuiFormRow>
        <EuiFormRow
          label="Faktisk leveransdag"
          helpText="Dagen ordern blev levererad."
          isInvalid={!!errors.actualDeliveryDate}
          error={errors.actualDeliveryDate}
        >
          <EuiDatePicker
            selected={values.actualDeliveryDate}
            onClear={() => setFieldValue("actualDeliveryDate", null)}
            onChange={(v) => setFieldValue("actualDeliveryDate", v)}
            onBlur={() => setFieldTouched("actualDeliveryDate", true)}
            dateFormat="YYYY-MM-DD"
          />
        </EuiFormRow>
        <EuiFormRow
          label="Teknisk kontakt"
          helpText="Gärna namn, tel.nr, mejl."
          isInvalid={!!errors.technicalContact}
          error={errors.technicalContact}
        >
          <EuiFieldText {...getFieldProps<string>("technicalContact")} />
        </EuiFormRow>
        <EuiFormRow
          label="Kontakt fastighetsägare"
          helpText="Gärna namn, tel.nr, mejl."
          isInvalid={!!errors.propertyOwnerContact}
          error={errors.propertyOwnerContact}
        >
          <EuiFieldText {...getFieldProps<string>("propertyOwnerContact")} />
        </EuiFormRow>
        <DefaultOrTextRow
          name={propertyOf<Fields>("updatesEmail")}
          label="Mejladress för statusuppdateringar"
          labelDefault={`Organisationens mejl: ${
            organization.order_emails.join(", ") || "saknas"
          }`}
          labelSpecify="Angiven:"
          inputMode="email"
        />
        <EuiFormRow
          label="Intern orderinformation"
          helpText="Extra information om ordern. Ej synligt för kund."
          isInvalid={!!errors.internalNote}
          error={errors.internalNote}
        >
          <EuiTextArea {...getFieldProps<string>("internalNote")} />
        </EuiFormRow>
      </EuiFlexItem>

      <EuiFlexItem>
        <EuiText>
          <h3>Förbindelseinfo</h3>
        </EuiText>
        <EuiFormRow
          label="Förbindelsenummer"
          helpText="OBS: När ett nummer har valts så går det inte att ändra"
          isInvalid={!!errors.serviceNumber}
          error={errors.serviceNumber}
        >
          <EuiFieldText
            {...getFieldProps<string>("serviceNumber")}
            readOnly={order.service.number !== null}
          />
        </EuiFormRow>
        <EuiFormRow>
          <EuiSwitch
            label="Generera förbindelsenummer"
            checked={values.serviceGenerateNumber}
            onChange={(event) =>
              setFieldValue("serviceGenerateNumber", event.target.checked)
            }
            disabled={order.service.number !== null}
          />
        </EuiFormRow>
        <SelectRow
          name={propertyOf<Fields>("serviceStatus")}
          label="Förbindelsestatus"
          options={serviceStatusOptions}
        />
        <EuiFormRow
          label="Förbindelsereferens"
          helpText="Fyll i om ni vill ha en egen referens på förbindelsen."
          isInvalid={!!errors.serviceCustomerRef}
          error={errors.serviceCustomerRef}
        >
          <EuiFieldText {...getFieldProps<string>("serviceCustomerRef")} />
        </EuiFormRow>
        <EuiFormRow
          label="Produkt"
          isInvalid={!!errors.productName}
          error={errors.productName}
        >
          <EuiFieldText {...getFieldProps<string>("productName")} />
        </EuiFormRow>
        <EuiFormRow
          label="Kapacitet"
          isInvalid={!!errors.productCapacity}
          error={errors.productCapacity}
        >
          <EuiFieldText
            {...getFieldProps<string>(propertyOf<Fields>("productCapacity"))}
          />
        </EuiFormRow>
        <EuiFormRow
          label="Teknik"
          isInvalid={!!errors.productTechnology}
          error={errors.productTechnology}
        >
          <EuiFieldText
            {...getFieldProps<string>(propertyOf<Fields>("productTechnology"))}
          />
        </EuiFormRow>
        <EuiFormRow
          label="Förbindelsens startdatum"
          helpText="Dagen då förbindelsen levererades."
          isInvalid={!!errors.serviceDeliveryDate}
          error={errors.serviceDeliveryDate}
        >
          <EuiDatePicker
            selected={values.serviceDeliveryDate}
            onClear={() => setFieldValue("serviceDeliveryDate", null)}
            onChange={(v) => setFieldValue("serviceDeliveryDate", v)}
            onBlur={() => setFieldTouched("serviceDeliveryDate", true)}
            dateFormat="YYYY-MM-DD"
          />
        </EuiFormRow>
        <EuiFormRow
          label="Förbindelsens slutdatum"
          helpText="Dagen då förbindelsen kopplades ned (eller slutade faktureras)."
          isInvalid={!!errors.serviceTerminationDate}
          error={errors.serviceTerminationDate}
        >
          <EuiDatePicker
            selected={values.serviceTerminationDate}
            onClear={() => setFieldValue("serviceTerminationDate", null)}
            onChange={(v) => setFieldValue("serviceTerminationDate", v)}
            onBlur={() => setFieldTouched("serviceTerminationDate", true)}
            dateFormat="YYYY-MM-DD"
          />
        </EuiFormRow>
        <EuiFormRow
          label="Intern förbindelseinformation"
          helpText="Extra information på förbindelsen, som inte är relaterad till denna order. Ej synlig för kund."
          isInvalid={!!errors.serviceInternalNote}
          error={errors.serviceInternalNote}
        >
          <EuiTextArea {...getFieldProps<string>("serviceInternalNote")} />
        </EuiFormRow>
        <SelectRow
          name={propertyOf<Fields>("serviceAlarmPriority")}
          label="Larmprioritet"
          options={alarmPriorityOptions}
        />
      </EuiFlexItem>

      <EuiFlexItem>
        <EuiText>
          <h3>A-sida</h3>
        </EuiText>
        <EuiFormRow
          label="Platsnamn"
          helpText="T.ex. Småskolan, Vattentornet eller Nod 1234."
          isInvalid={!!errors.aLocationName}
          error={errors.aLocationName}
        >
          <EuiFieldText {...getFieldProps<string>("aLocationName")} />
        </EuiFormRow>
        <EuiFormRow
          label="Adress"
          helpText="T.ex. Skolstigen 2C Lgh 1003, 234 56 Enköping."
          isInvalid={!!errors.aAddress}
          error={errors.aAddress}
        >
          <EuiFieldText {...getFieldProps<string>("aAddress")} />
        </EuiFormRow>
        <EuiFormRow
          label="Avlämning"
          isInvalid={!!errors.aDemarcation}
          error={errors.aDemarcation}
        >
          <EuiFieldText {...getFieldProps<string>("aDemarcation")} />
        </EuiFormRow>
        <EuiFormRow
          label="Fastighetsbeteckning"
          isInvalid={!!errors.aPropertyDesignation}
          error={errors.aPropertyDesignation}
        >
          <EuiFieldText {...getFieldProps<string>("aPropertyDesignation")} />
        </EuiFormRow>
        <EuiFormRow
          label="Mätresultat A"
          isInvalid={!!errors.aToBMeasurement}
          error={errors.aToBMeasurement}
        >
          <EuiTextArea
            rows={2}
            {...getFieldProps<string>(propertyOf<Fields>("aToBMeasurement"))}
          />
        </EuiFormRow>
        <EuiFormRow
          label="Switchport för trafikgraf"
          isInvalid={!!errors.aGraphInterface}
          error={errors.aGraphInterface}
          fullWidth
        >
          <NetmonCoreInterfaceSelect
            authState={authState}
            selectedInterface={values.aGraphInterface}
            onSelect={(iface) =>
              setFieldValue(propertyOf<Fields>("aGraphInterface"), iface)
            }
            onSelectNone={() =>
              setFieldValue(propertyOf<Fields>("aGraphInterface"), null)
            }
          />
        </EuiFormRow>
        <EuiFormRow
          isInvalid={!!errors.monitorAGraphInterface}
          error={errors.monitorAGraphInterface}
        >
          <EuiSwitch
            disabled={!values.aGraphInterface}
            label="Lägg till larmövervakning för porten"
            checked={values.monitorAGraphInterface}
            onChange={(e) => {
              setFieldValue("monitorAGraphInterface", e.target.checked);
            }}
          />
        </EuiFormRow>{" "}
        <EuiSpacer />
        <EuiText>
          <h3>B-sida</h3>
        </EuiText>
        <EuiFormRow>
          <EuiCallOut
            iconType="help"
            title="B-sida behövs bara för produkter med två sidor."
            size="s"
          />
        </EuiFormRow>
        <EuiFormRow
          label="Platsnamn"
          helpText="T.ex. Småskolan, Vattentornet eller Nod 1234."
          isInvalid={!!errors.bLocationName}
          error={errors.bLocationName}
        >
          <EuiFieldText {...getFieldProps<string>("bLocationName")} />
        </EuiFormRow>
        <EuiFormRow
          label="Adress"
          helpText="T.ex. Skolstigen 2C Lgh 1003, 234 56 Enköping."
          isInvalid={!!errors.bAddress}
          error={errors.bAddress}
        >
          <EuiFieldText {...getFieldProps<string>("bAddress")} />
        </EuiFormRow>
        <EuiFormRow
          label="Avlämning"
          isInvalid={!!errors.bDemarcation}
          error={errors.bDemarcation}
        >
          <EuiFieldText {...getFieldProps<string>("bDemarcation")} />
        </EuiFormRow>
        <EuiFormRow
          label="Fastighetsbeteckning"
          isInvalid={!!errors.bPropertyDesignation}
          error={errors.bPropertyDesignation}
        >
          <EuiFieldText {...getFieldProps<string>("bPropertyDesignation")} />
        </EuiFormRow>
        <EuiFormRow
          label="Mätresultat B"
          isInvalid={!!errors.bToAMeasurement}
          error={errors.bToAMeasurement}
        >
          <EuiTextArea
            rows={2}
            {...getFieldProps<string>(propertyOf<Fields>("bToAMeasurement"))}
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
    <EuiSpacer />

    <EuiButton iconType="save" type="submit" fill isLoading={isSubmitting}>
      Spara
    </EuiButton>
  </EuiForm>
);

interface Props {
  order: Order;
  onSubmit: (fields: Fields) => Promise<unknown>;
  authState: AuthStateAdmin;
  organization: Organization;
}

export const OrderEditForm = withFormik<Props, Fields>({
  displayName: "OrderEditForm",
  mapPropsToValues: ({ order }) => ({
    status: order.status,
    internalNote: order.internal_note || "",
    customerRef: order.customer_ref,
    technicalContact: order.technical_contact,
    propertyOwnerContact: order.property_owner_contact,
    updatesEmail: order.updates_email || null,
    requestedDeliveryDate: order.requested_delivery_date
      ? moment(order.requested_delivery_date)
      : null,
    expectedDeliveryDate: order.expected_delivery_date
      ? moment(order.expected_delivery_date)
      : null,
    actualDeliveryDate: order.actual_delivery_date
      ? moment(order.actual_delivery_date)
      : null,
    serviceNumber: order.service.number || "",
    serviceGenerateNumber: false,
    serviceStatus: order.service.status,
    productName: order.service.product_name,
    productCapacity: order.service.product_capacity,
    productTechnology: order.service.product_technology,
    serviceCustomerRef: order.service.customer_ref,
    serviceInternalNote: order.service.internal_note || "",
    serviceAlarmPriority: order.service.alarm_priority || "high",
    aGraphInterface: order.service.a_graph_interface,
    monitorAGraphInterface: true,
    aLocationName: order.service.a_location_name,
    aAddress: order.service.a_address,
    aDemarcation: order.service.a_demarcation,
    aPropertyDesignation: order.service.a_property_designation,
    aToBMeasurement: order.service.a_to_b_measurement,
    bLocationName: order.service.b_location_name,
    bAddress: order.service.b_address,
    bDemarcation: order.service.b_demarcation,
    bPropertyDesignation: order.service.b_property_designation,
    bToAMeasurement: order.service.b_to_a_measurement,
    serviceDeliveryDate: order.service.delivery_date
      ? moment(order.service.delivery_date)
      : null,
    serviceTerminationDate: order.service.termination_date
      ? moment(order.service.termination_date)
      : null,
  }),
  validate: (values, { order }) => {
    const errors: FormikErrors<OrderUpdateParams> = {};

    if (values.productName) {
      if (values.productName.length > 64) {
        const charsOver = values.productName.length - 64;
        errors.productName = `${charsOver} tecken för långt`;
      }
    } else if (values.status === "delivered") {
      errors.productName = "Krävs när orderstatus är levererad";
    }

    if (values.productCapacity) {
      const charsOver = values.productCapacity.length - 64;
      if (charsOver > 0) {
        errors.productCapacity = `${charsOver} tecken för långt`;
      }
    }

    if (values.productTechnology) {
      const charsOver = values.productTechnology.length - 64;
      if (charsOver > 0) {
        errors.productTechnology = `${charsOver} tecken för långt`;
      }
    }

    if (values.serviceCustomerRef && values.serviceCustomerRef.length > 64) {
      const charsOver = values.serviceCustomerRef.length - 64;
      errors.serviceCustomerRef = `${charsOver} tecken för långt`;
    }

    if (values.customerRef && values.customerRef.length > 64) {
      const charsOver = values.customerRef.length - 64;
      errors.customerRef = `${charsOver} tecken för långt`;
    }

    if (
      values.requestedDeliveryDate &&
      !values.requestedDeliveryDate.isValid()
    ) {
      errors.requestedDeliveryDate = "Ogiltigt";
    }

    if (values.expectedDeliveryDate) {
      if (
        values.status === "sent" ||
        values.status === "canceling-sent" ||
        values.status === "needs-tender" ||
        values.status === "delivery-not-possible"
      ) {
        errors.expectedDeliveryDate = `Måste vara tom när orderstatus är "${orderStatusToString(
          values.status
        )}"`;
      }
    }

    if (values.actualDeliveryDate) {
      if (values.status !== "delivered") {
        errors.actualDeliveryDate = `Måste vara tom när orderstatus är "${orderStatusToString(
          values.status
        )}"`;
      }
    } else {
      if (values.status === "delivered") {
        errors.actualDeliveryDate = `Krävs när orderstatus är "${orderStatusToString(
          values.status
        )}"`;
      }
    }

    if (values.internalNote.length > 2048) {
      const charsOver = values.internalNote.length - 2048;
      errors.internalNote = `${charsOver} tecken för långt`;
    }

    if (values.serviceNumber) {
      const charsOver = values.serviceNumber.length - 32;
      if (charsOver > 0) {
        errors.serviceNumber = `${charsOver} tecken för långt`;
      } else if (values.serviceGenerateNumber) {
        errors.serviceNumber = "Lämna tom när förbindelsenummer ska genereras";
      }
    }

    if (
      values.status === "delivered" &&
      !values.serviceNumber &&
      !values.serviceGenerateNumber
    ) {
      errors.serviceNumber = `Måste anges eller genereras när orderstatus är "${orderStatusToString(
        values.status
      )}"`;
    }

    if (values.serviceStatus === "active") {
      if (
        (order.type === "new" && values.status !== "delivered") ||
        (order.type === "end" && values.status === "delivered")
      ) {
        errors.serviceStatus = `Måste vara Inaktiv när orderstatus är "${orderStatusToString(
          values.status
        )}"`;
      }
    } else if (values.serviceStatus === "inactive") {
      if (
        (order.type === "new" && values.status === "delivered") ||
        (order.type === "end" && values.status !== "delivered")
      ) {
        errors.serviceStatus = `Måste vara Aktiv när orderstatus är "${orderStatusToString(
          values.status
        )}"`;
      }
    }

    if (values.serviceDeliveryDate) {
      if (order.type === "new" && values.status !== "delivered") {
        errors.serviceDeliveryDate = `Måste vara tom när orderstatus är "${orderStatusToString(
          values.status
        )}"`;
      }
    } else {
      if (order.type === "new" && values.status === "delivered") {
        errors.serviceDeliveryDate = `Måste anges när orderstatus är "${orderStatusToString(
          values.status
        )}"`;
      }
    }

    if (values.serviceTerminationDate) {
      if (order.type !== "end") {
        errors.serviceTerminationDate = "Får bara anges vid nedkoppling";
      } else if (values.status !== "delivered") {
        errors.serviceTerminationDate = `Måste vara tom när orderstatus är "${orderStatusToString(
          values.status
        )}"`;
      }
    } else {
      if (order.type === "end" && values.status === "delivered") {
        errors.serviceTerminationDate = `Måste anges när orderstatus är "${orderStatusToString(
          values.status
        )}"`;
      }
    }

    if (values.aLocationName && values.aLocationName.length > 64) {
      const charsOver = values.aLocationName.length - 64;
      errors.aLocationName = `${charsOver} tecken för långt`;
    }

    if (values.aAddress && values.aAddress.length > 128) {
      const charsOver = values.aAddress.length - 128;
      errors.aAddress = `${charsOver} tecken för långt`;
    }

    if (values.aDemarcation && values.aDemarcation.length > 256) {
      const charsOver = values.aDemarcation.length - 256;
      errors.aDemarcation = `${charsOver} tecken för långt`;
    }

    if (
      values.aPropertyDesignation &&
      values.aPropertyDesignation.length > 128
    ) {
      const charsOver = values.aPropertyDesignation.length - 128;
      errors.aPropertyDesignation = `${charsOver} tecken för långt`;
    }

    const aToBMeasurementCharsOver = values.aToBMeasurement.length - 64;
    if (aToBMeasurementCharsOver > 0) {
      errors.aToBMeasurement = `${aToBMeasurementCharsOver} tecken för långt`;
    }

    if (values.bLocationName && values.bLocationName.length > 64) {
      const charsOver = values.bLocationName.length - 64;
      errors.bLocationName = `${charsOver} tecken för långt`;
    }

    if (values.bAddress && values.bAddress.length > 128) {
      const charsOver = values.bAddress.length - 128;
      errors.bAddress = `${charsOver} tecken för långt`;
    }

    if (values.bDemarcation && values.bDemarcation.length > 256) {
      const charsOver = values.bDemarcation.length - 256;
      errors.bDemarcation = `${charsOver} tecken för långt`;
    }

    if (
      values.bPropertyDesignation &&
      values.bPropertyDesignation.length > 128
    ) {
      const charsOver = values.bPropertyDesignation.length - 128;
      errors.bPropertyDesignation = `${charsOver} tecken för långt`;
    }

    const bToAMeasurementCharsOver = values.bToAMeasurement.length - 64;
    if (bToAMeasurementCharsOver > 0) {
      errors.bToAMeasurement = `${bToAMeasurementCharsOver} tecken för långt`;
    }

    if (values.technicalContact && values.technicalContact.length > 128) {
      const charsOver = values.technicalContact.length - 128;
      errors.technicalContact = `${charsOver} tecken för långt`;
    }

    if (
      values.propertyOwnerContact &&
      values.propertyOwnerContact.length > 128
    ) {
      const charsOver = values.propertyOwnerContact.length - 128;
      errors.propertyOwnerContact = `${charsOver} tecken för långt`;
    }

    if (values.updatesEmail !== null) {
      if (!values.updatesEmail) {
        errors.updatesEmail = "Saknas";
      } else if (values.updatesEmail.length > 128) {
        const charsOver = values.updatesEmail.length - 128;
        errors.updatesEmail = `${charsOver} tecken för långt`;
      } else if (!emailValidator.validate(values.updatesEmail)) {
        errors.updatesEmail = "Ogiltigt format";
      }
    }

    if (values.serviceInternalNote.length > 2048) {
      const charsOver = values.serviceInternalNote.length - 2048;
      errors.serviceInternalNote = `${charsOver} tecken för långt`;
    }

    return errors;
  },
  validateOnMount: true,
  handleSubmit: async (values, { props, setValues }) => {
    const normalized: typeof values = {
      status: values.status,
      internalNote: values.internalNote.trim(),
      customerRef: values.customerRef.trim(),
      requestedDeliveryDate: values.requestedDeliveryDate,
      expectedDeliveryDate: values.expectedDeliveryDate,
      actualDeliveryDate: values.actualDeliveryDate,
      technicalContact: values.technicalContact.trim(),
      propertyOwnerContact: values.propertyOwnerContact.trim(),
      updatesEmail: values.updatesEmail && values.updatesEmail.trim(),
      serviceStatus: values.serviceStatus,
      productName: values.productName.trim(),
      productCapacity: values.productCapacity.trim(),
      productTechnology: values.productTechnology.trim(),
      serviceNumber: values.serviceNumber.trim(),
      serviceGenerateNumber: values.serviceGenerateNumber,
      serviceCustomerRef: values.serviceCustomerRef.trim(),
      serviceInternalNote: values.serviceInternalNote.trim(),
      serviceAlarmPriority: values.serviceAlarmPriority,
      aGraphInterface: values.aGraphInterface,
      monitorAGraphInterface: values.monitorAGraphInterface,
      aLocationName: values.aLocationName.trim(),
      aAddress: values.aAddress.trim(),
      aDemarcation: values.aDemarcation.trim(),
      aPropertyDesignation: values.aPropertyDesignation.trim(),
      aToBMeasurement: values.aToBMeasurement.trim(),
      bLocationName: values.bLocationName.trim(),
      bAddress: values.bAddress.trim(),
      bDemarcation: values.bDemarcation.trim(),
      bPropertyDesignation: values.bPropertyDesignation.trim(),
      bToAMeasurement: values.bToAMeasurement.trim(),
      serviceDeliveryDate: values.serviceDeliveryDate,
      serviceTerminationDate: values.serviceTerminationDate,
    };

    setValues(normalized);

    await props.onSubmit(normalized);
  },
})(InnerForm);

export default OrderEditForm;
