import {
  EuiButton,
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiSwitch,
  EuiTextArea,
} from "@elastic/eui";
import emailValidator from "email-validator";
import { FormikErrors, FormikProps, withFormik } from "formik";
import moment, { Moment } from "moment";
import "moment/locale/sv";
import React from "react";
import { OrderAddService } from "../../../api/resources/order";
import { Organization } from "../../../api/resources/organization";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import DefaultOrTextRow from "../../../utils/form/DefaultOrTextRow";
import SelectUserRow from "../../../utils/form/SelectUserRow";
import propertyOf from "../../../utils/propertyOf";
import { UserPreferences } from "../../../utils/useUserPreferences";

export interface Fields {
  customerRef: string;
  customerMessage: string;
  requestedDeliveryDate: Moment | null;
  technicalContact: string;
  updatesEmail: string | null;
  sendUpdateEmail: boolean;
  assignedUserId: string;
}

type InnerFormProps = {
  authState: AuthStateAuthed;
  organization: Organization;
} & FormikProps<Fields>;

const InnerForm = ({
  authState,
  organization,
  values,
  errors,
  touched,
  handleSubmit,
  isSubmitting,
  getFieldProps,
  setFieldValue,
  setFieldTouched,
}: InnerFormProps) => (
  <EuiForm component="form" onSubmit={handleSubmit}>
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiFormRow
          label="Orderreferens"
          helpText="Fyll i om ni vill ha en egen referens på ordern."
          isInvalid={!!touched.customerRef && !!errors.customerRef}
          error={errors.customerRef}
        >
          <EuiFieldText {...getFieldProps<string>("customerRef")} />
        </EuiFormRow>
        <EuiFormRow
          label="Önskat nedkopplingsdatum"
          helpText="Lämna tomt för snarast."
          isInvalid={
            !!touched.requestedDeliveryDate && !!errors.requestedDeliveryDate
          }
          error={errors.requestedDeliveryDate}
        >
          <EuiDatePicker
            selected={values.requestedDeliveryDate}
            onClear={() => setFieldValue("requestedDeliveryDate", null)}
            onChange={(v) => setFieldValue("requestedDeliveryDate", v)}
            onBlur={() => setFieldTouched("requestedDeliveryDate", true)}
            dateFormat="YYYY-MM-DD"
            minDate={moment()}
          />
        </EuiFormRow>
        <EuiFormRow
          label="Teknisk kontakt"
          helpText="Gärna namn, tel.nr, mejl."
          isInvalid={!!touched.technicalContact && !!errors.technicalContact}
          error={errors.technicalContact}
        >
          <EuiFieldText {...getFieldProps<string>("technicalContact")} />
        </EuiFormRow>
        <DefaultOrTextRow
          name={propertyOf<Fields>("updatesEmail")}
          label="Mejladress för statusuppdateringar"
          labelDefault={`Organisationens mejl: ${
            organization.order_emails.join(", ") || "saknas"
          }`}
          labelSpecify="Angiven:"
          inputMode="email"
        />
        {isAdmin(authState) && (
          <EuiFormRow
            isInvalid={!!touched.sendUpdateEmail && !!errors.sendUpdateEmail}
            error={errors.sendUpdateEmail}
          >
            <EuiSwitch
              label="Skicka orderbekräftelse"
              checked={values.sendUpdateEmail}
              onChange={(e) =>
                setFieldValue("sendUpdateEmail", e.target.checked)
              }
            />
          </EuiFormRow>
        )}
        <EuiFormRow
          label="Meddelande"
          helpText="Här kan ni lämna ytterligare information."
          isInvalid={!!touched.customerMessage && !!errors.customerMessage}
          error={errors.customerMessage}
        >
          <EuiTextArea {...getFieldProps<string>("customerMessage")} />
        </EuiFormRow>
        {isAdmin(authState) && (
          <SelectUserRow
            authState={authState}
            label="Ansvarig"
            name={propertyOf<Fields>("assignedUserId")}
            defaultUserId={authState.user.id}
          />
        )}
      </EuiFlexItem>
    </EuiFlexGroup>

    <EuiSpacer />

    <EuiButton type="submit" fill isLoading={isSubmitting}>
      Skicka
    </EuiButton>
  </EuiForm>
);

interface Props {
  onSubmit: (fields: Fields) => Promise<unknown>;
  orderAddService: OrderAddService;
  authState: AuthStateAuthed;
  organization: Organization;
  userPreferences: UserPreferences;
  updateUserPreferences(values: Partial<UserPreferences>): void;
}

export const OrderCreateEndForm = withFormik<Props, Fields>({
  displayName: "OrderCreateEndForm",
  mapPropsToValues: ({ authState, userPreferences }) => ({
    customerRef: "",
    customerMessage: "",
    requestedDeliveryDate: null,
    technicalContact: "",
    updatesEmail: null,
    sendUpdateEmail: isAdmin(authState)
      ? userPreferences.sendOrderUpdateEmail
      : true,
    assignedUserId: "",
  }),
  validate: (values) => {
    const errors: FormikErrors<Fields> = {};

    if (values.customerRef && values.customerRef.length > 64) {
      const charsOver = values.customerRef.length - 64;
      errors.customerRef = `${charsOver} tecken för långt`;
    }

    if (values.customerMessage) {
      const charsOver = values.customerMessage.length - 10240;
      if (charsOver > 0) {
        errors.customerMessage = `${charsOver} tecken för långt`;
      }
    }

    if (values.requestedDeliveryDate) {
      if (!values.requestedDeliveryDate.isValid()) {
        errors.requestedDeliveryDate = "Ogiltigt";
      } else if (values.requestedDeliveryDate.isBefore(moment(), "days")) {
        errors.requestedDeliveryDate = "Datum har redan passerat";
      }
    }

    if (values.technicalContact && values.technicalContact.length > 128) {
      const charsOver = values.technicalContact.length - 128;
      errors.technicalContact = `${charsOver} tecken för långt`;
    }

    if (values.updatesEmail !== null) {
      if (!values.updatesEmail) {
        errors.updatesEmail = "Saknas";
      } else if (values.updatesEmail.length > 128) {
        const charsOver = values.updatesEmail.length - 128;
        errors.updatesEmail = `${charsOver} tecken för långt`;
      } else if (!emailValidator.validate(values.updatesEmail)) {
        errors.updatesEmail = "Ogiltigt format";
      }
    }

    return errors;
  },
  handleSubmit: async (values, { props, setValues }) => {
    const normalized: typeof values = {
      customerRef: values.customerRef.trim(),
      customerMessage: values.customerMessage.trim(),
      requestedDeliveryDate: values.requestedDeliveryDate,
      technicalContact: values.technicalContact.trim(),
      updatesEmail: values.updatesEmail && values.updatesEmail.trim(),
      sendUpdateEmail: values.sendUpdateEmail,
      assignedUserId: values.assignedUserId,
    };

    setValues(normalized);
    props.updateUserPreferences({
      sendOrderUpdateEmail: normalized.sendUpdateEmail,
    });

    await props.onSubmit(normalized);
  },
})(InnerForm);

export default OrderCreateEndForm;
