import {
  EuiBreadcrumb,
  EuiCallOut,
  EuiDelayRender,
  EuiLoadingSpinner,
  EuiSpacer,
} from "@elastic/eui";
import React, { useCallback, useState } from "react";
import { Navigate } from "react-router-dom";
import { Order, useOrderUpdateService } from "../../../api/resources/order";
import { useOrganizationService } from "../../../api/resources/organization";
import { AuthStateAdmin } from "../../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../../api/useService";
import Page from "../../../Page";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import useBreadcrumb from "../../../utils/useBreadcrumb";
import AddMonitoredInterface from "../../../widgets/AddMonitoredInterface";
import { orderHref } from "../view/OrderPage";
import { Fields, OrderEditForm } from "./OrderEditForm";

interface Props {
  authState: AuthStateAdmin;
  order: Order;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

export function OrderEditPage({ authState, order, onBreadcrumbs }: Props) {
  const [orderUpdateService, submitUpdate] = useOrderUpdateService(
    authState,
    order.id
  );
  const [doMonGraphIface, setDoMonGraphIface] = useState(false);
  const organizationService = useOrganizationService(
    authState,
    order.organization_id
  );

  const onSubmit = useCallback(
    async (fields: Fields) => {
      if (fields.monitorAGraphInterface && fields.aGraphInterface) {
        setDoMonGraphIface(true);
      }

      await submitUpdate({
        ...fields,
        assignedUserId: order.assigned_user ? order.assigned_user.id : null,
        updatesEmail: fields.updatesEmail || "",
        serviceNumber: fields.serviceNumber || null,
        aGraphInterfaceId: fields.aGraphInterface && fields.aGraphInterface.id,
      });
    },
    [submitUpdate, order.assigned_user]
  );

  useBreadcrumb(onBreadcrumbs, "Ändra");

  return (
    <Page title="Ändra order">
      {isError(orderUpdateService) && (
        <>
          <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
            <ServiceErrorMessage error={orderUpdateService} />
          </EuiCallOut>
          <EuiSpacer />
        </>
      )}
      {isError(organizationService) && (
        <>
          <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
            <ServiceErrorMessage error={organizationService} />
          </EuiCallOut>
          <EuiSpacer />
        </>
      )}
      {isLoading(organizationService) && (
        <EuiDelayRender>
          <EuiLoadingSpinner size="l" />
        </EuiDelayRender>
      )}
      {isLoaded(orderUpdateService) ? (
        doMonGraphIface &&
        orderUpdateService.payload.service.a_graph_interface_id !== null ? (
          <AddMonitoredInterface
            authState={authState}
            serviceId={orderUpdateService.payload.service.id}
            interfaceId={
              orderUpdateService.payload.service.a_graph_interface_id
            }
            redirectTo={orderHref(orderUpdateService.payload.id)}
          />
        ) : (
          <Navigate to={orderHref(orderUpdateService.payload.id)} />
        )
      ) : (
        isLoaded(organizationService) && (
          <OrderEditForm
            order={order}
            authState={authState}
            organization={organizationService.payload}
            onSubmit={onSubmit}
          />
        )
      )}
    </Page>
  );
}

export default OrderEditPage;
