import { EuiBreadcrumb, EuiDelayRender, EuiLoadingSpinner } from "@elastic/eui";
import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { useOrganizationService } from "../../api/resources/organization";
import { AuthStateAdmin } from "../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../api/useService";
import Page from "../../Page";
import { ServiceErrorMessage } from "../../utils/ErrorMessage";
import useBreadcrumb from "../../utils/useBreadcrumb";
import NotFoundPage from "../NotFoundPage";
import OrganizationCreatePage from "./create/OrganizationCreatePage";
import OrganizationEditPage from "./edit/OrganizationEditPage";
import OrganizationsPage from "./list/OrganizationsPage";
import OrgNotFoundPage from "./OrganizationNotFoundPage";
import OrganizationPage from "./view/OrganizationPage";

interface Props {
  authState: AuthStateAdmin;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

export function OrganizationsRouter({ authState, onBreadcrumbs }: Props) {
  const setChildBreadcrumbs = useBreadcrumb(onBreadcrumbs, "Organisationer");

  return (
    <Routes>
      <Route
        path="ny"
        element={
          <OrganizationCreatePage
            authState={authState}
            onBreadcrumbs={setChildBreadcrumbs}
          />
        }
      />
      <Route index element={<OrganizationsPage authState={authState} />} />
      <Route
        path=":organizationId/*"
        element={
          <OrganizationRouter
            authState={authState}
            onBreadcrumbs={setChildBreadcrumbs}
          />
        }
      />
    </Routes>
  );
}

interface OrganizationRouterProps {
  authState: AuthStateAdmin;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

function OrganizationRouter({
  authState,
  onBreadcrumbs,
}: OrganizationRouterProps) {
  const params = useParams<"organizationId">();
  if (params.organizationId === undefined) {
    throw Error("Missing organizationId in path");
  }
  const orgId = parseInt(params.organizationId, 10);
  const orgService = useOrganizationService(authState, orgId);

  const setChildBreadcrumbs = useBreadcrumb(
    onBreadcrumbs,
    isLoaded(orgService) ? orgService.payload.name : undefined
  );

  if (isError(orgService)) {
    if (orgService.httpStatus === 404) {
      return <OrgNotFoundPage />;
    } else {
      return (
        <Page title="Organisationen gick inte att hämta">
          <ServiceErrorMessage error={orgService} />
        </Page>
      );
    }
  }

  if (isLoading(orgService)) {
    return (
      <EuiDelayRender>
        <Page title="Hämtar organisation...">
          <EuiLoadingSpinner />
        </Page>
      </EuiDelayRender>
    );
  }

  const organization = orgService.payload;

  return (
    <Routes>
      <Route
        path="ändra"
        element={
          <OrganizationEditPage
            authState={authState}
            organization={organization}
            onBreadcrumbs={setChildBreadcrumbs}
          />
        }
      />
      <Route
        index
        element={
          <OrganizationPage authState={authState} organization={organization} />
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default OrganizationsRouter;
