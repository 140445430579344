import { Group, User, useUsersService } from "../../../api/resources/user";
import { AuthStateAdmin } from "../../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../../api/useService";
import assertNever from "../../../utils/assertNever";
import { Table, TableColumn } from "../../../widgets/Table";
import UserNameCell from "../../users/UserNameCell";

interface Props {
  authState: AuthStateAdmin;
  organizationId: number;
}

const emptyUsers: User[] = [];

export function UsersTable({ authState, organizationId }: Props) {
  const usersService = useUsersService(authState, { organizationId });
  const users = isLoaded(usersService)
    ? usersService.payload.items
    : emptyUsers;

  return (
    <Table
      items={users}
      columns={COLUMNS}
      preferencesKey="users"
      itemToKeywords={userToKeywords}
      loading={isLoading(usersService)}
      error={
        isError(usersService)
          ? usersService.message +
            (usersService.track ? ` (track ${usersService.track})` : "")
          : undefined
      }
    />
  );
}

function userGroupToString(group: Group): string {
  switch (group) {
    case "FB-PORTAL-ADMIN":
      return "Administratör";
    case "FB-PORTAL-USER":
      return "Användare";
    default:
      assertNever(group);
  }
}

function userPrivilegeToString(priv: User["order_privileges"]): string {
  switch (priv) {
    case "none":
      return "Dolt";
    case "view":
      return "Endast visa";
    case "manage":
      return "Hantera";
  }
}

const COLUMNS: TableColumn<User>[] = [
  {
    type: "field",
    field: "full_name",
    name: "Namn",
    dataType: "string",
    render: (_, user) => <UserNameCell user={user} />,
  },
  {
    type: "field",
    field: "phone",
    name: "Telefon",
    dataType: "string",
  },
  {
    type: "field",
    field: "email",
    name: "Mejl",
    dataType: "string",
  },
  {
    type: "field",
    field: "service_privileges",
    name: "Förbindelseprivilegier",
    render: (_, user) => userPrivilegeToString(user.service_privileges),
    csvValueFn: (user) => userPrivilegeToString(user.service_privileges),
    dataType: "string",
  },
  {
    type: "field",
    field: "error_case_privileges",
    name: "Felärendeprivilegier",
    render: (_, user) => userPrivilegeToString(user.error_case_privileges),
    csvValueFn: (user) => userPrivilegeToString(user.error_case_privileges),
    dataType: "string",
  },
  {
    type: "field",
    field: "order_privileges",
    name: "Orderprivilegier",
    render: (_, user) => userPrivilegeToString(user.order_privileges),
    csvValueFn: (user) => userPrivilegeToString(user.order_privileges),
    dataType: "string",
  },
  {
    type: "field",
    field: "username",
    name: "Användarnamn",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "group",
    name: "Grupp",
    render: (_, user) => userGroupToString(user.group),
    csvValueFn: (user) => userGroupToString(user.group),
    dataType: "string",
  },
  {
    type: "field",
    field: "require_2fa",
    name: "Kräv 2FA",
    dataType: "boolean",
    render: (_, user) => (user.require_2fa ? "2FA krävs" : "2FA avstängt"),
  },
  {
    type: "field",
    field: "password_expired",
    name: "Lösenordsstatus",
    dataType: "boolean",
    render: (_, user) => (user.password_expired ? "Utgått" : "Giltigt"),
    hideByDefault: true,
  },
];

function userToKeywords(user: User): string[] {
  return [
    user.full_name.split(/\s+/),
    [user.phone],
    [user.email],
    [user.service_privileges],
    [user.error_case_privileges],
    [user.order_privileges],
    [user.username],
    [userGroupToString(user.group)],
  ].flat();
}

export default UsersTable;
