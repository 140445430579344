import { EuiBreadcrumb, EuiCallOut, EuiSpacer } from "@elastic/eui";
import React, { useCallback, useState } from "react";
import { Navigate } from "react-router-dom";
import {
  Service,
  useServiceUpdateService,
} from "../../../api/resources/service";
import { AuthStateAdmin } from "../../../api/useAuthService";
import { isError, isLoaded } from "../../../api/useService";
import Page from "../../../Page";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import useBreadcrumb from "../../../utils/useBreadcrumb";
import AddMonitoredInterface from "../../../widgets/AddMonitoredInterface";
import { serviceHref } from "../view/ServicePage";
import { ServiceEditFields, ServiceEditForm } from "./ServiceEditForm";

interface Props {
  authState: AuthStateAdmin;
  service: Service;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

export function ServiceEditPage({ authState, service, onBreadcrumbs }: Props) {
  const [updateService, submitUpdate] = useServiceUpdateService(
    authState,
    service.id
  );
  const [doMonGraphIface, setDoMonGraphIface] = useState(false);

  const onSubmit = useCallback(
    async (fields: ServiceEditFields) => {
      if (fields.monitorAGraphInterface && fields.aGraphInterface) {
        setDoMonGraphIface(true);
      }

      await submitUpdate({
        ...fields,
        aGraphInterfaceId: fields.aGraphInterface && fields.aGraphInterface.id,
      });
    },
    [submitUpdate]
  );

  useBreadcrumb(onBreadcrumbs, "Ändra");

  return (
    <Page title="Ändra förbindelse">
      {isError(updateService) && (
        <>
          <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
            <ServiceErrorMessage error={updateService} />
          </EuiCallOut>
          <EuiSpacer />
        </>
      )}
      {isLoaded(updateService) ? (
        doMonGraphIface && updateService.payload.a_graph_interface_id ? (
          <AddMonitoredInterface
            authState={authState}
            serviceId={updateService.payload.id}
            interfaceId={updateService.payload.a_graph_interface_id}
            redirectTo={serviceHref(service.id)}
          />
        ) : (
          <Navigate to={serviceHref(service.id)} />
        )
      ) : (
        <ServiceEditForm
          service={service}
          authState={authState}
          onSubmit={onSubmit}
        />
      )}
    </Page>
  );
}

export default ServiceEditPage;
