import { Toast as ToastOriginal } from "@elastic/eui/src/components/toast/global_toast_list";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";

// This includes a useToast and context variable that goes with it.
// Use the /widget/Toast.tsx to display toasts added with this.

// Omit id from API, so that users won't have to come up with new IDs
export type Toast = Pick<
  ToastOriginal,
  "id" | "title" | "text" | "color" | "iconType" | "onClose"
>;

interface ContextValue {
  toasts: ToastOriginal[];
  addToast(toast: Toast): void;
  removeToast(id: string): void;
  removeAllToasts(): void;
}

const ToastsContext = createContext<ContextValue | null>(null);
ToastsContext.displayName = "ToastsContext";

export const useToasts = () => {
  const context = useContext(ToastsContext);
  if (context === null) {
    throw Error("Can not use Toasts component outside a ToastsContext");
  }

  return context;
};

export function ToastsContextProvider({ children }: PropsWithChildren) {
  const [toasts, setToasts] = useState<ToastOriginal[]>([]);

  const addToast = useCallback((newToast: Toast) => {
    setToasts((prevToasts) => {
      const foundToast = prevToasts.find((t) => t.id === newToast.id);
      if (foundToast) {
        // Already exists, update it
        return prevToasts.map((prevToast) =>
          prevToast === foundToast ? newToast : prevToast
        );
      } else {
        // New, add it
        return prevToasts.concat(newToast);
      }
    });
  }, []);

  const removeToast = useCallback((id: string) => {
    setToasts((last) => last.filter((toast) => toast.id !== id));
  }, []);

  const removeAllToasts = useCallback(() => {
    setToasts([]);
  }, []);

  return (
    <ToastsContext.Provider
      value={{
        toasts,
        addToast,
        removeToast,
        removeAllToasts,
      }}
      children={children}
    />
  );
}

export default useToasts;
