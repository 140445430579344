import { EuiBreadcrumb, EuiCallOut } from "@elastic/eui";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOrganizationAddService } from "../../../api/resources/organization";
import { AuthStateAdmin } from "../../../api/useAuthService";
import { isError, isLoaded } from "../../../api/useService";
import Page from "../../../Page";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import useBreadcrumb from "../../../utils/useBreadcrumb";
import { organizationHref } from "../view/OrganizationPage";
import OrganizationCreateForm from "./OrganizationCreateForm";

interface Props {
  authState: AuthStateAdmin;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

export function OrganizationCreatePage({ authState, onBreadcrumbs }: Props) {
  const [createService, handleSubmit] = useOrganizationAddService(authState);
  const navigate = useNavigate();

  useBreadcrumb(onBreadcrumbs, "Ny");

  useEffect(() => {
    if (isLoaded(createService)) {
      navigate(organizationHref(createService.payload.id), { replace: true });
    }
  }, [createService, navigate]);

  return (
    <Page title="Ny organisation">
      {isError(createService) && (
        <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
          <ServiceErrorMessage error={createService} />
        </EuiCallOut>
      )}
      <OrganizationCreateForm authState={authState} onSubmit={handleSubmit} />
    </Page>
  );
}

export default OrganizationCreatePage;
