import { Moment } from "moment";
import { useCallback, useEffect } from "react";
import useCache from "../../utils/useCache";
import { AuthStateAuthed, AuthStateAdmin } from "../useAuthService";
import {
  buildApiHref,
  isLoaded,
  useAutoGetService,
  useService,
} from "../useService";
import {
  AlarmPriority,
  Service,
  serviceApiHref,
  servicesApiHref,
  ServiceStatus,
} from "./service";
import { statisticsApiHref } from "./statistics";

interface ByUser {
  id: number;
  full_name: string;
}

export type OrderStatus =
  | "sent"
  | "canceling-sent"
  | "needs-tender"
  | "tendered"
  | "delivering"
  | "canceling-delivery"
  | "delivered"
  | "delivery-not-possible"
  | "removed";

export type OrderUpdateStatusChange = "no-change" | OrderStatus;

export type OrderType = "new" | "change" | "end";

export interface OrderListItem {
  id: number;
  organization_id: number;
  organization_name: string;
  service: Service;
  by_user: ByUser;
  assigned_user?: ByUser | null; // Only included for admins
  number: string;
  type: OrderType;
  status: OrderStatus;
  internal_note?: string; // Only included when user group is admin
  customer_ref: string;
  customer_message: string;
  technical_contact: string;
  property_owner_contact: string;
  updates_email: string;
  order_date: string;
  requested_delivery_date: string | null;
  expected_delivery_date: string | null;
  actual_delivery_date: string | null;
}

interface ListResponse {
  items: OrderListItem[];
}

interface OrdersApiHrefSearch {
  organizationId?: number;
  serviceId?: number;
}

export const ordersApiHref = (search: OrdersApiHrefSearch = {}) => {
  const searchParams: { organization_id?: string; service_id?: string } = {};

  if (search.organizationId) {
    searchParams.organization_id = search.organizationId.toString();
  }
  if (search.serviceId) {
    searchParams.service_id = search.serviceId.toString();
  }

  return buildApiHref("orders", searchParams);
};

export const useOrdersService = (
  authState: AuthStateAuthed,
  search: OrdersApiHrefSearch = {}
) => useAutoGetService<ListResponse>(authState, ordersApiHref(search));

interface OrderUpdate {
  id: number;
  order_id: number;
  by_user: ByUser;
  status_change: OrderUpdateStatusChange;
  message: string;
  time: string;
}

export interface Order extends OrderListItem {
  updates: OrderUpdate[];
}

export const orderApiHref = (id: number) => buildApiHref(`orders/${id}`);

export const useOrderService = (authState: AuthStateAuthed, id: number) =>
  useAutoGetService<Order>(authState, orderApiHref(id));

interface OrderAddCommonParams {
  assigned_user_id: number | null; // Only allowed for admins
  customer_ref: string;
  customer_message: string;
  requested_delivery_date: string | null;
  technical_contact: string;
  updates_email: string;
  send_update_email: boolean;
}

export interface OrderAddNewParams extends OrderAddCommonParams {
  type: "new";
  organization_id: number;
  service_customer_ref: string;
  a_graph_interface_id: number | null;
  a_location_name: string;
  a_address: string;
  a_property_designation: string;
  a_to_b_measurement: string;
  b_location_name: string;
  b_address: string;
  b_property_designation: string;
  b_to_a_measurement: string;
  property_owner_contact: string;
}

export interface OrderAddChangeParams extends OrderAddCommonParams {
  type: "change";
  service_id: number;
}

export interface OrderAddEndParams extends OrderAddCommonParams {
  type: "end";
  service_id: number;
}

type OrderAddParams =
  | OrderAddNewParams
  | OrderAddChangeParams
  | OrderAddEndParams;

export const useOrderAddService = (authState: AuthStateAuthed) => {
  const { service, post: origPost } = useService<Order>(authState);

  const post = useCallback(
    (request: OrderAddParams) => origPost(ordersApiHref(), request),
    [origPost]
  );

  const { setCache, removeCache } = useCache();
  useEffect(() => {
    if (isLoaded(service)) {
      setCache(orderApiHref(service.payload.id), service);
      // Extra cache invalidation
      removeCache(statisticsApiHref(), true);
      removeCache(ordersApiHref(), true);
      removeCache(servicesApiHref(), true);
    }
  }, [service, setCache, removeCache]);

  return [service, post] as const;
};

export type OrderAddService = ReturnType<typeof useOrderAddService>[0];

export interface OrderUpdateParams {
  assignedUserId: number | null;
  status: OrderStatus;
  internalNote: string;
  customerRef: string;
  technicalContact: string;
  propertyOwnerContact: string;
  updatesEmail: string;
  requestedDeliveryDate: Moment | null;
  expectedDeliveryDate: Moment | null;
  actualDeliveryDate: Moment | null;
  productName: string;
  productCapacity: string;
  productTechnology: string;
  serviceNumber: string | null;
  serviceGenerateNumber: boolean;
  serviceStatus: ServiceStatus;
  serviceCustomerRef: string;
  serviceInternalNote: string;
  serviceAlarmPriority: AlarmPriority;
  aGraphInterfaceId: number | null;
  aLocationName: string;
  aAddress: string;
  aDemarcation: string;
  aPropertyDesignation: string;
  aToBMeasurement: string;
  bLocationName: string;
  bAddress: string;
  bDemarcation: string;
  bPropertyDesignation: string;
  bToAMeasurement: string;
  serviceDeliveryDate: Moment | null;
  serviceTerminationDate: Moment | null;
}

export const useOrderUpdateService = (
  authState: AuthStateAdmin,
  id: number
) => {
  const { service, patch: origPatch } = useService<Order>(authState);

  const patch = useCallback(
    (params: OrderUpdateParams) =>
      origPatch(orderApiHref(id), {
        assigned_user_id: params.assignedUserId,
        status: params.status,
        internal_note: params.internalNote,
        customer_ref: params.customerRef,
        requested_delivery_date:
          params.requestedDeliveryDate &&
          params.requestedDeliveryDate.format("YYYY-MM-DD"),
        expected_delivery_date:
          params.expectedDeliveryDate &&
          params.expectedDeliveryDate.format("YYYY-MM-DD"),
        actual_delivery_date:
          params.actualDeliveryDate &&
          params.actualDeliveryDate.format("YYYY-MM-DD"),
        technical_contact: params.technicalContact,
        property_owner_contact: params.propertyOwnerContact,
        updates_email: params.updatesEmail,
        product_name: params.productName,
        product_capacity: params.productCapacity,
        product_technology: params.productTechnology,
        service_number: params.serviceNumber,
        service_generate_number: params.serviceGenerateNumber,
        service_status: params.serviceStatus,
        service_customer_ref: params.serviceCustomerRef,
        service_internal_note: params.serviceInternalNote,
        service_alarm_priority: params.serviceAlarmPriority,
        a_graph_interface_id: params.aGraphInterfaceId,
        a_location_name: params.aLocationName,
        a_address: params.aAddress,
        a_demarcation: params.aDemarcation,
        a_property_designation: params.aPropertyDesignation,
        a_to_b_measurement: params.aToBMeasurement,
        b_location_name: params.bLocationName,
        b_address: params.bAddress,
        b_demarcation: params.bDemarcation,
        b_property_designation: params.bPropertyDesignation,
        b_to_a_measurement: params.bToAMeasurement,
        service_delivery_date:
          params.serviceDeliveryDate &&
          params.serviceDeliveryDate.format("YYYY-MM-DD"),
        service_termination_date:
          params.serviceTerminationDate &&
          params.serviceTerminationDate.format("YYYY-MM-DD"),
      }),
    [origPatch, id]
  );

  const { setCache, removeCache } = useCache();
  useEffect(() => {
    if (isLoaded(service)) {
      setCache(orderApiHref(service.payload.id), service);
      // Extra cache invalidation
      removeCache(statisticsApiHref(), true);
      removeCache(ordersApiHref(), true);
      removeCache(servicesApiHref(), true);
      removeCache(serviceApiHref(service.payload.service.id));
    }
  }, [service, setCache, removeCache]);

  return [service, patch] as const;
};

export interface OrderUpdateAddParams {
  message: string;
  sendUpdateEmail: boolean;
}

export const orderUpdatesHref = (orderId: number) =>
  buildApiHref(`orders/${orderId}/updates`);

export const useOrderUpdateAddService = (
  authState: AuthStateAuthed,
  organizationId: number,
  orderId: number
) => {
  const { service, post: origPost } = useService<void>(authState);

  const post = useCallback(
    (params: OrderUpdateAddParams) =>
      origPost(orderUpdatesHref(orderId), {
        message: params.message,
        send_update_email: params.sendUpdateEmail,
      }),
    [origPost, orderId]
  );

  const { removeCache } = useCache();
  useEffect(() => {
    if (isLoaded(service)) {
      // Extra cache invalidation
      removeCache(statisticsApiHref(), true);
      removeCache(ordersApiHref(), true);
      removeCache(orderApiHref(orderId));
    }
  }, [service, orderId, organizationId, removeCache]);

  return [service, post] as const;
};

type UseOrderUpdateAddReturn = ReturnType<typeof useOrderUpdateAddService>;
export type OrderUpdateAddService = UseOrderUpdateAddReturn[0];
export type OrderUpdateAddPost = UseOrderUpdateAddReturn[1];
