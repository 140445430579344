import {
  EuiErrorBoundary,
  EuiPageHeaderProps,
  EuiPageTemplate,
} from "@elastic/eui";
import React, { ReactNode, useEffect } from "react";

interface Props {
  title: string;
  children: ReactNode;
  actionItems?: EuiPageHeaderProps["rightSideItems"];
}

export function Page({ title, children, actionItems = [] }: Props) {
  useEffect(() => {
    document.title = `${title} - Portalen`;
  }, [title]);

  return (
    <EuiErrorBoundary>
      <EuiPageTemplate direction="column" restrictWidth={false}>
        <EuiPageTemplate.Header
          pageTitle={title}
          rightSideItems={actionItems}
        />
        <EuiPageTemplate.Section>{children}</EuiPageTemplate.Section>
      </EuiPageTemplate>
    </EuiErrorBoundary>
  );
}

export default Page;
