// Used to give compile time warnings for unchecked code paths
//
// Example usage
// switch (status) {
//    case 'ok':
//      return 'OK'
//    case 'error': break
//      return 'Error'
//    default:
//      return assertNever(status)  // This gives compile time warning if there are more cases
// }
export function assertNever(x: never): never {
  throw new Error("Expected never type, but got " + JSON.stringify(x));
}

export default assertNever;
