import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import React, { useState } from "react";
import { Organization } from "../../../api/resources/organization";
import { AuthStateAdmin } from "../../../api/useAuthService";
import Page from "../../../Page";
import RouterLinkAdapter from "../../../utils/RouterLinkAdapter";
import AddUserModal from "./AddUserModal";
import OrganizationDescription from "./OrganizationDescription";
import OrganizationUsersTable from "./UsersTable";

export const organizationHref = (organizationId: number) =>
  `/organisationer/${organizationId}`;

interface Props {
  authState: AuthStateAdmin;
  organization: Organization;
}

export function OrganizationPage({ authState, organization }: Props) {
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  return (
    <Page title={organization.name} actionItems={actionItems}>
      <OrganizationDescription
        authState={authState}
        organization={organization}
      />
      <EuiSpacer />
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText>
            <h2>Användare</h2>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiText>
            <EuiButton
              iconType="plus"
              onClick={() => setShowAddUserModal(true)}
            >
              Lägg till användare
            </EuiButton>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <OrganizationUsersTable
        authState={authState}
        organizationId={organization.id}
      />
      {showAddUserModal && (
        <AddUserModal
          authState={authState}
          organization={organization}
          onClose={() => setShowAddUserModal(false)}
        />
      )}
    </Page>
  );
}

const actionItems = [
  <RouterLinkAdapter to="ändra">
    {(linkProps) => (
      <EuiButton iconType="documentEdit" {...linkProps}>
        Ändra
      </EuiButton>
    )}
  </RouterLinkAdapter>,
];

export default OrganizationPage;
