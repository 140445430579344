import { Criteria, EuiTableSortingType, Pagination } from "@elastic/eui";
import lodashGet from "lodash/get";
import Papa from "papaparse";
import { useCallback, useMemo, useState } from "react";
import { TableColumn } from "../widgets/Table";
import assertNever from "./assertNever";

function buildSearchIndex<Item>(
  items: Item[],
  itemToKeywords: (item: Item) => string[]
): Map<string, Set<Item>> {
  const searchIndex = new Map<string, Set<Item>>();

  for (const item of items) {
    const keywords = itemToKeywords(item).map((k) => k.toLocaleLowerCase());

    for (const keyword of keywords) {
      // Add all keyword prefix lengths for this item
      for (let prefixLen = 0; prefixLen <= keyword.length; prefixLen += 1) {
        const prefix = keyword.substr(0, prefixLen);
        const results = searchIndex.get(prefix);
        if (results) {
          // Existing prefix, add this item to it
          results.add(item);
        } else {
          // New prefix
          searchIndex.set(prefix, new Set([item]));
        }
      }
    }
  }

  return searchIndex;
}

// Set intersection. Returns a new set with items that are in both sets.
function intersection<T>(set1: Set<T>, set2: Set<T>): Set<T> {
  const intersectionSet = new Set<T>();

  for (const value of set1) {
    if (set2.has(value)) {
      intersectionSet.add(value);
    }
  }

  return intersectionSet;
}

// Build array with (unordered) search result
function search<Item>(
  searchIndex: Map<string, Set<Item>>,
  tokens: string[]
): Item[] {
  return [
    ...tokens
      .map((token) => searchIndex.get(token) || new Set<Item>())
      .reduce(intersection),
  ];
}

export function useSearch<Item>(
  items: Item[],
  itemToKeywords: (item: Item) => string[]
) {
  const searchIndex = useMemo(
    () => buildSearchIndex(items, itemToKeywords),
    [items, itemToKeywords]
  );

  const [query, setQuery] = useState("");

  const foundItems = useMemo(
    () => search(searchIndex, query.toLocaleLowerCase().trim().split(/\s+/)),
    [searchIndex, query]
  );

  return [foundItems, query, setQuery] as const;
}

// Sort items according to predicate
export function sorted<Item>(
  items: Item[],
  pred: (a: Item, b: Item) => number,
  dir: "asc" | "desc"
) {
  const copy = Array.from(items);

  if (dir === "asc") {
    copy.sort(pred);
  } else {
    copy.sort((a, b) => pred(b, a));
  }

  return copy;
}

// Sort items according to predicate
export function useSort<Item>(
  items: Item[],
  initialField: string,
  fieldToPred: (field: string) => (a: Item, b: Item) => number
) {
  const [field, setField] = useState(initialField);
  const [direction, setDirection] = useState<"asc" | "desc">("asc");

  const pred = useMemo(() => fieldToPred(field), [fieldToPred, field]);
  const sortedItems = useMemo(
    () => sorted(items, pred, direction),
    [items, pred, direction]
  );

  const sorting: EuiTableSortingType<Item> = {
    sort: {
      field: field as keyof Item,
      direction,
    },
  };

  return [sortedItems, sorting, setField, setDirection] as const;
}

export function usePaginate<Item>(items: Item[]) {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const paginated = useMemo(() => {
    if (pageSize === 0) {
      return items;
    } else {
      return items.slice(pageSize * pageIndex, pageSize * (pageIndex + 1));
    }
  }, [items, pageSize, pageIndex]);

  const pagination = useMemo(
    (): Pagination => ({
      pageIndex,
      pageSize,
      totalItemCount: items.length,
      pageSizeOptions: [10, 25, 100, 0],
    }),
    [items.length, pageIndex, pageSize]
  );

  return {
    paginated,
    pagination,
    setPageIndex,
    setPageSize: setPageSize,
  } as const;
}

interface UseFilteredSortedAndPaginatedParams<Item> {
  items: Item[];
  filterFn(item: Item): boolean;
  itemToKeywords: (item: Item) => string[];
  initSortField: string;
  fieldToSortPredicate: (field: string) => (a: Item, b: Item) => number;
}

// All at once
export function useFilteredSortedAndPaginated<Item>({
  items,
  filterFn,
  itemToKeywords,
  initSortField,
  fieldToSortPredicate: fieldToPred,
}: UseFilteredSortedAndPaginatedParams<Item>) {
  const filtered = useMemo(() => items.filter(filterFn), [items, filterFn]);

  const [found, query, setQuery] = useSearch(filtered, itemToKeywords);
  const noMatches = items.length > 0 && found.length === 0;

  const [sorted, sorting, setSortField, setSortDir] = useSort(
    found,
    initSortField,
    fieldToPred
  );

  const { paginated, pagination, setPageSize, setPageIndex } =
    usePaginate(sorted);

  const onQueryChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setQuery(event.target.value),
    [setQuery]
  );

  const onTableChange = useCallback(
    ({ page, sort }: Criteria<Item>) => {
      if (page) {
        setPageSize(page.size);
        setPageIndex(page.index);
      }

      if (sort) {
        setSortField(sort.field.toString());
        setSortDir(sort.direction);
      }
    },
    [setPageSize, setPageIndex, setSortField, setSortDir]
  );

  return {
    items: paginated,
    query,
    pagination,
    sorting,
    noMatches,
    onQueryChange,
    onTableChange,
  };
}

export function exportToCsv<ItemType extends { [key: string]: any }>(
  items: ItemType[],
  columns: TableColumn<ItemType>[]
): string {
  // Collect column info needed to create fields and row data.
  // Only columns in includeColumns are used, and with preserved order.
  const columnInfo = columns.map((col) => {
    switch (col.type) {
      case "field":
        return {
          field: col.name,
          valueFn:
            col.csvValueFn ||
            ((item: ItemType) => {
              const value = lodashGet(item, col.field);
              return value === undefined ? `${col.field} undefined` : value;
            }),
        };
      case "computed":
        // Use csvValueFn if provided, else fall back to the render function
        return {
          field: col.name,
          valueFn: col.csvValueFn,
        };
      default:
        return assertNever(col);
    }
  });

  const fields = columnInfo.map((c) => c.field);

  // Translate each item into an array of values
  const data = items.map((item) =>
    // Pick values using column info and translate to suitable value using valueFn.
    columnInfo.map((c) => c.valueFn(item))
  );

  return Papa.unparse({ fields, data });
}
