import {
  CriteriaWithPagination,
  DefaultItemAction,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiDataGridCellValueElementProps,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiTableFieldDataColumnType,
  EuiTableSortingType,
} from "@elastic/eui";
import { get } from "lodash";
import { ReactNode, useCallback, useMemo } from "react";
import ColumnSelector from "../table/ColumnSelector";
import useConfiguredColumns from "../table/useConfiguredColumns";
import useTablePreferences from "../table/useTablePreferences";
import assertNever from "../utils/assertNever";
import {
  exportToCsv,
  sorted,
  usePaginate,
  useSearch,
} from "../utils/table-helpers";
import DownloadButton from "./DownloadButton";

interface Props<ItemType> {
  items: ItemType[];
  itemToKeywords(item: ItemType): string[];
  columns: TableColumn<ItemType>[];
  actions?: DefaultItemAction<ItemType>[];
  /** Key used to get table preferences from user preferences */
  preferencesKey: string;
  loading?: boolean;
  error?: string;
}

interface ColumnCommon {
  field: string;
  name: string;
  description?: string;
  sortable?: boolean;
  hideByDefault?: boolean;
}

interface ColumnField<Item> extends ColumnCommon {
  type: "field";
  dataType: EuiTableFieldDataColumnType<Item>["dataType"];
  render?: (value: any, item: Item) => ReactNode;
  csvValueFn?: (item: Item) => string | null;
}

interface ColumnComputed<Item> extends ColumnCommon {
  type: "computed";
  render: (item: Item) => ReactNode;
  csvValueFn: (item: Item) => string | null;
}

export type TableColumn<Item> = ColumnField<Item> | ColumnComputed<Item>;

export interface TableRenderCellValueProps<ItemType>
  extends EuiDataGridCellValueElementProps {
  item: ItemType;
}

const typesOrdering = [
  "undefined",
  "object", // Note that this inludes null
  "boolean",
  "number",
  "bigint",
  "symbol",
  "string",
  "function",
];

/** A sort predicate that sorts values of any type */
function sortPredAuto(a: unknown, b: unknown): number {
  if (typeof a !== typeof b) {
    // Different types is sorted by their types according to typesOrdering,
    // instead of by value.
    const aOrder = typesOrdering.findIndex((t) => t === typeof a);
    const bOrder = typesOrdering.findIndex((t) => t === typeof b);
    return aOrder - bOrder;
  }

  if (typeof a === "undefined") {
    return 0; // Both are undefined
  }
  if (typeof a === "string" && typeof b === "string") {
    return a.localeCompare(b);
  }
  if (typeof a === "number" && typeof b === "number") {
    return a === b ? 0 : a - b;
  }
  if (typeof a === "boolean" && typeof b === "boolean") {
    return a === b ? 0 : a ? 1 : -1;
  }

  console.warn(`sortPredAuto: Can't sort value type ${typeof a}`);
  return 0;
}

/** A sort predicate for values that probably is a boolean, null or undefined */
function sortPredBoolean(a: unknown, b: unknown): number {
  // Sort undefined first
  if (typeof a === "undefined") {
    return typeof b === "undefined" ? 0 : -1;
  } else if (typeof b === "undefined") {
    return 1;
  }

  // Then null
  if (a === null) {
    return b === null ? 0 : -1;
  } else if (b === null) {
    return 1;
  }

  // Have to be a boolean
  if (typeof a !== "boolean" || typeof b !== "boolean") {
    console.warn(
      `sortPredBoolean() called on types, a: ${typeof a} <=> b: ${typeof b}`
    );
    return 0;
  }

  // Then "false", and last "true"
  return a === b ? 0 : a ? 1 : -1;
}

/** A sort predicate for values that probably is a string, null or undefined */
function sortPredString(a: unknown, b: unknown): number {
  // Sort undefined first
  if (typeof a === "undefined") {
    return typeof b === "undefined" ? 0 : -1;
  } else if (typeof b === "undefined") {
    return 1;
  }

  // Then null
  if (a === null) {
    return b === null ? 0 : -1;
  } else if (b === null) {
    return 1;
  }

  // Have to be a string
  if (typeof a !== "string" || typeof b !== "string") {
    console.warn(
      `sortPredString(): Invalid type a: ${typeof a} <=> b: ${typeof b}`
    );
    return 0;
  }

  // And last by string compare
  return a.localeCompare(b);
}

/** A sort predicate for values that probably is a number, null or undefined */
function sortPredNumber(a: unknown, b: unknown): number {
  // Sort undefined first
  if (typeof a === "undefined") {
    return typeof b === "undefined" ? 0 : -1;
  } else if (typeof b === "undefined") {
    return 1;
  }

  // Then null
  if (a === null) {
    return b === null ? 0 : -1;
  } else if (b === null) {
    return 1;
  }

  // Have to be a number
  if (typeof a !== "number" || typeof b !== "number") {
    console.warn(
      `sortPredNumber(): Invalid type a: ${typeof a} <=> b: ${typeof b}`
    );
    return 0;
  }

  // And then from smallest number up
  return a - b;
}

/** Get a sort predicate that is good for this columns dataType */
function dataTypeToSortPred<Item>(dataType: ColumnField<Item>["dataType"]) {
  switch (dataType) {
    case undefined:
    case "auto":
      return sortPredAuto;
    case "boolean":
      return sortPredBoolean;
    case "date":
    case "string":
      return sortPredString;
    case "number":
      return sortPredNumber;
    default:
      return assertNever(dataType);
  }
}

/** Create a generic sort predicate for a column of "field" type
 *
 * Uses the dataType prop to determine sort function.
 * Also handles undefined and null values.
 */
function columnFieldSortPredFn<Item>(
  column: ColumnField<Item>
): (rowA: Item, rowB: Item) => number {
  const sortPredFn = dataTypeToSortPred(column.dataType);

  return (rowA, rowB) => {
    const a: unknown = get(rowA, column.field);
    const b: unknown = get(rowB, column.field);
    return sortPredFn(a, b);
  };
}

/**
 * Create a generic sort predicate for a column of "computed" type
 *
 * Computed columns always have a csvValueFn that returns a string, so take
 * advantage of that to make a simple string compare.
 * This will however sort numbers incorrectly (ex. "2" is more than "10000").
 */
function columnComputedSortPredFn<Item>(column: ColumnComputed<Item>) {
  return (rowA: Item, rowB: Item): number => {
    const a = column.csvValueFn(rowA);
    const b = column.csvValueFn(rowB);

    // Sort null first
    if (a === null) {
      return b === null ? 0 : -1;
    } else if (b === null) {
      return 1;
    }

    // Then string compare
    return a.localeCompare(b);
  };
}

/** Creates a generic sort predicate for a column based on its type */
function columnSortPredFn<Item>(column: TableColumn<Item>) {
  switch (column.type) {
    case "field":
      return columnFieldSortPredFn(column);
    case "computed":
      return columnComputedSortPredFn(column);
    default:
      return assertNever(column);
  }
}

export function Table<ItemType extends { [key: string]: any }>({
  items,
  itemToKeywords,
  columns,
  actions,
  preferencesKey,
  loading,
  error,
}: Props<ItemType>) {
  const { configuredColumns, setConfiguredColumns } = useConfiguredColumns(
    preferencesKey,
    columns
  );
  const [foundItems, query, setQuery] = useSearch(items, itemToKeywords);

  const visibleColumns = useMemo(
    (): TableColumn<ItemType>[] =>
      configuredColumns.filter((col) => col.isVisible),
    [configuredColumns]
  );

  const euiColumns = useMemo((): EuiBasicTableColumn<ItemType>[] => {
    // Convert columns to the format used by EuiInMemoryTable
    const euiColumns: EuiBasicTableColumn<ItemType>[] = [];

    for (const column of visibleColumns) {
      switch (column.type) {
        case "field":
          euiColumns.push({
            field: column.field,
            name: column.name,
            description: column.description,
            dataType: column.dataType,
            sortable: column.sortable === undefined ? true : column.sortable,
            render: column.render,
          });
          break;
        case "computed":
          euiColumns.push({
            name: column.name,
            description: column.description,
            // Sort by value from csvValueFn if it exists. Else sorting is
            // disabled, as there is no known value to sort on.
            sortable: column.csvValueFn,
            render: column.render,
          });
          break;
        default:
          assertNever(column);
      }
    }

    if (actions) {
      euiColumns.push({ name: "Hantera", actions });
    }

    return euiColumns;
  }, [visibleColumns, actions]);

  const [tablePreferences, setTablePreferences] =
    useTablePreferences(preferencesKey);

  // Find the sorted column
  const sortColumn = useMemo(() => {
    if (!tablePreferences.sort) {
      return undefined;
    }

    const { field } = tablePreferences.sort;
    return columns.find((col) => col.field === field);
  }, [columns, tablePreferences.sort]);

  const sortedItems = useMemo(() => {
    if (!sortColumn) {
      return foundItems; // No sorting
    }

    const direction = tablePreferences.sort?.direction || "asc";
    const sortPred = columnSortPredFn(sortColumn);

    return sorted(foundItems, sortPred, direction);
  }, [sortColumn, foundItems, tablePreferences.sort]);

  const generateCsvFile = useCallback(
    () => exportToCsv(foundItems, visibleColumns),
    [foundItems, visibleColumns]
  );

  const {
    paginated: paginatedItems,
    pagination,
    setPageIndex,
    setPageSize,
  } = usePaginate(sortedItems);

  // Convert sort preferences to what EUI uses. Specifically, EUI uses column
  // `field` for column type=field, and `name` for column type=computed.
  const euiSorting: EuiTableSortingType<ItemType> = useMemo(() => {
    if (!tablePreferences.sort || !sortColumn) {
      return {};
    }
    return {
      sort: {
        field: sortColumn.type === "field" ? sortColumn.field : sortColumn.name,
        direction: tablePreferences.sort.direction,
      },
    };
  }, [tablePreferences.sort, sortColumn]);

  const handleResetColumns = useCallback(() => {
    // Reset to default config by removing all configuration
    setConfiguredColumns([]);
  }, [setConfiguredColumns]);

  const handleTableChange = useCallback(
    (criteria: CriteriaWithPagination<ItemType>) => {
      setPageIndex(criteria.page.index);
      setPageSize(criteria.page.size);

      if (criteria.sort) {
        // Note: For computed columns, EUI uses `field` as a reference to
        // `name` instead of `field`. So we have to find the column first to
        // know what the actual field name is before saving to preferences.
        const fieldOrName = criteria.sort.field;
        const column = columns.find((col) => {
          if (col.type === "field") {
            return col.field === fieldOrName;
          } else if (col.type === "computed") {
            return col.name === fieldOrName;
          } else {
            return assertNever(col);
          }
        });
        if (!column) {
          // No such column, turn of sorting
          setTablePreferences({ sort: undefined });
          return;
        }

        setTablePreferences({
          sort: {
            field: column.field,
            direction: criteria.sort.direction,
          },
        });
      }
    },
    [setPageIndex, setPageSize, setTablePreferences, columns]
  );

  return (
    <>
      <EuiFlexGroup gutterSize="s">
        <EuiFlexItem>
          <EuiFieldSearch
            placeholder="Sök..."
            value={query}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setQuery(event.target.value)
            }
            isInvalid={!!query && items.length === 0}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup
            alignItems="center"
            justifyContent="center"
            gutterSize="s"
          >
            <EuiFlexItem>
              <div>
                <DownloadButton
                  generateFileFn={generateCsvFile}
                  filename={`${preferencesKey}-${new Date().toLocaleDateString()}.csv`}
                  size="s"
                  iconType="exportAction"
                  color="text"
                >
                  CSV export
                </DownloadButton>
              </div>
            </EuiFlexItem>
            <EuiFlexItem>
              <ColumnSelector<ItemType>
                columns={configuredColumns}
                onChange={setConfiguredColumns}
                onReset={handleResetColumns}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiBasicTable<ItemType>
        aria-label="Tabell"
        columns={euiColumns}
        hasActions={!!actions}
        pagination={pagination}
        sorting={euiSorting}
        loading={loading}
        items={paginatedItems}
        onChange={handleTableChange}
        error={error}
      />
    </>
  );
}

export default Table;
