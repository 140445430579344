import {
  EuiButton,
  EuiCallOut,
  EuiDelayRender,
  EuiLoadingSpinner,
  EuiSpacer,
} from "@elastic/eui";
import React, { ReactNode, useMemo, useRef } from "react";
import { Order, useOrderUpdateAddService } from "../../../api/resources/order";
import {
  Organization,
  useOrganizationService,
} from "../../../api/resources/organization";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../../api/useService";
import Page from "../../../Page";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import RouterLinkAdapter from "../../../utils/RouterLinkAdapter";
import useUserPreferences from "../../../utils/useUserPreferences";
import ServiceMonitoredInterfaces from "../../services/view/ServiceMonitoredInterfaces";
import OrderAddUpdateForm from "./OrderAddUpdateForm";
import OrderDescription from "./OrderDescription";
import OrderUpdates from "./OrderUpdates";

export const orderHref = (orderId: number) => `/order/${orderId}`;

interface Props {
  authState: AuthStateAuthed;
  order: Order;
}

export function OrderPage({ authState, order }: Props) {
  const updateFormRef = useRef<HTMLDivElement>(null);
  const organizationService = useOrganizationService(
    authState,
    order.organization_id
  );

  const actionItems = useMemo(() => {
    var items: ReactNode[] = [];

    if (authState.user.orderPrivileges === "manage") {
      items.push(
        <EuiButton onClick={() => updateFormRef.current?.scrollIntoView()}>
          Lägg till meddelande
        </EuiButton>
      );
    }

    if (isAdmin(authState)) {
      items.push(
        <RouterLinkAdapter to="ändra">
          {(linkProps) => (
            <EuiButton iconType="documentEdit" {...linkProps}>
              Ändra
            </EuiButton>
          )}
        </RouterLinkAdapter>
      );
    }

    return items;
  }, [authState]);

  return (
    <Page title="Order" actionItems={actionItems}>
      {isError(organizationService) && (
        <ServiceErrorMessage error={organizationService} />
      )}
      {isLoading(organizationService) && (
        <EuiDelayRender>
          <EuiLoadingSpinner />
        </EuiDelayRender>
      )}
      {isLoaded(organizationService) && (
        <OrderDescription
          authState={authState}
          order={order}
          organization={organizationService.payload}
        />
      )}
      {isAdmin(authState) && (
        <>
          <EuiSpacer />
          <ServiceMonitoredInterfaces
            authState={authState}
            service={{
              ...order.service,
              organization_id: order.organization_id,
            }}
          />
        </>
      )}
      <EuiSpacer />
      <OrderUpdates order={order} />
      <div ref={updateFormRef}>
        {authState.user.orderPrivileges === "manage" &&
          isLoaded(organizationService) && (
            <AddUpdateWidget
              authState={authState}
              order={order}
              organization={organizationService.payload}
            />
          )}
      </div>
    </Page>
  );
}

interface AddUpdateWidgetProps {
  authState: AuthStateAuthed;
  organization: Organization;
  order: Order;
}

function AddUpdateWidget({
  authState,
  organization,
  order,
}: AddUpdateWidgetProps) {
  const [addUpdateService, addUpdate] = useOrderUpdateAddService(
    authState,
    order.organization_id,
    order.id
  );
  const [userPreferences, updateUserPreferences] = useUserPreferences();

  return (
    <>
      {isError(addUpdateService) && (
        <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
          <ServiceErrorMessage error={addUpdateService} />
        </EuiCallOut>
      )}
      <OrderAddUpdateForm
        authState={authState}
        organization={organization}
        order={order}
        onSubmit={addUpdate}
        orderUpdateAddService={addUpdateService}
        userPreferences={userPreferences}
        updateUserPreferences={updateUserPreferences}
      />
    </>
  );
}

export default OrderPage;
