import {
  Comparators,
  Criteria,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiCallOut,
  EuiConfirmModal,
  EuiText,
} from "@elastic/eui";
import React, { useCallback, useState } from "react";
import {
  TrustedDevice,
  useTrustedDeviceDeleteService,
  useTrustedDevicesService,
} from "../../../api/resources/trusted_device";
import { AuthStateAuthed } from "../../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../../api/useService";
import { parseDateAndFormatShort } from "../../../utils/date";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import TrustedDeviceEditModal from "./TrustedDeviceEditModal";

interface Props {
  authState: AuthStateAuthed;
}

export function TrustedDevices({ authState }: Props) {
  const listService = useTrustedDevicesService(authState);
  const items = isLoaded(listService) ? listService.payload.items : [];

  const [isAboutToDelete, setIsAboutToDelete] = useState<TrustedDevice | null>(
    null
  );
  const [deleteService, deleteInterface] =
    useTrustedDeviceDeleteService(authState);

  const [isEditing, setIsEditing] = useState<TrustedDevice | null>(null);
  const [sortField, setSortField] = useState<keyof TrustedDevice>("last_use");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const onConfirmDelete = useCallback(
    (item: TrustedDevice) => {
      deleteInterface(item.id);
      setIsAboutToDelete(null);
    },
    [deleteInterface]
  );

  const columns: EuiBasicTableColumn<TrustedDevice>[] = [
    {
      field: "name",
      name: "Enhetsnamn",
      sortable: true,
    },
    {
      field: "last_use",
      name: "Senaste användning",
      dataType: "date",
      sortable: true,
      render: (_, trustedDevice) =>
        parseDateAndFormatShort(trustedDevice.last_use),
    },
    {
      field: "user_agent",
      name: "User agent",
      sortable: true,
    },
    {
      actions: [
        {
          name: "Ändra",
          description: "Ändra",
          icon: "documentEdit",
          type: "icon",
          onClick: setIsEditing,
          isPrimary: true,
          "data-test-subj": "action-edit",
        },
        {
          name: "Ta bort",
          description: "Ta bort",
          icon: "trash",
          color: "danger",
          type: "icon",
          onClick: setIsAboutToDelete,
          isPrimary: true,
          "data-test-subj": "action-delete",
        },
      ],
    },
  ];

  const isAnyLoading = isLoading(listService) || isLoading(deleteService);

  const sortedItems = items
    .slice(0)
    .sort(Comparators.property(sortField, Comparators.default(sortDirection)));

  const onTableChange = ({ sort }: Criteria<TrustedDevice>) => {
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
  };

  return (
    <>
      <EuiText>
        <h2>Betrodda enheter</h2>
        <p>
          SMS-kod (tvåfaktorsautentisering) krävs inte för betrodda enheter. Det
          är bra för säkerheten att ta bort enheter som inte längre används.
        </p>
      </EuiText>
      {isError(deleteService) && (
        <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
          <ServiceErrorMessage error={deleteService} />
        </EuiCallOut>
      )}
      <EuiBasicTable<TrustedDevice>
        items={sortedItems}
        rowHeader="id"
        columns={columns}
        loading={isAnyLoading}
        error={isError(listService) ? listService.message : ""}
        noItemsMessage="Saknas"
        tableLayout="auto"
        onChange={onTableChange}
        sorting={{
          sort: { field: sortField, direction: sortDirection },
        }}
      />
      {isAboutToDelete && (
        <EuiConfirmModal
          title="Ta bort betrodd enhet?"
          onCancel={() => setIsAboutToDelete(null)}
          onConfirm={() => onConfirmDelete(isAboutToDelete)}
          cancelButtonText="Avbryt"
          confirmButtonText="Ta bort"
          buttonColor="danger"
          defaultFocusedButton="confirm"
        />
      )}
      {isEditing && (
        <TrustedDeviceEditModal
          authState={authState}
          trustedDevice={isEditing}
          onClose={() => setIsEditing(null)}
        />
      )}
    </>
  );
}

export default TrustedDevices;
