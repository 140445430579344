import { useCallback, useEffect, useRef, useState } from "react";
import useCache, { useCacheItem } from "../utils/useCache";
import fetchFromApi, {
  Service,
  ServiceError,
  serviceInit,
  ServiceLoaded,
  ServiceLoading,
  serviceLoading,
  ServiceResponse,
} from "./fetchFromApi";
import { AuthStateBase } from "./useAuthService";

export const apiUrl =
  process.env.REACT_APP_API_URL || "REACT_APP_API_URL unset";

export function buildApiHref(
  resource: string,
  searchParams: Record<string, string> = {}
): string {
  const url = new URL(resource, apiUrl);
  const search = new URLSearchParams(url.searchParams);

  // Append all extra search parameters
  for (const [name, value] of Object.entries(searchParams)) {
    search.append(name, value);
  }

  search.sort(); // Use sorted params so that it is stable when caching
  url.search = search.toString();

  return url.toString();
}

export const useService = <ResponseBody>(authState: AuthStateBase) => {
  const [service, setService] = useState<Service<ResponseBody>>(serviceInit);
  const { getCache, setCache, removeCache } = useCache<ResponseBody>();

  // Store token in ref so it won't cause rerenders of functions
  const tokenRef = useRef(authState.token);

  useEffect(() => {
    tokenRef.current = authState.token;
  }, [authState.token]);

  const fetch = useCallback(
    async <RequestBody>(
      href: string,
      method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
      body?: RequestBody
    ) => {
      setService(serviceLoading);

      const response = await fetchFromApi<ResponseBody>({
        href,
        token: tokenRef.current,
        method,
        body,
      });

      setService(response);

      return response;
    },
    []
  );

  const get = useCallback(
    async (
      href: string,
      options: { useCache: boolean } = { useCache: true }
    ) => {
      if (options.useCache) {
        const cachedResponse = getCache(href);
        if (cachedResponse) {
          setService(cachedResponse);
          return cachedResponse;
        }
      }

      const response = await fetch(href, "GET");

      if (isLoaded(response)) {
        setCache(href, response);
      }

      return response;
    },
    [fetch, getCache, setCache]
  );

  const post = useCallback(
    async <RequestBody>(href: string, body: RequestBody) => {
      const response = await fetch(href, "POST", body);

      if (response.status === "loaded") {
        // POST invalidates resource cache
        removeCache(href, true);
      }

      return response;
    },
    [fetch, removeCache]
  );

  const put = useCallback(
    async <RequestBody>(href: string, body: RequestBody) => {
      const response = await fetch(href, "PUT", body);

      if (isLoaded(response)) {
        // PUT invalidates resource cache
        removeCache(href, true);
      }

      return response;
    },
    [fetch, removeCache]
  );

  const patch = useCallback(
    async <RequestBody>(href: string, body: RequestBody) => {
      const response = await fetch(href, "PATCH", body);

      if (isLoaded(response)) {
        // PATCH invalidates resource cache
        removeCache(href);
      }
    },
    [fetch, removeCache]
  );

  const remove = useCallback(
    async (href: string) => {
      const response = await fetch(href, "DELETE");

      if (response.status === "loaded") {
        removeCache(href);
      }

      return response;
    },
    [fetch, removeCache]
  );

  // Resets to init state
  const clear = useCallback(() => setService(serviceInit), []);

  return {
    service,
    get,
    post,
    put,
    patch,
    remove,
    set: setService,
    clear,
  } as const;
};

// Auto fetches the resource, and auto refetches if
// resource or params changes
export const useAutoGetService = <ResponseBody>(
  authState: AuthStateBase,
  href: string
) => {
  const cachedService = useCacheItem<ResponseBody>(href);
  const { service, get } = useService<ResponseBody>(authState);

  // (Re)fetch if not cached, or href changes
  useEffect(() => {
    if (!cachedService) {
      get(href, { useCache: false });
    }
  }, [get, href, cachedService]);

  const [lastCache, setLastCache] = useState<
    ServiceResponse<ResponseBody> | undefined
  >(cachedService);
  const [lastHref, setLastHref] = useState(cachedService && href);

  // Save the last valid cache so it can be used if the real cache expired
  useEffect(() => {
    if (cachedService && cachedService !== lastCache) {
      setLastCache(cachedService);
      setLastHref(href);
    }
  }, [cachedService, lastCache, href]);

  // Always return errors, even if cached without error
  if (isError(service)) {
    return service;
  }

  // Return serviceLoading while waiting for new href to be cached
  if (lastHref !== href) {
    return serviceLoading;
  }

  return lastCache || serviceLoading;
};

/** Assertion function to narrow down `Service` to `ServiceLoading` */
export function isLoading<T>(s: Service<T>): s is ServiceLoading {
  return s.status === "loading";
}

/** Assertion function to narrow down `Service` to `ServiceLoaded` */
export function isLoaded<T>(s: Service<T>): s is ServiceLoaded<T> {
  return s.status === "loaded";
}

/** Assertion function to narrow down `Service` to `ServiceError` */
export function isError<T>(s: Service<T>): s is ServiceError {
  return s.status === "error";
}
