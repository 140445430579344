import {
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiRadio,
  htmlIdGenerator,
} from "@elastic/eui";
import { useField } from "formik";
import React, { useState } from "react";

const generateId = htmlIdGenerator("default-or-text-row");

interface Props {
  /** Formik field name */
  name: string;
  /** Form row label */
  label: string;
  /** Form row help text */
  helpText?: string;
  /** Label on radio input for default */
  labelDefault: string;
  /** Label on radio input for specify */
  labelSpecify: string;
  /** Input mode on text input */
  inputMode: React.HTMLAttributes<HTMLInputElement>["inputMode"];
}

/** A EuiFormRow where the user can select to use the default value or
 * entering a string. Uses a field with the provided name from Formik context.
 *
 * Default value is represented as `null`. The user of this component have to
 * determine what value to use in place of `null`.  */
export const DefaultOrTextRow = ({
  name,
  label,
  helpText,
  labelDefault,
  labelSpecify,
  inputMode = "text",
}: Props) => {
  const [field, meta, helper] = useField<string | null>(name);

  // Remember text value, so that it is not erased when switching to "default"
  const [textValue, setTextValue] = useState(field.value || "");

  return (
    <EuiFormRow
      label={label}
      helpText={helpText}
      isInvalid={!!meta.error}
      error={meta.error}
    >
      <>
        <EuiRadio
          id={generateId("default")}
          label={labelDefault}
          checked={field.value === null}
          onChange={() => helper.setValue(null)}
        />
        <EuiFlexGroup gutterSize="none" alignItems="baseline">
          <EuiFlexItem grow={false}>
            <EuiRadio
              id={generateId("specify")}
              label={labelSpecify}
              checked={field.value !== null}
              onChange={() => helper.setValue(textValue, meta.touched)}
            />
          </EuiFlexItem>
          <EuiFlexItem style={{ paddingLeft: 24 }}>
            <EuiFieldText
              value={textValue}
              inputMode={inputMode}
              disabled={field.value === null}
              onChange={(e) => {
                setTextValue(e.target.value);
                helper.setValue(e.target.value);
              }}
              onBlur={() => helper.setTouched(true)}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    </EuiFormRow>
  );
};

export default DefaultOrTextRow;
