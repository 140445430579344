import {
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiSwitch,
} from "@elastic/eui";
import * as EmailValidator from "email-validator";
import { FormikErrors, FormikProps, withFormik } from "formik";
import React from "react";
import { Organization } from "../../../api/resources/organization";
import { User, UserAddPost } from "../../../api/resources/user";
import { isError } from "../../../api/useService";
import SelectRow from "../../../utils/form/SelectRow";
import propertyOf from "../../../utils/propertyOf";

interface Fields {
  organizationId: number;
  username: string;
  fullName: string;
  phone: string;
  email: string;
  require2fa: boolean;
  servicePrivileges: User["service_privileges"];
  errorCasePrivileges: User["error_case_privileges"];
  orderPrivileges: User["order_privileges"];
}

interface InnerFormProps extends FormikProps<Fields> {
  formId: string;
}

const InnerForm = ({
  formId,
  errors,
  touched,
  values,
  handleSubmit,
  getFieldProps,
  setFieldValue,
}: InnerFormProps) => (
  <EuiForm id={formId} component="form" onSubmit={handleSubmit}>
    <EuiFormRow
      label="För- och efternamn"
      isInvalid={!!touched.fullName && !!errors.fullName}
      error={errors.fullName}
    >
      <EuiFieldText
        {...getFieldProps<string>(propertyOf<Fields>("fullName"))}
      />
    </EuiFormRow>
    <EuiFormRow
      label="Telefon"
      isInvalid={!!touched.phone && !!errors.phone}
      error={errors.phone}
    >
      <EuiFieldText
        inputMode="tel"
        {...getFieldProps<string>(propertyOf<Fields>("phone"))}
      />
    </EuiFormRow>
    <EuiFormRow
      label="Mejladress"
      isInvalid={!!touched.email && !!errors.email}
      error={errors.email}
    >
      <EuiFieldText
        inputMode="email"
        {...getFieldProps<string>(propertyOf<Fields>("email"))}
      />
    </EuiFormRow>
    <EuiFormRow
      label="Användarnamn"
      isInvalid={!!touched.username && !!errors.username}
      error={errors.username}
    >
      <EuiFieldText
        {...getFieldProps<string>(propertyOf<Fields>("username"))}
      />
    </EuiFormRow>
    <EuiFormRow
      label="Autenticering"
      isInvalid={!!touched.require2fa && !!errors.require2fa}
      error={errors.require2fa}
    >
      <EuiSwitch
        label="Kräv 2FA"
        checked={values.require2fa}
        onChange={(e) => setFieldValue("require2fa", e.target.checked)}
      />
    </EuiFormRow>
    <SelectRow
      label="Behörighet för förbindelser"
      name={propertyOf<Fields>("servicePrivileges")}
      options={[
        { text: "Dölj", value: "none", disabled: true },
        { text: "Visa", value: "view" },
        { text: "Hantera", value: "manage" },
      ]}
    />
    <SelectRow
      label="Behörighet för order"
      name={propertyOf<Fields>("orderPrivileges")}
      options={[
        { text: "Dölj", value: "none" },
        { text: "Visa", value: "view" },
        { text: "Hantera", value: "manage" },
      ]}
    />
    <SelectRow
      label="Behörighet för felärenden"
      name={propertyOf<Fields>("errorCasePrivileges")}
      options={[
        { text: "Dölj", value: "none" },
        { text: "Visa", value: "view" },
        { text: "Hantera", value: "manage" },
      ]}
    />
    <EuiSpacer size="xxl" />
  </EuiForm>
);

interface Props {
  /** Use form element id from parent, so that parent can submit  */
  formId: string;
  organization: Organization;
  onSubmit: UserAddPost;
}

export const AddUserForm = withFormik<Props, Fields>({
  displayName: "EditForm",
  mapPropsToValues: ({ organization }) =>
    process.env.NODE_ENV === "development"
      ? {
          organizationId: organization.id,
          username: "test-" + Math.random(),
          fullName: "Test Name " + Math.random(),
          group: "FB-PORTAL-ADMIN",
          phone: "0701234567",
          email: "mattias.wallin@lidendata.com",
          require2fa: false,
          servicePrivileges: "manage",
          errorCasePrivileges: "manage",
          orderPrivileges: "manage",
        }
      : {
          organizationId: organization.id,
          username: "",
          fullName: "",
          group: "FB-PORTAL-USER",
          phone: "",
          email: "",
          require2fa: true,
          servicePrivileges: "manage",
          errorCasePrivileges: "manage",
          orderPrivileges: "manage",
        },
  validate: (values) => {
    const errors: FormikErrors<Fields> = {};

    if (!values.username) {
      errors.username = "Saknas";
    } else if (!/^[@.a-z0-9_-]+$/.test(values.username)) {
      errors.username = "Använd endast a-z, 0-9, @, -, . och _";
    } else {
      const charsOver = values.username.length - 128;
      if (charsOver > 0) {
        errors.username = `${charsOver} tecken för långt`;
      }
    }

    if (!values.fullName) {
      errors.fullName = "Saknas";
    } else {
      const charsOver = values.fullName.length - 128;
      if (charsOver > 0) {
        errors.fullName = `${charsOver} tecken för långt`;
      }
    }

    if (!values.phone) {
      errors.phone = "Saknas";
    } else if (!/^[+0-9]+$/.test(values.phone)) {
      errors.phone = "Ogiltiga tecken (använd endast 0-9 och +)";
    } else {
      const charsOver = values.phone.length - 12;
      if (charsOver > 0) {
        errors.phone = `${charsOver} tecken för långt`;
      }
    }

    if (!values.email) {
      errors.email = "Saknas";
    } else if (!EmailValidator.validate(values.email)) {
      errors.email = "Ogiltigt format";
    } else {
      const charsOver = values.email.length - 128;
      if (charsOver > 0) {
        errors.email = `${charsOver} tecken för långt`;
      }
    }

    return errors;
  },
  handleSubmit: async (values, { props, setValues, setFieldError }) => {
    const normalized: typeof values = {
      organizationId: values.organizationId,
      username: values.username,
      fullName: values.fullName.trim(),
      phone: values.phone,
      email: values.email.trim(),
      require2fa: values.require2fa,
      servicePrivileges: values.servicePrivileges,
      errorCasePrivileges: values.errorCasePrivileges,
      orderPrivileges: values.orderPrivileges,
    };

    setValues(normalized);

    const response = await props.onSubmit(normalized);

    if (isError(response) && response.message === "username-taken") {
      setFieldError(
        propertyOf<Fields>("username"),
        "Användarnamnet är redan upptaget"
      );
    }
  },
})(InnerForm);

export default AddUserForm;
