import { EuiButtonEmpty } from "@elastic/eui";
import { EuiButtonEmptyProps } from "@elastic/eui/src/components/button/button_empty";
import React, { useCallback, useState } from "react";

interface Props {
  generateFileFn: () => string;
  filename: string;
}

/** A button that when clicked makes the browser download the result
 * from calling generateFileFn().
 * Extra props are passed to EuiButtonEmpty. */
export const DownloadButton: React.FunctionComponent<
  Props & EuiButtonEmptyProps
> = ({ generateFileFn, filename, ...restProps }) => {
  const [linkRef, setLinkRef] = useState<HTMLAnchorElement | null>(null);

  const onClick = useCallback(() => {
    if (!linkRef) {
      return;
    }

    const blob = new Blob([generateFileFn()], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    linkRef.href = url;
    linkRef.click();
    URL.revokeObjectURL(url); // Warning: Can this remove the object too early?
  }, [generateFileFn, linkRef]);

  return (
    <>
      <EuiButtonEmpty
        isDisabled={!linkRef}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
        {...restProps}
      />
      {/* Hidden link that gets clicked by the real link. This is needed for
      Chrome to use the filename. */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
      <a ref={setLinkRef} download={filename} style={{ display: "none" }} />
    </>
  );
};

export default DownloadButton;
