import * as graph from "../graph";
import { AuthStateAdmin } from "../useAuthService";
import { buildApiHref, useAutoGetService } from "../useService";

export interface NetmonCoreInterface {
  id: number;
  descr: string;
  alias: string;
  snmp_index: number;
  node_name: string;
  device_id: number;
  device_name: string;
  device_ip4_str: string;
}

interface ListResponse {
  items: NetmonCoreInterface[];
}

export const netmonCoreInterfacesApiHref = (search?: { query: string }) =>
  buildApiHref("netmon-core-interfaces", search && { query: search.query });

export const useNetmonCoreInterfacesService = (
  authState: AuthStateAdmin,
  query: string
) =>
  useAutoGetService<ListResponse>(
    authState,
    netmonCoreInterfacesApiHref({ query })
  );

const graphHref = (interfaceId: number, range: graph.Range) =>
  buildApiHref(`netmon-core-interfaces/${interfaceId}/graph/${range}`);

export const useNetmonCoreInterfaceGraphService = (
  authState: AuthStateAdmin,
  interfaceId: number,
  range: graph.Range
) => useAutoGetService<graph.Data>(authState, graphHref(interfaceId, range));
