import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@elastic/eui";
import { useEffect, useMemo, useState } from "react";
import { User, useUsersService } from "../api/resources/user";
import { AuthStateAdmin } from "../api/useAuthService";
import { isError, isLoaded, isLoading } from "../api/useService";
import { ServiceErrorMessage } from "../utils/ErrorMessage";

interface Props {
  authState: AuthStateAdmin;
  initialUserId: number | null;
  onClose(): void;
  onSelected(userId: number | null): void;
}

type Option = EuiComboBoxOptionOption<number | null>;
const emptyUsers: User[] = [];

export function UserSelectModal({
  authState,
  initialUserId,
  onClose,
  onSelected,
}: Props) {
  const usersService = useUsersService(authState, {
    group: "FB-PORTAL-ADMIN",
  });
  const users = isLoaded(usersService)
    ? usersService.payload.items
    : emptyUsers;

  const options = useMemo(() => {
    const options = users.map(
      (user): Option => ({
        key: user.id.toString(),
        label: user.full_name,
        value: user.id,
      })
    );
    options.push({ key: "none", label: "Ingen ansvarig", value: null });

    return options;
  }, [users]);

  const [selected, setSelected] = useState<Option[]>([]);

  // When options are loaded, select the one matching initial user id
  const [initialUsed, setInitialUsed] = useState(false);
  useEffect(() => {
    if (!initialUsed) {
      const initOption = options.find((opt) => opt.value === initialUserId);
      if (initOption) {
        setSelected([initOption]);
        setInitialUsed(true);
      }
    }
  }, [options, initialUsed, initialUserId]);

  function handleSaveClick() {
    const userId = selected[0]?.value;
    if (userId !== undefined) {
      onSelected(userId);
    }
  }

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Tilldela ärende</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        {isError(usersService) && (
          <EuiCallOut
            title="Kunde inte hämta användare"
            color="danger"
            iconType="alert"
          >
            <ServiceErrorMessage error={usersService} />
          </EuiCallOut>
        )}
        <EuiComboBox
          options={options}
          isLoading={isLoading(usersService)}
          isClearable={false}
          singleSelection={{ asPlainText: true }}
          selectedOptions={selected}
          onChange={setSelected}
        />
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Avbryt</EuiButtonEmpty>
        <EuiButton onClick={handleSaveClick} fill>
          Tilldela
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

export default UserSelectModal;
