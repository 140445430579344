import React from "react";
import { EuiBreadcrumb, EuiDelayRender, EuiLoadingSpinner } from "@elastic/eui";
import { Route, Routes, useParams } from "react-router-dom";
import { useOrderService } from "../../api/resources/order";
import { AuthStateAuthed } from "../../api/useAuthService";
import Page from "../../Page";
import { ServiceErrorMessage } from "../../utils/ErrorMessage";
import useBreadcrumb from "../../utils/useBreadcrumb";
import NotFoundPage from "../NotFoundPage";
import OrderCreateNewPage from "./create-new/OrderCreateNewPage";
import OrderEditPage from "./edit/OrderEditPage";
import OrdersPage from "./list/OrdersPage";
import OrderNotFoundPage from "./OrderNotFoundPage";
import OrderPage from "./view/OrderPage";
import AdminOrNotFoundPage from "../AdminOrNotFoundPage";
import { isError, isLoaded, isLoading } from "../../api/useService";

interface OrdersRouterProps {
  authState: AuthStateAuthed;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

export function OrdersRouter({ authState, onBreadcrumbs }: OrdersRouterProps) {
  const setChildBreadcrumbs = useBreadcrumb(onBreadcrumbs, "Order");

  return (
    <Routes>
      <Route index element={<OrdersPage authState={authState} />} />
      <Route
        path="ny"
        element={
          <OrderCreateNewPage
            authState={authState}
            onBreadcrumbs={setChildBreadcrumbs}
          />
        }
      />
      <Route
        path=":orderId/*"
        element={
          <OrderRouter
            authState={authState}
            onBreadcrumbs={setChildBreadcrumbs}
          />
        }
      />
    </Routes>
  );
}

interface OrderRouterProps {
  authState: AuthStateAuthed;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

export function OrderRouter({ authState, onBreadcrumbs }: OrderRouterProps) {
  const params = useParams<"orderId">();
  if (params.orderId === undefined) {
    throw Error("Missing orderId in path");
  }
  const orderId = parseInt(params.orderId, 10);
  const orderService = useOrderService(authState, orderId);

  const setChildBreadcrumbs = useBreadcrumb(
    onBreadcrumbs,
    isLoaded(orderService) ? orderService.payload.number : undefined
  );

  if (isError(orderService)) {
    if (orderService.httpStatus === 404) {
      return <OrderNotFoundPage />;
    } else {
      return (
        <Page title="Order gick inte att hämta">
          <ServiceErrorMessage error={orderService} />
        </Page>
      );
    }
  }

  if (isLoading(orderService)) {
    return (
      <EuiDelayRender>
        <Page title="Hämtar order...">
          <EuiLoadingSpinner />
        </Page>
      </EuiDelayRender>
    );
  }

  const order = orderService.payload;

  return (
    <Routes>
      <Route
        path="ändra"
        element={
          <AdminOrNotFoundPage authState={authState}>
            {(authState) => (
              <OrderEditPage
                authState={authState}
                order={order}
                onBreadcrumbs={setChildBreadcrumbs}
              />
            )}
          </AdminOrNotFoundPage>
        }
      />
      <Route
        index
        element={<OrderPage authState={authState} order={order} />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default OrdersRouter;
