import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiListGroup,
  EuiSpacer,
  EuiTitle,
} from "@elastic/eui";
import { EuiListGroupItemProps } from "@elastic/eui/src/components/list_group/list_group_item";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  NetmonCoreInterface,
  useNetmonCoreInterfacesService,
} from "../api/resources/netmon_core_interface";
import { AuthStateAdmin } from "../api/useAuthService";
import { isError, isLoading } from "../api/useService";
import assertNever from "../utils/assertNever";
import { ServiceErrorMessage } from "../utils/ErrorMessage";
import { netmonCoreInterfaceToString } from "../utils/netmonCoreInterface";

interface Props {
  authState: AuthStateAdmin;
  onSelect(iface: NetmonCoreInterface): void;
  onSelectNone?: () => void;
  onClose(): void;
}

function NetmonCoreInterfaceSelectFlyout({
  authState,
  onSelect,
  onSelectNone,
  onClose,
}: Props) {
  const [query, setQuery] = useState("");

  // Delay the query to server
  const [delayedQuery, setDelayedQuery] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedQuery(query);
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, [query]);

  const interfacesService = useNetmonCoreInterfacesService(
    authState,
    delayedQuery
  );

  const handleOnSelect = useCallback(
    (iface: NetmonCoreInterface) => {
      onClose();
      onSelect(iface);
    },
    [onClose, onSelect]
  );

  const listItems = useMemo((): EuiListGroupItemProps[] => {
    switch (interfacesService.status) {
      case "error":
        return []; // Error is show elsewhere
      case "loading":
        return [{ label: "Hämtar..." }];
      case "loaded":
        if (interfacesService.payload.items.length === 0) {
          return [{ label: "Inga resultat", color: "subdued" }];
        }

        return interfacesService.payload.items.map((iface) => ({
          key: iface.id,
          label: netmonCoreInterfaceToString(iface),
          onClick: () => handleOnSelect(iface),
        }));
      default:
        assertNever(interfacesService);
    }
  }, [interfacesService, handleOnSelect]);

  return (
    <EuiFlyout ownFocus onClose={onClose}>
      <EuiFlyoutHeader>
        <EuiTitle size="m">
          <h2>Välj switchport</h2>
        </EuiTitle>
        <EuiSpacer />
        {isError(interfacesService) && (
          <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
            <ServiceErrorMessage error={interfacesService} />
          </EuiCallOut>
        )}
        <EuiFieldSearch
          placeholder="Sök"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          fullWidth
          isLoading={isLoading(interfacesService)}
        />
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiListGroup
          flush
          maxWidth={false}
          wrapText
          gutterSize="none"
          listItems={listItems}
        />
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty iconType="cross" onClick={onClose} flush="left">
              Stäng
            </EuiButtonEmpty>
          </EuiFlexItem>
          {onSelectNone && (
            <EuiFlexItem grow={false}>
              <EuiButton
                onClick={() => {
                  onClose();
                  onSelectNone();
                }}
              >
                Använd ingen switchport
              </EuiButton>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  );
}

export default NetmonCoreInterfaceSelectFlyout;
