import { DefaultItemAction, EuiLink } from "@elastic/eui";
import React, { useCallback, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Service, useServicesService } from "../../../api/resources/service";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import { isLoaded, isLoading } from "../../../api/useService";
import TableCellJson from "../../../table/TableCellJson";
import { netmonCoreInterfaceToString } from "../../../utils/netmonCoreInterface";
import RouterLinkAdapter from "../../../utils/RouterLinkAdapter";
import NetmonCoreInterfaceDisplay from "../../../widgets/NetmonCoreInterfaceDisplay";
import { Table, TableColumn } from "../../../widgets/Table";
import { organizationHref } from "../../organizations/view/OrganizationPage";
import {
  alarmPriorityToString,
  combinedAddressString,
  combinedProductString,
  serviceStatusToString,
} from "../service-utils";
import { serviceHref } from "../view/ServicePage";
import CombinedAddressCell from "./CombinedAddressCell";
import CombinedProductCell from "./CombinedProductCell";

type Column<ItemType> = TableColumn<ItemType> & {
  /** If true, column is only visible for user.group FB-PORTAL-ADMIN */
  adminOnly?: boolean;
};

const COLUMNS: Column<Service>[] = [
  {
    type: "field",
    field: "number",
    name: "Förbindelse",
    dataType: "string",
    sortable: true,
    render: (_, service) => (
      <RouterLinkAdapter to={serviceHref(service.id)}>
        {(linkProps) => <EuiLink {...linkProps}>{service.number}</EuiLink>}
      </RouterLinkAdapter>
    ),
  },
  {
    type: "field",
    field: "organization_name",
    name: "Organisation",
    dataType: "string",
    sortable: true,
    adminOnly: true,
    render: (_, service) => (
      <RouterLinkAdapter to={organizationHref(service.organization_id)}>
        {(linkProps) => (
          <EuiLink {...linkProps}>{service.organization_name}</EuiLink>
        )}
      </RouterLinkAdapter>
    ),
  },
  {
    type: "field",
    field: "status",
    name: "Status",
    render: (_, service) => serviceStatusToString(service.status),
    csvValueFn: (service) => serviceStatusToString(service.status),
    dataType: "string",
  },
  {
    // Combined product description from name, capacity and technology
    // field: "product_combined",
    type: "computed",
    field: "product_combined",
    name: "Produkt (Kombinerad)",
    description: "Produktnamn, teknik och kapacitet",
    render: (service: Service) => (
      <CombinedProductCell service={service} isDetails={false} />
    ),
    csvValueFn: combinedProductString,
  },
  {
    type: "field",
    field: "product_name",
    name: "Produktnamn",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "product_capacity",
    name: "Kapacitet",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "product_technology",
    name: "Teknik",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "customer_ref",
    name: "Förbindelsereferens",
    dataType: "string",
  },
  {
    field: "address_combined",
    type: "computed",
    // Combined a and b-side (if any) address
    name: "Adress",
    description: "Kombinerad adress från både A- och B-sida",
    render: (service: Service) => (
      <CombinedAddressCell service={service} isDetails={false} />
    ),
    csvValueFn: combinedAddressString,
  },
  {
    type: "field",
    field: "a_location_name",
    name: "A-namn",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "a_address",
    name: "A-adress",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "a_demarcation",
    name: "A-avlämning",
    adminOnly: true,
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "a_property_designation",
    name: "A-fast.bet.",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "a_to_b_measurement",
    name: "Mätresultat A",
    sortable: false,
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "a_graph_interface",
    name: "A-grafport",
    adminOnly: true,
    hideByDefault: true,
    render: (_, service) => (
      <NetmonCoreInterfaceDisplay iface={service.a_graph_interface} />
    ),
    csvValueFn: (service) =>
      service.a_graph_interface &&
      netmonCoreInterfaceToString(service.a_graph_interface),
    dataType: "string",
  },
  {
    type: "field",
    field: "b_location_name",
    name: "B-namn",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "b_address",
    name: "B-adress",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "b_demarcation",
    name: "B-avlämning",
    adminOnly: true,
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "b_property_designation",
    name: "B-fast.bet.",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "b_to_a_measurement",
    name: "Mätresultat B",
    sortable: false,
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "delivery_date",
    name: "Startdatum",
    dataType: "date",
    render: (_, service) => service.delivery_date,
    // defaultSortDirection: "desc",
  },
  {
    type: "field",
    field: "termination_date",
    name: "Slutdatum",
    dataType: "date",
    render: (_, service) => service.termination_date,
    // defaultSortDirection: "desc",
  },
  {
    type: "field",
    field: "internal_note",
    name: "Intern information",
    adminOnly: true,
    sortable: false,
    dataType: "string",
  },
  {
    type: "field",
    field: "alarm_priority",
    name: "Larmprioritet",
    adminOnly: true,
    hideByDefault: true,
    render: (_, service) =>
      service.alarm_priority ? (
        <>{alarmPriorityToString(service.alarm_priority)}</>
      ) : null,
    csvValueFn: (service) =>
      service.alarm_priority
        ? alarmPriorityToString(service.alarm_priority)
        : null,
    dataType: "string",
  },
  {
    type: "field",
    field: "monitored_interface_count",
    name: "Övervakade portar",
    adminOnly: true,
    hideByDefault: true,
    dataType: "number",
  },
  {
    type: "field",
    field: "json",
    name: "JSON",
    dataType: "string",
    adminOnly: true,
    hideByDefault: true,
    sortable: false,
    render: (_, service) => <TableCellJson data={service} />,
    csvValueFn: JSON.stringify,
  },
];

const emptyServices: Service[] = [];

interface Props {
  authState: AuthStateAuthed;
}

export const ServicesTable = ({ authState }: Props) => {
  const servicesService = useServicesService(authState);
  const services = useMemo(
    () =>
      isLoaded(servicesService) ? servicesService.payload.items : emptyServices,
    [servicesService]
  );

  // Apply filters from search params
  const { search } = useLocation();
  const filteredServices = useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    let result = services;

    // Filter for organization
    const organizationFilter = urlSearchParams.getAll("organization_id");
    if (organizationFilter.length > 0) {
      result = result.filter((service) =>
        organizationFilter.includes(service.organization_id.toString())
      );
    }

    // Filter for status
    const filterStatus = urlSearchParams.getAll("status");
    if (filterStatus.length > 0) {
      result = result.filter((service) =>
        filterStatus.includes(service.status)
      );
    }

    return result;
  }, [services, search]);

  const serviceToKeywords = useCallback(
    (service: Service): string[] => {
      return [
        // Extra search criteria for admins
        isAdmin(authState) && service.internal_note
          ? service.internal_note.split(/\s+/)
          : [],
        [service.number],
        service.organization_name.split(/\s+/),
        [serviceStatusToString(service.status)],
        service.product_name.split(/\s+/),
        [service.product_capacity],
        [service.product_technology],
        service.customer_ref.split(/\s+/),
        service.a_location_name.split(/\s+/),
        service.a_address.split(/\s+/),
        service.a_property_designation.split(/\s+/),
        service.b_location_name.split(/\s+/),
        service.b_address.split(/\s+/),
        service.b_property_designation.split(/\s+/),
        service.delivery_date ? [service.delivery_date] : [],
        service.termination_date ? [service.termination_date] : [],
      ].flat();
    },
    [authState]
  );

  // Filter out column that are only useful for admins
  const columns = useMemo(() => {
    if (isAdmin(authState)) {
      return COLUMNS;
    } else {
      return COLUMNS.filter((column) => !column?.adminOnly);
    }
  }, [authState]);

  const navigate = useNavigate();
  const actions: DefaultItemAction<Service>[] = [
    {
      name: "Felanmäl",
      icon: "alert",
      description: "Felanmäl",
      onClick: (service) => {
        navigate(`${service.id}/felanmäl`);
      },
      type: "icon",
      color: "danger",
      enabled: (service) => service.status === "active",
    },
    {
      type: "icon",
      name: "Säg upp",
      description: "Säg upp",
      icon: "minusInCircle",
      onClick: (service) => {
        navigate(`${service.id}/säg-upp`);
      },
      color: "warning",
      enabled: (service) => service.status === "active",
    },
  ];

  return (
    <Table
      items={filteredServices}
      itemToKeywords={serviceToKeywords}
      columns={columns}
      preferencesKey="services"
      actions={actions}
      loading={isLoading(servicesService)}
    />
  );
};

export default ServicesTable;
