import { useCallback, useEffect } from "react";
import useCache from "../../utils/useCache";
import { AuthStateAuthed, AuthStateAdmin } from "../useAuthService";
import {
  buildApiHref,
  isLoaded,
  useAutoGetService,
  useService,
} from "../useService";
import { Service } from "./service";
import { statisticsApiHref } from "./statistics";

export type ErrorCaseStatus = "open" | "closed";

export type ErrorCaseUpdateStatusChange = "no-change" | ErrorCaseStatus;

export interface ErrorCaseUpdate {
  id: number;
  by_user: ByUser;
  status_change: ErrorCaseUpdateStatusChange;
  message: string;
  time: string;
}

interface ByUser {
  id: number;
  full_name: string;
}

export interface ErrorCaseListItem {
  id: number;
  organization_id: number;
  organization_name: string;
  service: Service | null;
  by_user: ByUser;
  assigned_user?: ByUser | null; // Only for admins
  number: string;
  status: ErrorCaseStatus;
  error_description: string;
  updates_email: string;
  creation_time: string;
  close_time?: string;
}

interface ListResponse {
  items: ErrorCaseListItem[];
}

export const errorCasesApiHref = (search?: {
  organizationId?: number;
  serviceId?: number;
}) =>
  buildApiHref(
    "error-cases",
    search && {
      ...(search.organizationId && {
        organization_id: search.organizationId.toString(),
      }),
      ...(search.serviceId && {
        service_id: search.serviceId.toString(),
      }),
    }
  );

export const useErrorCasesService = (
  authState: AuthStateAuthed,
  search?: {
    organizationId?: number;
    serviceId?: number;
  }
) => useAutoGetService<ListResponse>(authState, errorCasesApiHref(search));

export interface ErrorCase {
  id: number;
  organization_id: number;
  organization_name: string;
  service_id: number | null;
  by_user: ByUser;
  assigned_user?: ByUser | null; // Only for admins
  number: string;
  status: ErrorCaseStatus;
  error_description: string;
  updates_email: string;
  creation_time: string;
  close_time: string | null;
  updates: ErrorCaseUpdate[];
}

export const errorCaseApiHref = (id: number) =>
  buildApiHref(`error-cases/${id}`);

export const useErrorCaseService = (authState: AuthStateAuthed, id: number) =>
  useAutoGetService<ErrorCase>(authState, errorCaseApiHref(id));

export interface ErrorCaseAddParams {
  organizationId: number;
  serviceId: number | null;
  assignedUserId: number | null; // Only admins allowed anything other than null
  errorDescription: string;
  updatesEmail: string;
  sendUpdateEmail: boolean;
}

export const useErrorCaseAddService = (authState: AuthStateAuthed) => {
  const { service, post: origPost } = useService<ErrorCase>(authState);

  const post = useCallback(
    (params: ErrorCaseAddParams) =>
      origPost(errorCasesApiHref(), {
        organization_id: params.organizationId,
        service_id: params.serviceId,
        assigned_user_id: params.assignedUserId,
        error_description: params.errorDescription,
        updates_email: params.updatesEmail,
        send_update_email: params.sendUpdateEmail,
      }),
    [origPost]
  );

  const { setCache, removeCache } = useCache();
  useEffect(() => {
    if (isLoaded(service)) {
      // Update cache
      setCache(errorCaseApiHref(service.payload.id), service);
      // Extra cache invalidation
      removeCache(errorCasesApiHref(), true);
      removeCache(statisticsApiHref(), true);
    }
  }, [service, setCache, removeCache]);

  return [service, post] as const;
};

export type ErrorCaseAddService = ReturnType<typeof useErrorCaseAddService>[0];

export interface ErrorCaseUpdateParams {
  id: number;
  organizationId: number;
  serviceId: number | null;
  assignedUserId: number | null;
  status: ErrorCaseStatus;
  errorDescription: string;
  updatesEmail: string;
  closeTime: string | null;
}

export const useErrorCaseUpdateService = (authState: AuthStateAdmin) => {
  const { service, patch: origPatch } = useService<ErrorCase>(authState);

  const patch = useCallback(
    (params: ErrorCaseUpdateParams) =>
      origPatch(errorCaseApiHref(params.id), {
        id: params.id,
        organization_id: params.organizationId,
        service_id: params.serviceId,
        assigned_user_id: params.assignedUserId,
        status: params.status,
        error_description: params.errorDescription,
        updates_email: params.updatesEmail,
        close_time: params.closeTime,
      }),
    [origPatch]
  );

  const { setCache, removeCache } = useCache();
  useEffect(() => {
    if (isLoaded(service)) {
      setCache(errorCaseApiHref(service.payload.id), service);
      // Extra cache invalidation
      removeCache(statisticsApiHref(), true);
      removeCache(errorCasesApiHref(), true);
    }
  }, [service, setCache, removeCache]);

  return [service, patch] as const;
};

export interface ErrorCaseUpdateAddParams {
  statusChange: ErrorCaseUpdateStatusChange;
  message: string;
  sendUpdateEmail: boolean;
}

export const errorCaseUpdateApiHref = (errorCaseId: number) =>
  buildApiHref(`error-cases/${errorCaseId}/updates`);

export const useErrorCaseUpdateAddService = (
  authState: AuthStateAuthed,
  organizationId: number,
  errorCaseId: number
) => {
  const { service, post: origPost } = useService<void>(authState);

  const post = useCallback(
    (params: ErrorCaseUpdateAddParams) =>
      origPost(errorCaseUpdateApiHref(errorCaseId), {
        status_change: params.statusChange,
        message: params.message,
        send_update_email: params.sendUpdateEmail,
      }),
    [origPost, errorCaseId]
  );

  const { removeCache } = useCache();
  useEffect(() => {
    if (isLoaded(service)) {
      // Extra cache invalidation
      removeCache(statisticsApiHref(), true);
      removeCache(errorCasesApiHref(), true);
      removeCache(errorCaseApiHref(errorCaseId));
    }
  }, [service, errorCaseId, organizationId, removeCache]);

  return [service, post] as const;
};

type UseAddUpdateReturn = ReturnType<typeof useErrorCaseUpdateAddService>;
export type ErrorCaseUpdateAddService = UseAddUpdateReturn[0];
export type ErrorCaseUpdateAddPost = UseAddUpdateReturn[1];
