import {
  EuiCallOut,
  EuiCollapsibleNav,
  EuiCollapsibleNavGroup,
  EuiFacetButton,
  EuiFacetGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiListGroupItem,
  EuiSkeletonText,
  EuiShowFor,
  EuiText,
} from "@elastic/eui";
import React, { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStatisticsService } from "./api/resources/statistics";
import { AuthStateAuthed, isAdmin } from "./api/useAuthService";
import { isError, isLoaded, isLoading } from "./api/useService";
import { useConfig } from "./config";
import { orderStatusToString } from "./pages/orders/order-utils";
import { ServiceErrorMessage } from "./utils/ErrorMessage";
import RouterLinkAdapter from "./utils/RouterLinkAdapter";

interface Props {
  authState: AuthStateAuthed;
}

export function SideNav({ authState }: Props) {
  const [navIsOpen, setNavIsOpen] = useState(
    !!localStorage.getItem("navIsDocked")
  );
  const [navIsDocked, setNavIsDocked] = useState(
    !!localStorage.getItem("navIsDocked")
  );
  const statsService = useStatisticsService(authState);

  const flipIsOpen = useCallback(() => {
    setNavIsOpen((prev) => !prev);
  }, []);

  const flipIsDocked = useCallback(() => {
    setNavIsDocked((prev) => {
      if (prev) {
        localStorage.removeItem("navIsDocked");
      } else {
        localStorage.setItem("navIsDocked", "true");
      }
      return !prev;
    });
  }, []);

  const { pathname } = useLocation();
  const { version } = useConfig();

  const handleWillNavigate = useCallback(() => {
    // Close SideNav before navigation
    if (!navIsDocked) {
      setNavIsOpen(false);
    }
  }, [navIsDocked]);

  return (
    <EuiCollapsibleNav
      style={{ top: 49, height: "calc(100% - 49px)" }}
      isOpen={navIsOpen}
      isDocked={navIsDocked}
      button={
        <EuiHeaderSectionItemButton
          aria-label="Växla navigation"
          onClick={flipIsOpen}
        >
          <EuiIcon type="menu" size="m" aria-hidden="true" />
        </EuiHeaderSectionItemButton>
      }
      onClose={() => setNavIsOpen(false)}
    >
      <EuiCollapsibleNavGroup>
        {isError(statsService) && (
          <EuiCallOut
            size="s"
            title="Kunde inte hämta statistik"
            color="danger"
            iconType="alert"
          >
            <ServiceErrorMessage error={statsService} />
          </EuiCallOut>
        )}
        <RouterLinkAdapter to="/förbindelser">
          {({ onClick, href }) => (
            <EuiListGroupItem
              isActive={pathname.startsWith("/förbindelser")}
              label="Förbindelser"
              size="l"
              href={href}
              onClick={(event) => {
                handleWillNavigate();
                onClick(event);
              }}
            />
          )}
        </RouterLinkAdapter>
        <EuiFacetGroup style={{ paddingLeft: 16 }}>
          <FilterButton
            to="/förbindelser?status=active"
            quantity={
              isLoaded(statsService)
                ? statsService.payload.services.active
                : undefined
            }
            onWillNavigate={handleWillNavigate}
            isLoading={isLoading(statsService)}
          >
            Aktiva
          </FilterButton>
          <FilterButton
            to="/förbindelser?status=inactive"
            quantity={
              isLoaded(statsService)
                ? statsService.payload.services.inactive
                : undefined
            }
            onWillNavigate={handleWillNavigate}
            isLoading={isLoading(statsService)}
          >
            Inaktiva
          </FilterButton>
        </EuiFacetGroup>
        {authState.user.orderPrivileges !== "none" && (
          <>
            <RouterLinkAdapter to="/order">
              {({ onClick, ...linkProps }) => (
                <EuiListGroupItem
                  isActive={pathname.startsWith("/order")}
                  label="Order"
                  size="l"
                  {...linkProps}
                  onClick={(event) => {
                    handleWillNavigate();
                    onClick(event);
                  }}
                />
              )}
            </RouterLinkAdapter>
            <EuiFacetGroup style={{ paddingLeft: 16 }}>
              {isLoading(statsService) && <EuiSkeletonText lines={1} />}
              {isAdmin(authState) &&
                isLoaded(statsService) &&
                statsService.payload.orders.unassigned > 0 && (
                  <FilterButton
                    to="/order?assigned_user_id=null"
                    quantity={statsService.payload.orders.unassigned}
                    onWillNavigate={handleWillNavigate}
                  >
                    Saknar ansvarig
                  </FilterButton>
                )}
              {isLoaded(statsService) &&
                statsService.payload.authed_user.assigned_active_orders > 0 && (
                  <FilterButton
                    to={`/order?assigned_user_id=${authState.user.id}`}
                    quantity={
                      statsService.payload.authed_user.assigned_active_orders
                    }
                    onWillNavigate={handleWillNavigate}
                  >
                    Du är ansvarig
                  </FilterButton>
                )}
              {isLoaded(statsService) &&
                (
                  [
                    "sent",
                    "canceling-sent",
                    "needs-tender",
                    "tendered",
                    "delivering",
                    "canceling-delivery",
                    "delivered",
                    "delivery-not-possible",
                  ] as const
                )
                  .filter((status) => statsService.payload.orders[status] > 0)
                  .map((status) => (
                    <FilterButton
                      key={status}
                      to={`/order?status=${status}`}
                      quantity={statsService.payload.orders[status]}
                      onWillNavigate={handleWillNavigate}
                    >
                      {orderStatusToString(status)}
                    </FilterButton>
                  ))}
            </EuiFacetGroup>
          </>
        )}
        {authState.user.errorCasePrivileges !== "none" && (
          <>
            <RouterLinkAdapter to="/felärenden">
              {({ onClick, ...linkProps }) => (
                <EuiListGroupItem
                  isActive={pathname.startsWith("/felärenden")}
                  label="Felärenden"
                  size="l"
                  {...linkProps}
                  onClick={(event) => {
                    handleWillNavigate();
                    onClick(event);
                  }}
                />
              )}
            </RouterLinkAdapter>
            <EuiFacetGroup style={{ paddingLeft: 16 }}>
              {isAdmin(authState) &&
                isLoaded(statsService) &&
                statsService.payload.error_cases.unassigned > 0 && (
                  <FilterButton
                    to="/felärenden?assigned_user_id=null"
                    quantity={statsService.payload.error_cases.unassigned}
                    onWillNavigate={handleWillNavigate}
                  >
                    Saknar ansvarig
                  </FilterButton>
                )}
              {isLoaded(statsService) &&
                statsService.payload.authed_user.assigned_open_error_cases >
                  0 && (
                  <FilterButton
                    to={`/felärenden?assigned_user_id=${authState.user.id}`}
                    quantity={
                      statsService.payload.authed_user.assigned_open_error_cases
                    }
                    onWillNavigate={handleWillNavigate}
                  >
                    Du är ansvarig
                  </FilterButton>
                )}
              <FilterButton
                to="/felärenden?status=open"
                quantity={
                  isLoaded(statsService)
                    ? statsService.payload.error_cases.open
                    : undefined
                }
                onWillNavigate={handleWillNavigate}
                isLoading={isLoading(statsService)}
              >
                Pågår
              </FilterButton>
              <FilterButton
                to="/felärenden?status=closed"
                quantity={
                  isLoaded(statsService)
                    ? statsService.payload.error_cases.closed
                    : undefined
                }
                onWillNavigate={handleWillNavigate}
                isLoading={isLoading(statsService)}
              >
                Avslutade
              </FilterButton>
            </EuiFacetGroup>
          </>
        )}
        {isAdmin(authState) && (
          <RouterLinkAdapter to="/organisationer">
            {({ onClick, ...linkProps }) => (
              <EuiListGroupItem
                isActive={pathname === "/organisationer"}
                label="Organisationer"
                size="l"
                {...linkProps}
                onClick={(event) => {
                  handleWillNavigate();
                  onClick(event);
                }}
              />
            )}
          </RouterLinkAdapter>
        )}
      </EuiCollapsibleNavGroup>

      {/* Docking button only for larger screens that can support it*/}
      <EuiShowFor sizes={["l", "xl"]}>
        <EuiCollapsibleNavGroup>
          <EuiListGroupItem
            size="xs"
            color="subdued"
            label={navIsDocked ? "Flytande meny" : "Fäst meny"}
            onClick={flipIsDocked}
            iconType={navIsDocked ? "lock" : "lockOpen"}
          />
        </EuiCollapsibleNavGroup>
      </EuiShowFor>

      <EuiFlexItem grow={1}></EuiFlexItem>

      <EuiFlexItem>
        <EuiText size="xs" textAlign="right">
          <code style={{ padding: 8 }}>v{version}</code>
        </EuiText>
      </EuiFlexItem>
    </EuiCollapsibleNav>
  );
}

interface FilterButtonProps {
  quantity?: number;
  to: string;
  isLoading?: boolean;
  onWillNavigate(): void;
  children: React.ReactNode;
}

function FilterButton({
  quantity,
  to,
  isLoading = false,
  children,
  onWillNavigate,
}: FilterButtonProps) {
  const location = useLocation();

  return (
    <RouterLinkAdapter to={to}>
      {({ onClick }) => (
        <EuiFacetButton
          quantity={quantity}
          isSelected={location.pathname + location.search === to}
          isLoading={isLoading}
          onClick={(event) => {
            onWillNavigate();
            onClick(event);
          }}
        >
          {children}
        </EuiFacetButton>
      )}
    </RouterLinkAdapter>
  );
}

export default SideNav;
