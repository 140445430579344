import {
  EuiDescriptionList,
  EuiDescriptionListDescription,
  EuiDescriptionListTitle,
} from "@elastic/eui";
import { Service } from "../../../api/resources/service";
import { combinedProductString } from "../service-utils";

interface Props {
  service: Pick<
    Service,
    "product_name" | "product_capacity" | "product_technology"
  >;
  isDetails: boolean;
}

export const CombinedProductCell = ({ service, isDetails }: Props) =>
  isDetails ? (
    <EuiDescriptionList>
      <EuiDescriptionListTitle>Produkt</EuiDescriptionListTitle>
      <EuiDescriptionListDescription>
        {service.product_name || "-"}
      </EuiDescriptionListDescription>
      <EuiDescriptionListTitle>Kapacitet</EuiDescriptionListTitle>
      <EuiDescriptionListDescription>
        {service.product_capacity || "-"}
      </EuiDescriptionListDescription>
      <EuiDescriptionListTitle>Teknik</EuiDescriptionListTitle>
      <EuiDescriptionListDescription>
        {service.product_technology || "-"}
      </EuiDescriptionListDescription>
    </EuiDescriptionList>
  ) : (
    <>{combinedProductString(service)}</>
  );

export default CombinedProductCell;
