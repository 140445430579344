import React from "react";
import * as graph from "../api/graph";
import {
  NetmonCoreInterface,
  useNetmonCoreInterfaceGraphService,
} from "../api/resources/netmon_core_interface";
import { AuthStateAdmin } from "../api/useAuthService";
import TrafficChart from "./TrafficChart";

interface Props {
  authState: AuthStateAdmin;
  iface: NetmonCoreInterface;
  rangeName: graph.Range;
}

function InterfaceTrafficChart({ authState, iface, rangeName }: Props) {
  const graphService = useNetmonCoreInterfaceGraphService(
    authState,
    iface.id,
    rangeName
  );

  return <TrafficChart graphService={graphService} rangeName={rangeName} />;
}

export default InterfaceTrafficChart;
