import { EuiFieldText, EuiForm, EuiFormRow } from "@elastic/eui";
import { FormikErrors, FormikProps, withFormik } from "formik";
import React from "react";
import {
  TrustedDevice,
  TrustedDeviceUpdateParams,
} from "../../../api/resources/trusted_device";
import { AuthStateAuthed } from "../../../api/useAuthService";
import propertyOf from "../../../utils/propertyOf";

interface Fields {
  name: string;
}

interface InnerFormProps extends FormikProps<Fields> {
  form: string;
}

const InnerForm = ({
  form,
  errors,
  getFieldProps,
  handleSubmit,
}: InnerFormProps) => (
  <EuiForm component="form" id={form} onSubmit={handleSubmit}>
    <EuiFormRow
      label="Enhetsnamn (valfritt)"
      helpText="Ett namn gör det lättare att känna igen denna enhet."
      isInvalid={!!errors.name}
      error={errors.name}
    >
      <EuiFieldText
        placeholder="Ex. Jobblaptop, Mobilen"
        {...getFieldProps<string>(propertyOf<Fields>("name"))}
      />
    </EuiFormRow>
  </EuiForm>
);

interface Props {
  authState: AuthStateAuthed;
  trustedDevice: TrustedDevice;
  form: string /** id to use on <form> element */;
  onSubmit(request: TrustedDeviceUpdateParams): Promise<unknown>;
}

export const TrustedDeviceEditForm = withFormik<Props, Fields>({
  displayName: "TrustedDeviceEditForm",
  mapPropsToValues: (props) => ({
    name: props.trustedDevice.name,
  }),
  validate: (values) => {
    const errors: FormikErrors<Fields> = {};

    if (values.name) {
      const charsOver = values.name.length - 128;
      if (charsOver > 0) {
        errors.name = `${charsOver} tecken för långt`;
      }
    }

    return errors;
  },
  handleSubmit: async (values, { props, setValues }) => {
    const normalized: typeof values = {
      name: values.name.trim(),
    };

    setValues(normalized);

    props.onSubmit(normalized);
  },
})(InnerForm);

export default TrustedDeviceEditForm;
