import { useCallback, useEffect } from "react";
import useCache from "../../utils/useCache";
import { AuthStateAdmin } from "../useAuthService";
import {
  buildApiHref,
  isLoaded,
  useAutoGetService,
  useService,
} from "../useService";
import { organizationApiHref, organizationsApiHref } from "./organization";

export type Group = "FB-PORTAL-USER" | "FB-PORTAL-ADMIN";

export interface User {
  id: number;
  organization_id: number;
  username: string;
  password_expired: boolean;
  require_2fa: boolean;
  full_name: string;
  group: Group;
  phone: string;
  email: string;
  service_privileges: "view" | "manage";
  error_case_privileges: "none" | "view" | "manage";
  order_privileges: "none" | "view" | "manage";
}

export const usersApiHref = (search?: {
  organizationId?: number;
  group?: Group;
}) =>
  buildApiHref(
    "users",
    search && {
      ...(search.organizationId && {
        organization_id: search.organizationId.toString(),
      }),
      ...(search.group && { group: search.group }),
    }
  );

interface ListResponse {
  items: User[];
}

export const useUsersService = (
  authState: AuthStateAdmin,
  search?: {
    organizationId?: number;
    group?: Group;
  }
) => useAutoGetService<ListResponse>(authState, usersApiHref(search));

const userApiHref = (id: number) => buildApiHref(`users/${id}`);

export interface UserAddParams {
  organizationId: number;
  username: string;
  fullName: string;
  phone: string;
  email: string;
  require2fa: boolean;
  servicePrivileges: User["service_privileges"];
  errorCasePrivileges: User["error_case_privileges"];
  orderPrivileges: User["order_privileges"];
}

export const useUserAddService = (authState: AuthStateAdmin) => {
  const { service, post: origPost } = useService<User>(authState);

  const post = useCallback(
    (params: UserAddParams) =>
      origPost(usersApiHref(), {
        organization_id: params.organizationId,
        username: params.username,
        full_name: params.fullName,
        phone: params.phone,
        email: params.email,
        require_2fa: params.require2fa,
        service_privileges: params.servicePrivileges,
        error_case_privileges: params.errorCasePrivileges,
        order_privileges: params.orderPrivileges,
      }),
    [origPost]
  );

  const { setCache, removeCache } = useCache();
  useEffect(() => {
    if (isLoaded(service)) {
      // Update cache
      setCache(userApiHref(service.payload.id), service);
      // Extra cache invalidation
      removeCache(usersApiHref(), true);
      removeCache(organizationsApiHref(), true);
      removeCache(organizationApiHref(service.payload.organization_id));
    }
  }, [service, setCache, removeCache]);

  return [service, post] as const;
};

export type UserAddPost = ReturnType<typeof useUserAddService>[1];
