import { EuiComboBox, EuiComboBoxOptionOption } from "@elastic/eui";
import {
  Organization,
  useOrganizationsService,
} from "../api/resources/organization";
import { AuthStateAdmin } from "../api/useAuthService";
import { isError, isLoaded, isLoading } from "../api/useService";
import { ServiceErrorMessage } from "../utils/ErrorMessage";

interface Props {
  authState: AuthStateAdmin;
  value: Organization | null;
  onChange(organizationId: Organization | null): void;
}

export function OrganizationSelect({ authState, value, onChange }: Props) {
  const orgsService = useOrganizationsService(authState);

  if (isError(orgsService)) {
    return <ServiceErrorMessage error={orgsService} />;
  }

  const options: EuiComboBoxOptionOption<Organization>[] = isLoaded(orgsService)
    ? orgsService.payload.items.map((o) => ({
        label: o.name,
        value: o,
      }))
    : [];

  const selectedOptions = value
    ? options.filter((o) => o.value?.id === value.id)
    : [];

  return (
    <EuiComboBox
      placeholder="Välj organisation"
      singleSelection={{ asPlainText: true }}
      options={options}
      selectedOptions={selectedOptions}
      onChange={(selectedOpts) => onChange(selectedOpts[0]?.value || null)}
      isLoading={isLoading(orgsService)}
    />
  );
}

export default OrganizationSelect;
