import { EuiBreadcrumb, EuiContext, EuiProvider } from "@elastic/eui";
import "@elastic/eui/dist/eui_theme_light.css";
import React, { useMemo, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Group } from "./api/resources/user";
import { AuthState, useAuthService } from "./api/useAuthService";
import { ConfigContext, getInitialConfig } from "./config";
import Header from "./Header";
import AdminOrNotFoundPage from "./pages/AdminOrNotFoundPage";
import ErrorCasesRouter from "./pages/error-cases/ErrorCasesRouter";
import Login from "./pages/Login";
import Login2fa from "./pages/Login2fa";
import LogoutPage from "./pages/LogoutPage";
import NotFoundPage from "./pages/NotFoundPage";
import OrdersRouter from "./pages/orders/OrdersRouter";
import OrganizationsRouter from "./pages/organizations/OrganizationsRouter";
import PasswordChangePage from "./pages/password-change/PasswordChangePage";
import ServicesRouter from "./pages/services/ServicesRouter";
import UserPage from "./pages/users/view/UserPage";
import translationSv from "./translations/sv.json";
import RequireAuthed from "./utils/RequireAuthed";
import { CacheContextProvider } from "./utils/useCache";
import { ToastsContextProvider } from "./utils/useToasts";
import Toasts from "./widgets/Toasts";

function WithContext() {
  const [config] = useState(getInitialConfig);
  return (
    <ConfigContext.Provider value={config}>
      <CacheContextProvider defaultTtl={60 * 5}>
        <BrowserRouter basename={config.publicUrl}>
          <EuiContext i18n={{ mapping: translationSv }}>
            <EuiProvider colorMode="light">
              <ToastsContextProvider>
                <App />
              </ToastsContextProvider>
            </EuiProvider>
          </EuiContext>
        </BrowserRouter>
      </CacheContextProvider>
    </ConfigContext.Provider>
  );
}

function App() {
  const {
    authState: authStateOrig,
    login,
    login2fa,
    logout,
    login2faCancel,
    passwordChange,
    passwordChangeCancel,
  } = useAuthService();
  const [groupOverride, setGroupOverride] = useState<Group | undefined>();

  /** Use customized authState to allow overrides */
  const authState = useMemo((): AuthState => {
    if (authStateOrig.status !== "authed") {
      return authStateOrig;
    }

    const group = groupOverride || authStateOrig.user.group;

    return {
      ...authStateOrig,
      user: { ...authStateOrig.user, group: group },
    };
  }, [authStateOrig, groupOverride]);

  const [breadcrumbs, setBreadcrumbs] = useState<EuiBreadcrumb[]>([]);

  return (
    <>
      {authState.status === "authed" && (
        <Header
          authState={authState}
          breadcrumbs={breadcrumbs}
          onGroupOverride={setGroupOverride}
        />
      )}
      <Toasts />
      <AppRouter
        authState={authState}
        login={login}
        login2fa={login2fa}
        logout={logout}
        passwordChange={passwordChange}
        passwordChangeCancel={passwordChangeCancel}
        onLogin2faCancel={login2faCancel}
        onBreadcrumbs={setBreadcrumbs}
      />
    </>
  );
}

interface AppRouterProps {
  authState: AuthState;
  login(username: string, password: string): Promise<unknown>;
  login2fa(code: string, trustDeviceName: string | null): Promise<unknown>;
  logout(): Promise<unknown>;
  passwordChange(newPassword: string): Promise<unknown>;
  passwordChangeCancel(): void;
  onLogin2faCancel(): void;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

const AppRouter = ({
  authState,
  login,
  login2fa,
  logout,
  passwordChange,
  passwordChangeCancel,
  onLogin2faCancel,
  onBreadcrumbs,
}: AppRouterProps) => (
  <Routes>
    <Route
      index
      element={
        <RequireAuthed authState={authState}>
          {() => <Navigate to="förbindelser" />}
        </RequireAuthed>
      }
    />
    <Route
      path="logga-in"
      element={<Login authState={authState} onLogin={login} />}
    />
    <Route
      path="logga-in-2fa"
      element={
        <Login2fa
          authState={authState}
          onLogin2fa={login2fa}
          onCancel={onLogin2faCancel}
        />
      }
    />
    <Route
      path="byt-lösenord"
      element={
        <PasswordChangePage
          authState={authState}
          onPasswordChange={passwordChange}
          onCancel={passwordChangeCancel}
        />
      }
    />
    <Route
      path="logga-ut"
      element={<LogoutPage authState={authState} onLogout={logout} />}
    />
    <Route
      path="organisationer/*"
      element={
        <RequireAuthed authState={authState}>
          {(authState) => (
            <AdminOrNotFoundPage authState={authState}>
              {(authState) => (
                <OrganizationsRouter
                  authState={authState}
                  onBreadcrumbs={onBreadcrumbs}
                />
              )}
            </AdminOrNotFoundPage>
          )}
        </RequireAuthed>
      }
    />
    <Route
      path="förbindelser/*"
      element={
        <RequireAuthed authState={authState}>
          {(authState) => (
            <ServicesRouter
              authState={authState}
              onBreadcrumbs={onBreadcrumbs}
            />
          )}
        </RequireAuthed>
      }
    />
    <Route
      path="order/*"
      element={
        <RequireAuthed authState={authState}>
          {(authState) => (
            <OrdersRouter authState={authState} onBreadcrumbs={onBreadcrumbs} />
          )}
        </RequireAuthed>
      }
    />
    <Route
      path="felärenden/*"
      element={
        <RequireAuthed authState={authState}>
          {(authState) => (
            <ErrorCasesRouter
              authState={authState}
              onBreadcrumbs={onBreadcrumbs}
            />
          )}
        </RequireAuthed>
      }
    />
    <Route
      path="kontouppgifter"
      element={
        <RequireAuthed authState={authState}>
          {(authState) => (
            <UserPage authState={authState} onBreadcrumbs={onBreadcrumbs} />
          )}
        </RequireAuthed>
      }
    />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default WithContext;
