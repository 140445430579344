import { EuiBreadcrumb, EuiCallOut } from "@elastic/eui";
import React, { useCallback, useState } from "react";
import { Navigate } from "react-router-dom";
import { useOrderAddService } from "../../../api/resources/order";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import { isError, isLoaded } from "../../../api/useService";
import Page from "../../../Page";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import useBreadcrumb from "../../../utils/useBreadcrumb";
import useUserPreferences from "../../../utils/useUserPreferences";
import AddMonitoredInterface from "../../../widgets/AddMonitoredInterface";
import { orderHref } from "../view/OrderPage";
import { Fields, OrderCreateNewForm } from "./OrderCreateNewForm";

interface Props {
  authState: AuthStateAuthed;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

export function OrderCreateNewPage({ authState, onBreadcrumbs }: Props) {
  const [addService, addOrder] = useOrderAddService(authState);
  const [userPreferences, updateUserPreferences] = useUserPreferences();
  const [addMonIfaceId, setAddMonIface] = useState<number | null>(null);

  useBreadcrumb(onBreadcrumbs, "Ny");

  const onSubmit = useCallback(
    async (fields: Fields) => {
      if (fields.monitorAGraphInterface && fields.aGraphInterface) {
        setAddMonIface(fields.aGraphInterface.id);
      }

      let organizationId: number;
      if (isAdmin(authState)) {
        // Admins must specify organization (enforced by form)
        if (fields.organization === null) {
          throw new Error("Organization id is null");
        }
        organizationId = fields.organization.id;
      } else {
        // Users are only allowed to use their own organization (hidden in form)
        organizationId = authState.user.organizationId;
      }

      await addOrder({
        type: "new",
        organization_id: organizationId,
        assigned_user_id:
          fields.assignedUserId === ""
            ? null
            : parseInt(fields.assignedUserId, 10),
        service_customer_ref: fields.serviceCustomerRef,
        a_graph_interface_id:
          fields.aGraphInterface && fields.aGraphInterface.id,
        a_location_name: fields.aLocationName,
        a_address: fields.aAddress,
        a_property_designation: fields.aPropertyDesignation,
        a_to_b_measurement: fields.aToBMeasurement,
        b_location_name: fields.bLocationName,
        b_address: fields.bAddress,
        b_property_designation: fields.bPropertyDesignation,
        b_to_a_measurement: fields.bToAMeasurement,
        property_owner_contact: fields.propertyOwnerContact,
        customer_ref: fields.customerRef,
        customer_message: fields.customerMessage,
        requested_delivery_date:
          fields.requestedDeliveryDate &&
          fields.requestedDeliveryDate.format("YYYY-MM-DD"),
        technical_contact: fields.technicalContact,
        updates_email: fields.updatesEmail || "",
        send_update_email: fields.sendUpdateEmail,
      });
    },
    [addOrder, authState]
  );

  return (
    <Page title="Ny order">
      {isError(addService) && (
        <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
          <ServiceErrorMessage error={addService} />
        </EuiCallOut>
      )}
      {isLoaded(addService) ? (
        isAdmin(authState) && addMonIfaceId ? (
          <AddMonitoredInterface
            authState={authState}
            serviceId={addService.payload.service.id}
            interfaceId={addMonIfaceId}
            redirectTo={orderHref(addService.payload.id)}
          />
        ) : (
          <Navigate to={orderHref(addService.payload.id)} />
        )
      ) : (
        <OrderCreateNewForm
          authState={authState}
          orderAddService={addService}
          onSubmit={onSubmit}
          userPreferences={userPreferences}
          updateUserPreferences={updateUserPreferences}
        />
      )}
    </Page>
  );
}

export default OrderCreateNewPage;
