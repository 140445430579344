import {
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
} from "@elastic/eui";
import React, { useState } from "react";
import {
  ErrorCase,
  useErrorCaseUpdateService,
} from "../../../api/resources/errorCase";
import { AuthStateAdmin } from "../../../api/useAuthService";
import { isError, isLoading } from "../../../api/useService";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import { UserSelectModal } from "../../../widgets/UserSelectModal";
import UserNameCell from "../../users/UserNameCell";

interface Props {
  authState: AuthStateAdmin;
  errorCase: ErrorCase;
}

export function ErrorCaseAssignedUser({ authState, errorCase }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [updateService, postUpdate] = useErrorCaseUpdateService(authState);

  function handleClick() {
    setIsOpen((last) => !last);
  }

  function handleSelected(userId: number | null) {
    setIsOpen(false);
    postUpdate({
      id: errorCase.id,
      assignedUserId: userId,
      organizationId: errorCase.organization_id,
      serviceId: errorCase.service_id,
      status: errorCase.status,
      errorDescription: errorCase.error_description,
      updatesEmail: errorCase.updates_email,
      closeTime: errorCase.close_time,
    });
  }

  return (
    <>
      <EuiFlexGroup alignItems="center">
        {errorCase.assigned_user && (
          <EuiFlexItem grow={false}>
            <UserNameCell user={errorCase.assigned_user} />
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            onClick={handleClick}
            iconType="user"
            isLoading={isLoading(updateService)}
            size="s"
          >
            {errorCase.assigned_user === null ? "Tilldela ärende" : "Ändra"}
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
      {isError(updateService) && (
        <EuiCallOut
          title="Kunde inte spara ansvarig"
          color="danger"
          iconType="alert"
        >
          <ServiceErrorMessage error={updateService} />
        </EuiCallOut>
      )}
      {isOpen && (
        <UserSelectModal
          authState={authState}
          initialUserId={errorCase.assigned_user?.id || null}
          onClose={() => setIsOpen(false)}
          onSelected={handleSelected}
        />
      )}
    </>
  );
}

export default ErrorCaseAssignedUser;
