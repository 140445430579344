import { EuiBreadcrumb, EuiDelayRender, EuiLoadingSpinner } from "@elastic/eui";
import React from "react";
import { Route, Routes, useParams } from "react-router";
import { useErrorCaseService } from "../../api/resources/errorCase";
import { AuthStateAuthed } from "../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../api/useService";
import Page from "../../Page";
import { ServiceErrorMessage } from "../../utils/ErrorMessage";
import useBreadcrumb from "../../utils/useBreadcrumb";
import NotFoundPage from "../NotFoundPage";
import ErrorCaseNotFoundPage from "./ErrorCaseNotFoundPage";
import ErrorCasesPage from "./list/ErrorCasesPage";
import ErrorCasePage from "./view/ErrorCasePage";

interface ErrorCasesRouterProps {
  authState: AuthStateAuthed;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

export function ErrorCasesRouter({
  authState,
  onBreadcrumbs,
}: ErrorCasesRouterProps) {
  const setClientBreadcrumb = useBreadcrumb(onBreadcrumbs, "Felärenden");

  return (
    <Routes>
      <Route index element={<ErrorCasesPage authState={authState} />} />
      <Route
        path=":errorCaseId/*"
        element={
          <ErrorCaseRouter
            authState={authState}
            onBreadcrumbs={setClientBreadcrumb}
          />
        }
      />
    </Routes>
  );
}

interface ErrorCaseRouterProps {
  authState: AuthStateAuthed;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

function ErrorCaseRouter({ authState, onBreadcrumbs }: ErrorCaseRouterProps) {
  const params = useParams<"errorCaseId">();
  if (params.errorCaseId === undefined) {
    throw Error("Missing errorCaseId path parameter");
  }
  const errorCaseId = parseInt(params.errorCaseId, 10);
  const errorCaseService = useErrorCaseService(authState, errorCaseId);

  useBreadcrumb(
    onBreadcrumbs,
    isLoaded(errorCaseService) ? errorCaseService.payload.number : undefined
  );

  if (isError(errorCaseService)) {
    if (errorCaseService.httpStatus === 404) {
      return <ErrorCaseNotFoundPage />;
    } else {
      return (
        <Page title="Felärendet gick inte att hämta">
          <ServiceErrorMessage error={errorCaseService} />
        </Page>
      );
    }
  }

  if (isLoading(errorCaseService)) {
    return (
      <EuiDelayRender>
        <Page title="Hämtar ärende...">
          <EuiLoadingSpinner />
        </Page>
      </EuiDelayRender>
    );
  }

  const errorCase = errorCaseService.payload;

  return (
    <Routes>
      <Route
        index
        element={<ErrorCasePage authState={authState} errorCase={errorCase} />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default ErrorCasesRouter;
