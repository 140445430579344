interface ServiceInit {
  status: "init";
}

export interface ServiceLoading {
  status: "loading";
}

export interface ServiceLoaded<T> {
  status: "loaded";
  payload: T;
  track: string | null;
}

export interface ServiceError {
  status: "error";
  message: string;
  track: string | null;
  httpStatus?: number;
}

export type ServiceResponse<T> = ServiceLoaded<T> | ServiceError;

export type Service<T> =
  | ServiceInit
  | ServiceLoading
  | ServiceLoaded<T>
  | ServiceError;

// Use stable singletons for these "placeholder" values
export const serviceInit: ServiceInit = { status: "init" };
export const serviceLoading: ServiceLoading = { status: "loading" };

interface ApiFetchArgs {
  href: string;
  token?: string | null;
  body?: any;
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
}

export async function fetchFromApi<ResponseBody = void>({
  href,
  token = null,
  body,
  method,
}: ApiFetchArgs): Promise<ServiceResponse<ResponseBody>> {
  const headers: Record<string, string> = {};
  if (body) {
    headers["Content-Type"] = "application/json; charset=utf-8";
  }
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  try {
    const response = await fetch(href, {
      method,
      credentials: "include",
      mode: "cors",
      headers,
      body: body && JSON.stringify(body),
    });

    const payload =
      response.headers.get("Content-Type") === "application/json"
        ? await response.json()
        : await response.blob();

    const track = response.headers.get("X-Track");

    if (!response.ok) {
      return {
        status: "error",
        message: payload?.message || `api.error.http.${response.status}`,
        track,
        httpStatus: response.status,
      };
    }

    return {
      status: "loaded",
      payload,
      track,
    };
  } catch (e) {
    return {
      status: "error",
      message: "fetch-network-error",
      track: null,
    };
  }
}

export default fetchFromApi;
