import {
  EuiDescriptionList,
  EuiDescriptionListDescription,
  EuiDescriptionListTitle,
} from "@elastic/eui";
import { Service } from "../../../api/resources/service";
import { combinedAddressString } from "../service-utils";

interface Props {
  service: Pick<
    Service,
    | "a_location_name"
    | "a_address"
    | "a_property_designation"
    | "b_location_name"
    | "b_address"
    | "b_property_designation"
  >;
  isDetails: boolean;
}

export const CombinedAddressCell = ({ service, isDetails }: Props) =>
  isDetails ? (
    <EuiDescriptionList>
      <EuiDescriptionListTitle>A-platsnamn</EuiDescriptionListTitle>
      <EuiDescriptionListDescription>
        {service.a_location_name || "-"}
      </EuiDescriptionListDescription>
      <EuiDescriptionListTitle>A-adress</EuiDescriptionListTitle>
      <EuiDescriptionListDescription>
        {service.a_address || "-"}
      </EuiDescriptionListDescription>
      <EuiDescriptionListTitle>A-fastighetsbeteckning</EuiDescriptionListTitle>
      <EuiDescriptionListDescription>
        {service.a_property_designation || "-"}
      </EuiDescriptionListDescription>
      <EuiDescriptionListTitle>B-platsnamn</EuiDescriptionListTitle>
      <EuiDescriptionListDescription>
        {service.b_location_name || "-"}
      </EuiDescriptionListDescription>
      <EuiDescriptionListTitle>B-adress</EuiDescriptionListTitle>
      <EuiDescriptionListDescription>
        {service.b_address || "-"}
      </EuiDescriptionListDescription>
      <EuiDescriptionListTitle>B-fastighetsbeteckning</EuiDescriptionListTitle>
      <EuiDescriptionListDescription>
        {service.b_property_designation || "-"}
      </EuiDescriptionListDescription>
    </EuiDescriptionList>
  ) : (
    <>{combinedAddressString(service)}</>
  );

export default CombinedAddressCell;
