import {
  EuiAvatar,
  EuiBreadcrumb,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeader,
  EuiHeaderBreadcrumbs,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiLink,
  EuiListGroup,
  EuiListGroupItem,
  EuiPopover,
  EuiSpacer,
  EuiSwitch,
  EuiText,
} from "@elastic/eui";
import React, { useState } from "react";
import { Group } from "./api/resources/user";
import { AuthStateAuthed, isAdmin } from "./api/useAuthService";
import gruppenLogo from "./logos/gruppen.png";
import SideNav from "./SideNav";
import RouterLinkAdapter from "./utils/RouterLinkAdapter";

interface Props {
  authState: AuthStateAuthed;
  breadcrumbs: EuiBreadcrumb[];
  onGroupOverride(group: Group): void;
}

export const Header = ({ authState, breadcrumbs, onGroupOverride }: Props) => (
  <EuiHeader position="fixed">
    <EuiHeaderSection grow={false}>
      <EuiHeaderSectionItem>
        <SideNav authState={authState} />
      </EuiHeaderSectionItem>
      <EuiHeaderSectionItem aria-hidden>
        <RouterLinkAdapter to="/förbindelser">
          {(linkProps) => (
            <EuiLink
              style={{ paddingLeft: 12, paddingRight: 12 }}
              {...linkProps}
            >
              <img src={gruppenLogo} height="42" alt="Lidén Data Gruppen" />
            </EuiLink>
          )}
        </RouterLinkAdapter>
      </EuiHeaderSectionItem>
      <EuiHeaderBreadcrumbs breadcrumbs={breadcrumbs} max={5} />
    </EuiHeaderSection>

    <EuiHeaderSection side="right">
      {process.env.NODE_ENV === "development" && (
        <EuiHeaderSectionItem>
          <EuiSwitch
            label="Admin"
            checked={isAdmin(authState)}
            onChange={({ target }) =>
              onGroupOverride(
                target.checked ? "FB-PORTAL-ADMIN" : "FB-PORTAL-USER"
              )
            }
          />
        </EuiHeaderSectionItem>
      )}
      <EuiHeaderSectionItem>
        <UserMenu authState={authState} />
      </EuiHeaderSectionItem>
    </EuiHeaderSection>
  </EuiHeader>
);

interface UserMenuProps {
  authState: AuthStateAuthed;
}

function UserMenu({ authState }: UserMenuProps) {
  const [isOpen, setIsOpen] = useState(false);

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const button = (
    <EuiHeaderSectionItemButton
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Account menu"
      onClick={onMenuButtonClick}
    >
      <EuiAvatar name={authState.user.fullName} size="s" />
    </EuiHeaderSectionItemButton>
  );

  return (
    <EuiPopover
      ownFocus
      button={button}
      isOpen={isOpen}
      anchorPosition="downRight"
      closePopover={closeMenu}
    >
      <div style={{ width: 320 }}>
        <EuiFlexGroup
          gutterSize="m"
          className="euiHeaderProfile"
          responsive={false}
        >
          <EuiFlexItem grow={false}>
            <EuiAvatar name={authState.user.fullName} size="xl" />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <p>{authState.user.fullName}</p>
            </EuiText>

            <EuiSpacer size="m" />

            <EuiListGroup flush gutterSize="none">
              <RouterLinkAdapter to="/kontouppgifter">
                {(linkProps) => (
                  <EuiListGroupItem
                    label="Kontouppgifter"
                    href={linkProps.href}
                    onClick={(e) => {
                      closeMenu();
                      linkProps.onClick(e);
                    }}
                  />
                )}
              </RouterLinkAdapter>
              <RouterLinkAdapter to="/logga-ut">
                {(linkProps) => (
                  <EuiListGroupItem label="Logga ut" {...linkProps} />
                )}
              </RouterLinkAdapter>
            </EuiListGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  );
}

export default Header;
