import { DefaultItemAction, EuiLink } from "@elastic/eui";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Organization,
  useOrganizationsService,
} from "../../../api/resources/organization";
import { AuthStateAdmin } from "../../../api/useAuthService";
import { isLoaded, isLoading } from "../../../api/useService";
import TableCellJson from "../../../table/TableCellJson";
import RouterLinkAdapter from "../../../utils/RouterLinkAdapter";
import { Table, TableColumn } from "../../../widgets/Table";
import { organizationHref } from "../view/OrganizationPage";

const COLUMNS: TableColumn<Organization>[] = [
  {
    type: "field",
    field: "name",
    name: "Namn",
    dataType: "string",
    render: (_, org) => (
      <RouterLinkAdapter to={organizationHref(org.id)}>
        {(linkProps) => <EuiLink {...linkProps}>{org.name}</EuiLink>}
      </RouterLinkAdapter>
    ),
  },
  {
    type: "field",
    field: "customer_number",
    name: "Kundnummer",
    dataType: "number",
  },
  {
    type: "field",
    field: "contact_details",
    name: "Kontaktuppgifter",
    dataType: "string",
  },
  {
    type: "field",
    field: "number_prefix",
    name: "Nummerprefix",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "order_emails",
    name: "Mejl för orderstatus",
    dataType: "string",
    render: (emails: string[]) => (
      <div>
        {emails.map((email, index) => (
          <div key={index}>{email}</div>
        ))}
      </div>
    ),
    csvValueFn: (org) => org.order_emails.join(", "),
  },
  {
    type: "field",
    field: "error_case_emails",
    name: "Mejl för felärendestatus",
    dataType: "string",
    render: (emails: string[]) => (
      <div>
        {emails.map((email, index) => (
          <div key={index}>{email}</div>
        ))}
      </div>
    ),
    csvValueFn: (org) => org.error_case_emails.join(", "),
    sortable: false,
  },
  {
    type: "computed",
    field: "json",
    name: "JSON",
    hideByDefault: true,
    render: (org) => <TableCellJson data={org} />,
    csvValueFn: JSON.stringify,
    sortable: false,
  },
];

interface Props {
  authState: AuthStateAdmin;
}

function OrganizationsTable({ authState }: Props) {
  const orgsService = useOrganizationsService(authState);
  const orgs = isLoaded(orgsService) ? orgsService.payload.items : [];
  const navigate = useNavigate();

  const actions: DefaultItemAction<Organization>[] = [
    {
      type: "icon",
      name: "Ändra",
      icon: "documentEdit",
      description: "Ändra på organisation",
      onClick: (service) => {
        navigate(`${organizationHref(service.id)}/ändra`);
      },
    },
  ];

  return (
    <Table
      items={orgs}
      itemToKeywords={organizationToKeywords}
      columns={COLUMNS}
      preferencesKey="organizations"
      actions={actions}
      loading={isLoading(orgsService)}
    />
  );
}

function organizationToKeywords(org: Organization): string[] {
  return [
    org.name.split(/\s+/),
    org.customer_number ? [org.customer_number.toString()] : [],
  ].flat();
}

export default OrganizationsTable;
