import { EuiToolTip } from "@elastic/eui";
import React, { ReactNode } from "react";
import { NetmonCoreInterface } from "../api/resources/netmon_core_interface";
import { netmonCoreInterfaceToString } from "../utils/netmonCoreInterface";

interface Props {
  iface: NetmonCoreInterface | null;
  placeholder?: ReactNode;
}

/** Display a descriptive string for the interface, with a tooltip containing
 * extra information.
 */
export function NetmonCoreInterfaceDisplay({
  iface,
  placeholder = null,
}: Props) {
  if (!iface) {
    return <>{placeholder}</>;
  }

  const tooltipContent = (
    <>
      <div>SNMP ifIndex: {iface.snmp_index}</div>
      <div>SWDOC interface ID: {iface.id}</div>
      <div>SWDOC switch ID: {iface.device_id}</div>
    </>
  );

  return (
    <EuiToolTip content={tooltipContent}>
      <>{netmonCoreInterfaceToString(iface)}</>
    </EuiToolTip>
  );
}

export default NetmonCoreInterfaceDisplay;
