import { EuiCallOut, EuiLink } from "@elastic/eui";
import React from "react";
import Page from "../../Page";
import RouterLinkAdapter from "../../utils/RouterLinkAdapter";

export const ErrorCaseNotFoundPage = () => (
  <Page title="Felärende">
    <EuiCallOut title="Ärendet finns inte" color="danger" iconType="alert">
      <p>Det gick inte att hitta detta felärende.</p>
      <RouterLinkAdapter to="/felärenden">
        {(linkProps) => (
          <EuiLink color="warning" {...linkProps}>
            Visa alla felärenden
          </EuiLink>
        )}
      </RouterLinkAdapter>
    </EuiCallOut>
  </Page>
);

export default ErrorCaseNotFoundPage;
