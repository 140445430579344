import {
  EuiButtonGroup,
  EuiButtonGroupOptionProps,
  EuiButtonIcon,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import React, { useState } from "react";
import * as graph from "../../../api/graph";
import { Service, serviceGraphApiHref } from "../../../api/resources/service";
import { AuthStateAuthed } from "../../../api/useAuthService";
import useCache from "../../../utils/useCache";
import ServiceTrafficChart from "../../../widgets/ServiceTrafficChart";

interface Props {
  authState: AuthStateAuthed;
  service: Service;
}

const rangeOptions: EuiButtonGroupOptionProps[] = [
  { id: "day", label: "Dygn" },
  { id: "week", label: "Vecka" },
  { id: "month", label: "Månad" },
  { id: "year", label: "År" },
];

export function ServiceTrafficCharts({ authState, service }: Props) {
  const [range, setRange] = useState<graph.Range>("day");
  const { removeCache } = useCache();

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText>
            <h2>Trafikgraf</h2>
          </EuiText>
        </EuiFlexItem>
        {service.a_graph_interface_id && (
          <>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                iconType="refresh"
                aria-label="Ladda om"
                onClick={() =>
                  removeCache(serviceGraphApiHref(service.id, range))
                }
                size="m"
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonGroup
                legend="Senaste"
                options={rangeOptions}
                idSelected={range}
                onChange={(id: string) => setRange(id as graph.Range)}
              />
            </EuiFlexItem>
          </>
        )}
      </EuiFlexGroup>
      <EuiSpacer />
      {service.a_graph_interface_id ? (
        <ServiceTrafficChart
          authState={authState}
          service={service}
          rangeName={range}
        />
      ) : (
        <EuiCallOut
          title="Förbindelsen saknar grafdata."
          color="primary"
          iconType="iInCircle"
        />
      )}
    </>
  );
}

export default ServiceTrafficCharts;
