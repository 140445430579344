import { EuiBreadcrumb, EuiCallOut, EuiSpacer } from "@elastic/eui";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Organization,
  useOrganizationUpdateService,
} from "../../../api/resources/organization";
import { AuthStateAdmin } from "../../../api/useAuthService";
import { isError, isLoaded } from "../../../api/useService";
import Page from "../../../Page";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import useBreadcrumb from "../../../utils/useBreadcrumb";
import OrganizationEditForm from "./OrganizationEditForm";

interface Props {
  authState: AuthStateAdmin;
  organization: Organization;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

export function OrganizationEditPage({
  authState,
  organization,
  onBreadcrumbs,
}: Props) {
  useBreadcrumb(onBreadcrumbs, "Ändra");

  const [organizationUpdateService, submitUpdate] =
    useOrganizationUpdateService(authState, organization.id);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoaded(organizationUpdateService)) {
      navigate(-1);
    }
  }, [organizationUpdateService, navigate]);

  return (
    <Page title="Ändra organisation">
      {isError(organizationUpdateService) && (
        <>
          <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
            <ServiceErrorMessage error={organizationUpdateService} />
          </EuiCallOut>
          <EuiSpacer />
        </>
      )}
      <OrganizationEditForm
        organization={organization}
        authState={authState}
        onSubmit={submitUpdate}
      />
    </Page>
  );
}

export default OrganizationEditPage;
