import { EuiGlobalToastList } from "@elastic/eui";
import { Toast as ToastOriginal } from "@elastic/eui/src/components/toast/global_toast_list";
import { useCallback } from "react";
import useToasts from "../utils/useToasts";

export function Toasts() {
  const { toasts, removeToast } = useToasts();

  const handleRemove = useCallback(
    (toast: ToastOriginal) => {
      removeToast(toast.id);
    },
    [removeToast]
  );

  return (
    <EuiGlobalToastList
      toasts={toasts}
      dismissToast={handleRemove}
      toastLifeTimeMs={12000}
    />
  );
}

export default Toasts;
