import {
  EuiDescriptionList,
  EuiDescriptionListDescription,
  EuiDescriptionListTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import React from "react";
import { Order } from "../../../api/resources/order";
import { Organization } from "../../../api/resources/organization";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import RouterLinkAdapter from "../../../utils/RouterLinkAdapter";
import NetmonCoreInterfaceDisplay from "../../../widgets/NetmonCoreInterfaceDisplay";
import { organizationHref } from "../../organizations/view/OrganizationPage";
import { alarmPriorityToString } from "../../services/service-utils";
import { serviceHref } from "../../services/view/ServicePage";
import { orderStatusToString, orderTypeToString } from "../order-utils";
import OrderAssignedUser from "./OrderAssignedUser";

interface Props {
  authState: AuthStateAuthed;
  organization: Organization;
  order: Order;
}

const OrderDescription = ({ order, authState, organization }: Props) => (
  <EuiFlexGroup>
    <EuiFlexItem>
      <EuiText>
        <h3>Orderinfo</h3>
      </EuiText>
      <EuiDescriptionList>
        {isAdmin(authState) && (
          <>
            <EuiDescriptionListTitle>Organisation</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <RouterLinkAdapter to={organizationHref(order.organization_id)}>
                {(linkProps) => (
                  <EuiLink {...linkProps}>{order.organization_name}</EuiLink>
                )}
              </RouterLinkAdapter>
            </EuiDescriptionListDescription>
            <EuiDescriptionListTitle>Ansvarig</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <OrderAssignedUser authState={authState} order={order} />
            </EuiDescriptionListDescription>
          </>
        )}
        <EuiDescriptionListTitle>Ordernummer</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.number}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Orderreferens</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.customer_ref || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Status</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {orderStatusToString(order.status)}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Typ</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {orderTypeToString(order.type)}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Önskad leveransdag</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.requested_delivery_date || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Beräknad leveransdag</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.expected_delivery_date || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Faktisk leveransdag</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.actual_delivery_date || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Teknisk kontakt</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.technical_contact || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>
          Kontakt fastighetsägare
        </EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.property_owner_contact || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>
          Mejladress för statusuppdateringar
        </EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.updates_email
            ? order.updates_email
            : organization.order_emails.length
            ? organization.order_emails.map((email, index) => (
                <div key={index}>{email}</div>
              ))
            : "Saknas"}
        </EuiDescriptionListDescription>
        {isAdmin(authState) && (
          <>
            <EuiDescriptionListTitle>
              Intern orderinformation
            </EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <EuiText size="s" style={{ whiteSpace: "pre-wrap" }}>
                <p>{order.internal_note || "-"}</p>
              </EuiText>
            </EuiDescriptionListDescription>
          </>
        )}
      </EuiDescriptionList>
    </EuiFlexItem>
    <EuiFlexItem>
      <EuiText>
        <h3>Förbindelseinfo</h3>
      </EuiText>
      <EuiDescriptionList>
        <EuiDescriptionListTitle>Förbindelse</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.type === "new" ? (
            order.service.number || "-"
          ) : (
            <RouterLinkAdapter to={serviceHref(order.service.id)}>
              {(linkProps) => (
                <EuiLink {...linkProps}>{order.service.number}</EuiLink>
              )}
            </RouterLinkAdapter>
          )}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Förbindelsereferens</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.service.customer_ref || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Produkt</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.service.product_name || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Kapacitet</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.service.product_capacity || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Teknik</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.service.product_technology || "-"}
        </EuiDescriptionListDescription>
        {isAdmin(authState) && (
          <>
            <EuiDescriptionListTitle>
              Intern förbindelseinformation
            </EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <EuiText size="s" style={{ whiteSpace: "pre-wrap" }}>
                <p>{order.service.internal_note || "-"}</p>
              </EuiText>
            </EuiDescriptionListDescription>
            <EuiDescriptionListTitle>Larmprioritet</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {order.service.alarm_priority
                ? alarmPriorityToString(order.service.alarm_priority)
                : "Kunde inte hämtas"}
            </EuiDescriptionListDescription>
          </>
        )}
      </EuiDescriptionList>
    </EuiFlexItem>{" "}
    <EuiFlexItem>
      <EuiText>
        <h3>A-sida</h3>
      </EuiText>
      <EuiDescriptionList>
        <EuiDescriptionListTitle>Platsnamn</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.service.a_location_name || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Adress</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.service.a_address || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Avlämning</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.service.a_demarcation || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Fastighetsbeteckning</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.service.a_property_designation || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Mätresultat A</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          <EuiText size="s" style={{ whiteSpace: "pre-wrap" }}>
            <p>{order.service.a_to_b_measurement || "-"}</p>
          </EuiText>
        </EuiDescriptionListDescription>
        {isAdmin(authState) && (
          <>
            <EuiDescriptionListTitle>
              Switchport för trafikgraf
            </EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <NetmonCoreInterfaceDisplay
                iface={order.service.a_graph_interface}
                placeholder="-"
              />
            </EuiDescriptionListDescription>
          </>
        )}
        <EuiSpacer />
        <EuiText>
          <h3>B-sida</h3>
        </EuiText>
        <EuiDescriptionListTitle>Platsnamn</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.service.b_location_name || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Adress</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.service.b_address || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Avlämning</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.service.b_demarcation || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Fastighetsbeteckning</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {order.service.b_property_designation || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Mätresultat B</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          <EuiText size="s" style={{ whiteSpace: "pre-wrap" }}>
            <p>{order.service.b_to_a_measurement || "-"}</p>
          </EuiText>
        </EuiDescriptionListDescription>
      </EuiDescriptionList>
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default OrderDescription;
