import {
  EuiBreadcrumb,
  EuiCallOut,
  EuiDelayRender,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
} from "@elastic/eui";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOrderAddService } from "../../../api/resources/order";
import { useOrganizationService } from "../../../api/resources/organization";
import { Service } from "../../../api/resources/service";
import { AuthStateAuthed } from "../../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../../api/useService";
import Page from "../../../Page";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import useBreadcrumb from "../../../utils/useBreadcrumb";
import useUserPreferences from "../../../utils/useUserPreferences";
import ServiceDescription from "../../../widgets/ServiceDescription";
import { orderHref } from "../view/OrderPage";
import { Fields, OrderCreateChangeForm } from "./OrderCreateChangeForm";

interface Props {
  authState: AuthStateAuthed;
  service: Service;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

export function OrderCreateChangePage({
  authState,
  service,
  onBreadcrumbs,
}: Props) {
  const [addService, addOrder] = useOrderAddService(authState);
  const [userPreferences, updateUserPreferences] = useUserPreferences();
  const organizationService = useOrganizationService(
    authState,
    service.organization_id
  );

  useBreadcrumb(onBreadcrumbs, "Beställ ändring");

  const onSubmit = useCallback(
    async (fields: Fields) => {
      await addOrder({
        type: "change",
        service_id: service.id,
        assigned_user_id:
          fields.assignedUserId === ""
            ? null
            : parseInt(fields.assignedUserId, 10),
        customer_ref: fields.customerRef,
        customer_message: fields.customerMessage,
        requested_delivery_date:
          fields.requestedDeliveryDate &&
          fields.requestedDeliveryDate.format("YYYY-MM-DD"),
        technical_contact: fields.technicalContact,
        updates_email: fields.updatesEmail || "",
        send_update_email: fields.sendUpdateEmail,
      });
    },
    [addOrder, service.id]
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoaded(addService)) {
      navigate(orderHref(addService.payload.id), { replace: true });
    }
  }, [addService, navigate]);

  return (
    <Page title="Beställ ändring">
      {isError(addService) && (
        <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
          <ServiceErrorMessage error={addService} />
        </EuiCallOut>
      )}
      {isError(organizationService) && (
        <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
          <ServiceErrorMessage error={organizationService} />
        </EuiCallOut>
      )}
      <EuiFlexGroup>
        <EuiFlexItem>
          {isLoaded(organizationService) && (
            <OrderCreateChangeForm
              authState={authState}
              organization={organizationService.payload}
              orderAddService={addService}
              onSubmit={onSubmit}
              userPreferences={userPreferences}
              updateUserPreferences={updateUserPreferences}
            />
          )}
          {isLoading(organizationService) && (
            <EuiDelayRender>
              <EuiLoadingSpinner size="l" />
            </EuiDelayRender>
          )}
        </EuiFlexItem>
        <EuiFlexItem>
          <ServiceDescription authState={authState} service={service} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </Page>
  );
}

export default OrderCreateChangePage;
