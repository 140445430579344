import { euiDragDropReorder } from "@elastic/eui";
import { useCallback, useMemo } from "react";
import { ColumnPreferences } from "../utils/useUserPreferences";
import { TableColumn } from "../widgets/Table";
import useTablePreferences from "./useTablePreferences";

export type TableColumnConfed<Item> = TableColumn<Item> & ConfigurableFields;

interface ConfigurableFields {
  isVisible: boolean;
}

export function useConfiguredColumns<Item>(
  tableId: string,
  columns: readonly TableColumn<Item>[]
) {
  //  const [colsPrefs, setColsPrefs] = useTableColsPreferences(tableId);
  const [tablePrefs, setTablePrefs] = useTablePreferences(tableId);
  // const colsPrefs = tablePrefs.columns;

  const configuredColumns = useMemo((): TableColumnConfed<Item>[] => {
    const confedColsWithSortKey = columns.map((col, colIdx) => {
      // Get preferences for this column, if any
      const prefsIdx = tablePrefs.columns.findIndex(
        (prefs) => prefs.field === col.field
      );

      let sortKey: number;
      let isVisible: boolean;
      if (prefsIdx === -1) {
        // No preferences. Fallback to sort in the original column order.
        sortKey = colIdx;
        isVisible = col.hideByDefault !== true;
      } else {
        sortKey = prefsIdx;
        isVisible = (tablePrefs.columns[prefsIdx] as ColumnPreferences)
          .isVisible;
      }

      let confedCol: TableColumnConfed<Item> = { ...col, isVisible };

      return [sortKey, confedCol] as const;
    });

    confedColsWithSortKey.sort(([aOrd], [bOrd]) => aOrd - bOrd);

    // Remove the no longer used sort key
    const confedCols = confedColsWithSortKey.map(([_, col]) => col);

    return confedCols;
  }, [columns, tablePrefs.columns]);

  const setConfiguredColumns = useCallback(
    (columns: TableColumnConfed<Item>[]) => {
      const newCols = columns.map((col) => ({
        field: col.field,
        isVisible: col.isVisible,
      }));
      setTablePrefs({ columns: newCols });
    },
    [setTablePrefs]
  );

  const setConfiguredColumn = useCallback(
    (next: TableColumnConfed<Item>) => {
      setConfiguredColumns(
        configuredColumns.map((confedCol) =>
          next.field === confedCol.field ? next : confedCol
        )
      );
    },
    [configuredColumns, setConfiguredColumns]
  );

  const reorderConfiguredColumn = useCallback(
    (startIndex: number, endIndex: number) => {
      setConfiguredColumns(
        euiDragDropReorder(configuredColumns, startIndex, endIndex)
      );
    },
    [configuredColumns, setConfiguredColumns]
  );

  return {
    configuredColumns,
    setConfiguredColumns,
    setConfiguredColumn,
    reorderConfiguredColumn,
  } as const;
}

export default useConfiguredColumns;
