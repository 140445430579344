import { EuiBreadcrumb, EuiDelayRender, EuiLoadingSpinner } from "@elastic/eui";
import React, { lazy, Suspense } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { useServiceService } from "../../api/resources/service";
import { AuthStateAuthed } from "../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../api/useService";
import Page from "../../Page";
import { ServiceErrorMessage } from "../../utils/ErrorMessage";
import useBreadcrumb from "../../utils/useBreadcrumb";
import AdminOrNotFoundPage from "../AdminOrNotFoundPage";
import ErrorCaseCreatePage from "../error-cases/create/ErrorCaseCreatePage";
import NotFoundPage from "../NotFoundPage";
import OrderCreateChangePage from "../orders/create-change/OrderCreateChangePage";
import OrderCreateEndPage from "../orders/create-end/OrderCreateEndPage";
import ServiceCreatePage from "./create/ServiceCreatePage";
import ServiceEditPage from "./edit/ServiceEditPage";
import ServicesPage from "./list/ServicesPage";
import ServiceNotFoundPage from "./ServiceNotFoundPage";
import ServicePage from "./view/ServicePage";

const CreateReportPage = lazy(() => import("./createReport/CreateReportPage"));

interface Props {
  authState: AuthStateAuthed;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

export function ServicesRouter({ authState, onBreadcrumbs }: Props) {
  const setChildBreadcrumbs = useBreadcrumb(onBreadcrumbs, "Förbindelser");

  return (
    <Routes>
      <Route
        path="ny"
        element={
          <AdminOrNotFoundPage authState={authState}>
            {(authState) => (
              <ServiceCreatePage
                authState={authState}
                onBreadcrumbs={setChildBreadcrumbs}
              />
            )}
          </AdminOrNotFoundPage>
        }
      />
      <Route
        path=":serviceId/*"
        element={
          <ItemRouter
            authState={authState}
            onBreadcrumbs={setChildBreadcrumbs}
          />
        }
      />
      <Route index element={<ServicesPage authState={authState} />} />
    </Routes>
  );
}

interface ItemRouterProps {
  authState: AuthStateAuthed;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

function ItemRouter({ authState, onBreadcrumbs }: ItemRouterProps) {
  const params = useParams<"serviceId">();
  if (params.serviceId === undefined) {
    throw Error("Missing serviceId parameter in path");
  }
  const serviceId = parseInt(params.serviceId, 10);
  const serviceService = useServiceService(authState, serviceId);

  const setChildBreadcrumbs = useBreadcrumb(
    onBreadcrumbs,
    isLoaded(serviceService) ? serviceService.payload.number : undefined
  );

  if (isError(serviceService)) {
    if (serviceService.httpStatus === 404) {
      return <ServiceNotFoundPage />;
    } else {
      return (
        <Page title="Förbindelse gick inte att hämta">
          <ServiceErrorMessage error={serviceService} />
        </Page>
      );
    }
  }

  if (isLoading(serviceService)) {
    return (
      <EuiDelayRender>
        <Page title="Hämtar förbindelse...">
          <EuiLoadingSpinner />
        </Page>
      </EuiDelayRender>
    );
  }

  const service = serviceService.payload;

  return (
    <Routes>
      <Route
        path="säg-upp"
        element={
          <OrderCreateEndPage
            authState={authState}
            service={service}
            onBreadcrumbs={setChildBreadcrumbs}
          />
        }
      />
      <Route
        path="felanmäl"
        element={
          <ErrorCaseCreatePage
            authState={authState}
            service={service}
            onBreadcrumbs={setChildBreadcrumbs}
          />
        }
      />
      <Route
        path="beställ-ändring"
        element={
          <OrderCreateChangePage
            authState={authState}
            service={service}
            onBreadcrumbs={setChildBreadcrumbs}
          />
        }
      />
      <Route
        path="ändra"
        element={
          <AdminOrNotFoundPage authState={authState}>
            {(authState) => (
              <ServiceEditPage
                authState={authState}
                service={service}
                onBreadcrumbs={setChildBreadcrumbs}
              />
            )}
          </AdminOrNotFoundPage>
        }
      />
      <Route
        path="skapa-rapport"
        element={
          <Suspense
            fallback={
              <Page title="Skapa rapport">
                <EuiDelayRender>
                  <EuiLoadingSpinner size="xxl" />
                </EuiDelayRender>
              </Page>
            }
          >
            <CreateReportPage
              service={service}
              onBreadcrumbs={setChildBreadcrumbs}
            />
          </Suspense>
        }
      />
      <Route
        index
        element={<ServicePage authState={authState} service={service} />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default ServicesRouter;
