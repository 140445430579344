import { AuthStateAuthed } from "../useAuthService";
import { buildApiHref, useAutoGetService } from "../useService";

export const statisticsApiHref = (search?: {
  organizationId?: number;
  serviceId?: number;
}) =>
  buildApiHref(
    "statistics",
    search && {
      ...(search.organizationId && {
        organization_id: search.organizationId.toString(),
      }),
      ...(search.serviceId && {
        service_id: search.serviceId.toString(),
      }),
    }
  );

interface ServicesStats {
  active: number;
  inactive: number;
}

interface ErrorCasesStats {
  unassigned: number;
  open: number;
  closed: number;
}

interface OrdersStats {
  unassigned: number;
  sent: number;
  "canceling-sent": number;
  "canceling-delivery": number;
  delivering: number;
  "needs-tender": number;
  tendered: number;
  delivered: number;
  "delivery-not-possible": number;
}

interface AuthedUserStats {
  assigned_active_orders: number;
  assigned_open_error_cases: number;
}

export interface Statistics {
  services: ServicesStats;
  orders: OrdersStats;
  error_cases: ErrorCasesStats;
  authed_user: AuthedUserStats;
}

export const useStatisticsService = (
  authState: AuthStateAuthed,
  search?: {
    organizationId?: number;
  }
) => useAutoGetService<Statistics>(authState, statisticsApiHref(search));
