import { EuiButton } from "@elastic/eui";
import React, { ReactElement, useMemo } from "react";
import { AuthStateAuthed } from "../../../api/useAuthService";
import Page from "../../../Page";
import RouterLinkAdapter from "../../../utils/RouterLinkAdapter";
import OrdersTable from "./OrdersTable";

interface Props {
  authState: AuthStateAuthed;
}

export function OrdersPage({ authState }: Props) {
  const actions = useMemo(() => {
    const actions: ReactElement[] = [];

    if (authState.user.orderPrivileges === "manage") {
      actions.push(
        <RouterLinkAdapter to="ny">
          {(linkProps) => (
            <EuiButton iconType="plus" fill {...linkProps}>
              Skapa ny order
            </EuiButton>
          )}
        </RouterLinkAdapter>
      );
    }

    return actions;
  }, [authState.user.orderPrivileges]);

  return (
    <Page title="Order" actionItems={actions}>
      <OrdersTable authState={authState} />
    </Page>
  );
}

export default OrdersPage;
