import React from "react";
import { ServiceError } from "../api/fetchFromApi";

export interface ErrorInfo {
  id: string;
  track: string | null;
  values?: object;
}

export interface ErrorMessageProps {
  error?: ErrorInfo;
}

export const ErrorMessage = ({ error }: ErrorMessageProps) => (
  <div className="my-2">
    {error && (
      <span className="text-danger">
        {error.id}
        {error.track && ` (track ${error.track})`}
      </span>
    )}
  </div>
);

interface ServiceErrorMessageProps {
  error: ServiceError;
}

export const ServiceErrorMessage = ({ error }: ServiceErrorMessageProps) => (
  <ErrorMessage error={{ id: error.message, track: error.track }} />
);

export default ErrorMessage;
