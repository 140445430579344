import {
  EuiAvatar,
  EuiBreadcrumb,
  EuiDelayRender,
  EuiDescriptionList,
  EuiDescriptionListDescription,
  EuiDescriptionListTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import React from "react";
import { useOrganizationService } from "../../../api/resources/organization";
import { AuthStateAuthed } from "../../../api/useAuthService";
import { isLoaded, isLoading } from "../../../api/useService";
import Page from "../../../Page";
import useBreadcrumb from "../../../utils/useBreadcrumb";
import TrustedDevices from "./TrustedDevices";

interface Props {
  authState: AuthStateAuthed;
  onBreadcrumbs(breadcrumbs: EuiBreadcrumb[]): void;
}

export function UserPage({ authState, onBreadcrumbs }: Props) {
  const orgService = useOrganizationService(
    authState,
    authState.user.organizationId
  );
  useBreadcrumb(onBreadcrumbs, "Kontouppgifter");

  return (
    <Page title="Kontouppgifter">
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiAvatar name={authState.user.fullName} size="xl" />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <h1>{authState.user.fullName}</h1>
          </EuiText>
          <EuiSpacer />
          <EuiDescriptionList>
            <EuiDescriptionListTitle>Organisation</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {(isLoaded(orgService) && orgService.payload.name) || "-"}
              {isLoading(orgService) && (
                <EuiDelayRender>
                  <EuiLoadingSpinner size="s" />
                </EuiDelayRender>
              )}
            </EuiDescriptionListDescription>
            <EuiDescriptionListTitle>Telefon</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {authState.user.phone || "-"}
            </EuiDescriptionListDescription>
            <EuiDescriptionListTitle>Mejladress</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {authState.user.email || "-"}
            </EuiDescriptionListDescription>
          </EuiDescriptionList>
          <EuiSpacer />
          <TrustedDevices authState={authState} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </Page>
  );
}

export default UserPage;
