import React from "react";
import {
  AuthStateAdmin,
  AuthStateAuthed,
  isAdmin,
} from "../api/useAuthService";
import NotFoundPage from "./NotFoundPage";

interface Props {
  authState: AuthStateAuthed;
  children(authState: AuthStateAdmin): JSX.Element;
}

export const AdminOrNotFoundPage = ({ authState, children }: Props) =>
  isAdmin(authState) ? children(authState as AuthStateAdmin) : <NotFoundPage />;

export default AdminOrNotFoundPage;
