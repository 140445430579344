import { EuiButtonEmpty } from "@elastic/eui";
import React, { useState } from "react";
import { NetmonCoreInterface } from "../api/resources/netmon_core_interface";
import { AuthStateAdmin } from "../api/useAuthService";
import NetmonCoreInterfaceDisplay from "./NetmonCoreInterfaceDisplay";
import NetmonCoreInterfaceSelectFlyout from "./NetmonCoreInterfaceSelectFlyout";

interface Props {
  authState: AuthStateAdmin;
  selectedInterface: NetmonCoreInterface | null;
  onSelect(iface: NetmonCoreInterface): void;
  onSelectNone: () => void;
}

export function NetmonCoreInterfaceSelect({
  authState,
  selectedInterface,
  onSelect,
  onSelectNone,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <EuiButtonEmpty
        iconType="pencil"
        iconSide="right"
        onClick={() => setIsOpen(true)}
        flush="both"
      >
        <NetmonCoreInterfaceDisplay
          iface={selectedInterface}
          placeholder="Ingen"
        />
      </EuiButtonEmpty>
      {isOpen && (
        <NetmonCoreInterfaceSelectFlyout
          authState={authState}
          onSelect={onSelect}
          onSelectNone={onSelectNone}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
}

export default NetmonCoreInterfaceSelect;
