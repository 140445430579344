import { EuiCallOut, EuiLink } from "@elastic/eui";
import React from "react";
import Page from "../../Page";
import RouterLinkAdapter from "../../utils/RouterLinkAdapter";

export const OrganizationNotFoundPage = () => (
  <Page title="Organisation">
    <EuiCallOut
      title="Organisationen finns inte"
      color="danger"
      iconType="alert"
    >
      <p>Det gick inte att hitta denna organisation.</p>
      <RouterLinkAdapter to="/organisationer">
        {(linkProps) => (
          <EuiLink color="warning" {...linkProps}>
            Visa alla organisationer
          </EuiLink>
        )}
      </RouterLinkAdapter>
    </EuiCallOut>
  </Page>
);

export default OrganizationNotFoundPage;
