import {
  EuiCallOut,
  EuiDescriptionList,
  EuiDescriptionListDescription,
  EuiDescriptionListTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiSkeletonText,
  EuiText,
} from "@elastic/eui";
import React from "react";
import { Organization } from "../../../api/resources/organization";
import { useStatisticsService } from "../../../api/resources/statistics";
import { AuthStateAuthed } from "../../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../../api/useService";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import RouterLinkAdapter from "../../../utils/RouterLinkAdapter";
import { errorCaseStatusToString } from "../../error-cases/view/ErrorCasePage";
import { orderStatusToString } from "../../orders/order-utils";
import { serviceStatusToString } from "../../services/service-utils";

interface Props {
  authState: AuthStateAuthed;
  organization: Organization;
}

export const OrganizationDescription = ({ authState, organization }: Props) => (
  <EuiFlexGroup>
    <EuiFlexItem>
      <EuiDescriptionList>
        <EuiDescriptionListTitle>Kundnummer</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {organization.customer_number}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Nummerprefix</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {organization.number_prefix}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Kontaktuppgifter</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          <EuiText size="s" style={{ whiteSpace: "pre-wrap" }}>
            <p>{organization.contact_details || "-"}</p>
          </EuiText>
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Mejl för orderstatus</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {organization.order_emails.length
            ? organization.order_emails.map((email, index) => (
                <div key={index}>{email}</div>
              ))
            : "Saknas"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>
          Mejl för felärendestatus
        </EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {organization.error_case_emails.length
            ? organization.error_case_emails.map((email, index) => (
                <div key={index}>{email}</div>
              ))
            : "Saknas"}
        </EuiDescriptionListDescription>
      </EuiDescriptionList>
    </EuiFlexItem>
    <EuiFlexItem>
      <StatisticsColumn authState={authState} organization={organization} />
    </EuiFlexItem>
  </EuiFlexGroup>
);

interface StatisticsColumnProps {
  authState: AuthStateAuthed;
  organization: Organization;
}

function StatisticsColumn({ authState, organization }: StatisticsColumnProps) {
  const statsService = useStatisticsService(authState, {
    organizationId: organization.id,
  });

  if (isError(statsService)) {
    return (
      <EuiCallOut
        title="Kunde inte hämta statistik"
        color="danger"
        iconType="alert"
      >
        <ServiceErrorMessage error={statsService} />
      </EuiCallOut>
    );
  }

  return (
    <EuiDescriptionList>
      <EuiDescriptionListTitle>Förbindelser</EuiDescriptionListTitle>
      {(["active", "inactive"] as const).map((status) => (
        <LinkedDescription
          key={status}
          isLoading={isLoading(statsService)}
          to={`/förbindelser?organization_id=${organization.id}&status=${status}`}
        >
          {isLoaded(statsService) ? statsService.payload.services[status] : "…"}
          {" " + serviceStatusToString(status).toLocaleLowerCase()}
        </LinkedDescription>
      ))}
      <EuiDescriptionListTitle>Order</EuiDescriptionListTitle>
      <LinkedDescription
        isLoading={isLoading(statsService)}
        to={`/order?organization_id=${organization.id}&assigned_user_id=null`}
      >
        {isLoaded(statsService) ? statsService.payload.orders.unassigned : "…"}
        {" saknar ansvarig"}
      </LinkedDescription>
      {(
        [
          "sent",
          "canceling-sent",
          "needs-tender",
          "tendered",
          "delivering",
          "canceling-delivery",
          "delivered",
          "delivery-not-possible",
        ] as const
      ).map((status) => (
        <LinkedDescription
          key={status}
          isLoading={isLoading(statsService)}
          to={`/order?organization_id=${organization.id}&status=${status}`}
        >
          {isLoaded(statsService) ? statsService.payload.orders[status] : "…"}
          {" " + orderStatusToString(status).toLocaleLowerCase()}
        </LinkedDescription>
      ))}
      <EuiDescriptionListTitle>Felärenden</EuiDescriptionListTitle>
      <LinkedDescription
        to={`/felärenden?organization_id=${organization.id}&assigned_user_id=null`}
        isLoading={isLoading(statsService)}
      >
        {isLoaded(statsService)
          ? statsService.payload.error_cases.unassigned
          : "…"}
        {" saknar ansvarig"}
      </LinkedDescription>
      {(["open", "closed"] as const).map((status) => (
        <LinkedDescription
          to={`/felärenden?organization_id=${organization.id}&status=${status}`}
          isLoading={isLoading(statsService)}
        >
          {isLoaded(statsService)
            ? statsService.payload.error_cases[status]
            : "…"}
          {" " + errorCaseStatusToString(status).toLocaleLowerCase()}
        </LinkedDescription>
      ))}
    </EuiDescriptionList>
  );
}

interface LinkedDescriptionProps {
  isLoading: boolean;
  to: string;
  children: React.ReactNode;
}

const LinkedDescription = ({
  isLoading,
  to,
  children,
}: LinkedDescriptionProps) => (
  <EuiDescriptionListDescription>
    {isLoading ? (
      <EuiSkeletonText lines={1} />
    ) : (
      <RouterLinkAdapter to={to}>
        {(linkProps) => <EuiLink {...linkProps}>{children}</EuiLink>}
      </RouterLinkAdapter>
    )}
  </EuiDescriptionListDescription>
);

export default OrganizationDescription;
