import { EuiBreadcrumb } from "@elastic/eui";
import { useEffect, useState } from "react";
import { useHref, useLinkClickHandler } from "react-router-dom";

/** Adds a breadcrumb to chain of breadcrumbs. Childs should add themselves
 * with the returned function.
 *
 * @param setBreadcrumbs Called when self breadcrumb changes, child bredcrumbs
 *                       changes and with empty list on unmount.
 * @param breadcrumb Bredcrumb for self
 * @returns Callback that childs should call to add their breadcrumbs.
 */
export function useBreadcrumb(
  setBreadcrumbs: (breadcrumbs: EuiBreadcrumb[]) => void,
  text?: string,
  to: string = ""
) {
  const [childBreadcrumbs, setChildBreadcrumbs] = useState<EuiBreadcrumb[]>([]);
  const href = useHref(to);

  // Add onClick handler that works with react-router
  const onClick = useLinkClickHandler(to);

  useEffect(() => {
    if (text) {
      setBreadcrumbs([
        {
          text,
          href,
          onClick,
        },
        ...childBreadcrumbs,
      ]);
    } else {
      setBreadcrumbs(childBreadcrumbs);
    }

    return () => setBreadcrumbs([]);
  }, [setBreadcrumbs, text, href, childBreadcrumbs, onClick]);

  return setChildBreadcrumbs;
}

export default useBreadcrumb;
