import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { AuthState, AuthStateAuthed } from "../api/useAuthService";

interface Props {
  authState: AuthState;
  children(authState: AuthStateAuthed): React.ReactElement;
}

export function RequireAuthed({ authState, children }: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (authState.status !== "authed") {
      navigate("/logga-in", { state: { redirect: location } });
    }
  }, [authState.status, navigate, location]);

  return authState.status === "authed" ? children(authState) : null;
}

export default RequireAuthed;
