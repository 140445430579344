import {
  EuiDescriptionList,
  EuiDescriptionListDescription,
  EuiDescriptionListTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
} from "@elastic/eui";
import React from "react";
import { Service } from "../../../api/resources/service";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import RouterLinkAdapter from "../../../utils/RouterLinkAdapter";
import NetmonCoreInterfaceDisplay from "../../../widgets/NetmonCoreInterfaceDisplay";
import { organizationHref } from "../../organizations/view/OrganizationPage";
import { alarmPriorityToString, serviceStatusToString } from "../service-utils";

interface Props {
  authState: AuthStateAuthed;
  service: Service;
}

export const ServiceDescription = ({ authState, service }: Props) => (
  <EuiFlexGroup>
    <EuiFlexItem>
      <EuiText>
        <h3>Förbindelseinfo</h3>
      </EuiText>
      <EuiDescriptionList>
        {isAdmin(authState) && (
          <>
            <EuiDescriptionListTitle>Organisation</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <RouterLinkAdapter to={organizationHref(service.organization_id)}>
                {(linkProps) => (
                  <EuiLink {...linkProps}>{service.organization_name}</EuiLink>
                )}
              </RouterLinkAdapter>
            </EuiDescriptionListDescription>
          </>
        )}
        <EuiDescriptionListTitle>Förbindelsenummer</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {service.number}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Förbindelsereferens</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {service.customer_ref || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Status</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {serviceStatusToString(service.status)}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Produkt</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {service.product_name || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Kapacitet</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {service.product_capacity || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Teknik</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {service.product_technology || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Startdatum</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {service.delivery_date || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Slutdatum</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {service.termination_date || "-"}
        </EuiDescriptionListDescription>
        {isAdmin(authState) && (
          <>
            <EuiDescriptionListTitle>
              Intern information
            </EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <EuiText size="s" style={{ whiteSpace: "pre-wrap" }}>
                <p>{service.internal_note || "-"}</p>
              </EuiText>
            </EuiDescriptionListDescription>
            <EuiDescriptionListTitle>Larmprioritet</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {service.alarm_priority
                ? alarmPriorityToString(service.alarm_priority)
                : "Kunde inte hämtas"}
            </EuiDescriptionListDescription>
          </>
        )}
      </EuiDescriptionList>
    </EuiFlexItem>
    <EuiFlexItem>
      <EuiText>
        <h3>A-sida</h3>
      </EuiText>
      <EuiDescriptionList>
        <EuiDescriptionListTitle>Platsnamn</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {service.a_location_name || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Adress</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {service.a_address || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Avlämning</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {service.a_demarcation || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Fastighetsbeteckning</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {service.a_property_designation || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Mätresultat A</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          <EuiText size="s" style={{ whiteSpace: "pre-wrap" }}>
            <p>{service.a_to_b_measurement || "-"}</p>
          </EuiText>
        </EuiDescriptionListDescription>
        {isAdmin(authState) && (
          <>
            <EuiDescriptionListTitle>
              Switchport för trafikgraf
            </EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <NetmonCoreInterfaceDisplay
                iface={service.a_graph_interface}
                placeholder="-"
              />
            </EuiDescriptionListDescription>
          </>
        )}
      </EuiDescriptionList>
    </EuiFlexItem>
    <EuiFlexItem>
      <EuiText>
        <h3>B-sida</h3>
      </EuiText>
      <EuiDescriptionList>
        <EuiDescriptionListTitle>Platsnamn</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {service.b_location_name || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Adress</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {service.b_address || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Avlämning</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {service.b_demarcation || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Fastighetsbeteckning</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {service.b_property_designation || "-"}
        </EuiDescriptionListDescription>
        <EuiDescriptionListTitle>Mätresultat B</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          <EuiText size="s" style={{ whiteSpace: "pre-wrap" }}>
            <p>{service.b_to_a_measurement || "-"}</p>
          </EuiText>
        </EuiDescriptionListDescription>
      </EuiDescriptionList>
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default ServiceDescription;
