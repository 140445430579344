import {
  EuiButton,
  EuiCallOut,
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTextArea,
} from "@elastic/eui";
import emailValidator from "email-validator";
import { FormikErrors, FormikProps, withFormik } from "formik";
import moment, { Moment } from "moment";
import "moment/locale/sv";
import React from "react";
import { NetmonCoreInterface } from "../../../api/resources/netmon_core_interface";
import { OrderAddService } from "../../../api/resources/order";
import { Organization } from "../../../api/resources/organization";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import DefaultOrTextRow from "../../../utils/form/DefaultOrTextRow";
import SelectUserRow from "../../../utils/form/SelectUserRow";
import propertyOf from "../../../utils/propertyOf";
import { UserPreferences } from "../../../utils/useUserPreferences";
import NetmonCoreInterfaceSelect from "../../../widgets/NetmonCoreInterfaceSelect";
import OrganizationSelect from "../../../widgets/OrganizationSelect";

export interface Fields {
  organization: Organization | null;
  serviceCustomerRef: string;
  customerRef: string;
  customerMessage: string;
  requestedDeliveryDate: Moment | null;
  aGraphInterface: NetmonCoreInterface | null;
  monitorAGraphInterface: boolean;
  aLocationName: string;
  aAddress: string;
  aPropertyDesignation: string;
  aToBMeasurement: string;
  bLocationName: string;
  bAddress: string;
  bPropertyDesignation: string;
  bToAMeasurement: string;
  technicalContact: string;
  propertyOwnerContact: string;
  updatesEmail: string | null;
  sendUpdateEmail: boolean;
  assignedUserId: string;
}

type InnerFormProps = {
  authState: AuthStateAuthed;
} & FormikProps<Fields>;

const InnerForm = ({
  authState,
  values,
  errors,
  touched,
  handleSubmit,
  isSubmitting,
  getFieldProps,
  setFieldValue,
  setFieldTouched,
}: InnerFormProps) => (
  <EuiForm component="form" onSubmit={handleSubmit}>
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiText>
          <h3>Orderinfo</h3>
        </EuiText>
        {isAdmin(authState) && (
          <EuiFormRow
            label="Organisation"
            isInvalid={!!touched.organization && !!errors.organization}
            error={errors.organization}
          >
            <OrganizationSelect
              authState={authState}
              value={values.organization}
              onChange={(organization) =>
                setFieldValue(propertyOf<Fields>("organization"), organization)
              }
            />
          </EuiFormRow>
        )}
        <EuiFormRow
          label="Orderreferens"
          helpText="Fyll i om ni vill ha en egen referens på ordern."
          isInvalid={!!touched.customerRef && !!errors.customerRef}
          error={errors.customerRef}
        >
          <EuiFieldText {...getFieldProps<string>("customerRef")} />
        </EuiFormRow>
        <EuiFormRow
          label="Förbindelsereferens"
          helpText="Fyll i om ni vill ha en egen referens på förbindelsen."
          isInvalid={
            !!touched.serviceCustomerRef && !!errors.serviceCustomerRef
          }
          error={errors.serviceCustomerRef}
        >
          <EuiFieldText {...getFieldProps<string>("serviceCustomerRef")} />
        </EuiFormRow>
        <EuiFormRow
          label="Önskat leveransdatum"
          helpText="Lämna tomt för snarast."
          isInvalid={
            !!touched.requestedDeliveryDate && !!errors.requestedDeliveryDate
          }
          error={errors.requestedDeliveryDate}
        >
          <EuiDatePicker
            selected={values.requestedDeliveryDate}
            onClear={() => setFieldValue("requestedDeliveryDate", null)}
            onChange={(v) => setFieldValue("requestedDeliveryDate", v)}
            onBlur={() => setFieldTouched("requestedDeliveryDate", true)}
            dateFormat="YYYY-MM-DD"
            minDate={moment()}
          />
        </EuiFormRow>
        <EuiFormRow
          label="Teknisk kontakt"
          helpText="Gärna namn, tel.nr, mejl."
          isInvalid={!!touched.technicalContact && !!errors.technicalContact}
          error={errors.technicalContact}
        >
          <EuiFieldText {...getFieldProps<string>("technicalContact")} />
        </EuiFormRow>
        <EuiFormRow
          label="Kontakt fastighetsägare"
          helpText="Gärna namn, tel.nr, mejl."
          isInvalid={
            !!touched.propertyOwnerContact && !!errors.propertyOwnerContact
          }
          error={errors.propertyOwnerContact}
        >
          <EuiFieldText {...getFieldProps<string>("propertyOwnerContact")} />
        </EuiFormRow>
        <DefaultOrTextRow
          name={propertyOf<Fields>("updatesEmail")}
          label="Mejladress för statusuppdateringar"
          labelDefault={`Organisationens mejl: ${
            values.organization?.order_emails.join(", ") || "saknas"
          }`}
          labelSpecify="Angiven:"
          inputMode="email"
        />
        {isAdmin(authState) && (
          <EuiFormRow
            isInvalid={!!touched.sendUpdateEmail && !!errors.sendUpdateEmail}
            error={errors.sendUpdateEmail}
          >
            <EuiSwitch
              label="Skicka orderbekräftelse"
              checked={values.sendUpdateEmail}
              onChange={(e) =>
                setFieldValue("sendUpdateEmail", e.target.checked)
              }
            />
          </EuiFormRow>
        )}
        <EuiFormRow
          label="Beskrivning"
          helpText="Beskriv vad ni vill beställa. Här kan ni även lämna ytterligare information."
          isInvalid={!!touched.customerMessage && !!errors.customerMessage}
          error={errors.customerMessage}
        >
          <EuiTextArea {...getFieldProps<string>("customerMessage")} />
        </EuiFormRow>
        {isAdmin(authState) && (
          <SelectUserRow
            authState={authState}
            label="Ansvarig"
            name={propertyOf<Fields>("assignedUserId")}
            defaultUserId={authState.user.id}
          />
        )}
      </EuiFlexItem>

      <EuiFlexItem>
        <EuiText>
          <h3>A-sida</h3>
        </EuiText>
        <EuiFormRow
          label="Platsnamn"
          helpText="T.ex. Småskolan, Vattentornet eller Nod 1234."
          isInvalid={!!touched.aLocationName && !!errors.aLocationName}
          error={errors.aLocationName}
        >
          <EuiFieldText {...getFieldProps<string>("aLocationName")} />
        </EuiFormRow>
        <EuiFormRow
          label="Adress"
          helpText="T.ex. Skolstigen 2C Lgh 1003, 234 56 Enköping."
          isInvalid={!!touched.aAddress && !!errors.aAddress}
          error={errors.aAddress}
        >
          <EuiFieldText {...getFieldProps<string>("aAddress")} />
        </EuiFormRow>
        <EuiFormRow
          label="Fastighetsbeteckning"
          isInvalid={
            !!touched.aPropertyDesignation && !!errors.aPropertyDesignation
          }
          error={errors.aPropertyDesignation}
        >
          <EuiFieldText {...getFieldProps<string>("aPropertyDesignation")} />
        </EuiFormRow>
        <EuiFormRow
          label="Mätresultat A"
          isInvalid={!!touched.aToBMeasurement && !!errors.aToBMeasurement}
          error={errors.aToBMeasurement}
        >
          <EuiTextArea
            rows={2}
            {...getFieldProps<string>(propertyOf<Fields>("aToBMeasurement"))}
          />
        </EuiFormRow>
        {isAdmin(authState) && (
          <>
            <EuiFormRow
              label="Switchport för trafikgraf"
              isInvalid={!!errors.aGraphInterface}
              error={errors.aGraphInterface}
              fullWidth
            >
              <NetmonCoreInterfaceSelect
                authState={authState}
                selectedInterface={values.aGraphInterface}
                onSelect={(iface) =>
                  setFieldValue(propertyOf<Fields>("aGraphInterface"), iface)
                }
                onSelectNone={() =>
                  setFieldValue(propertyOf<Fields>("aGraphInterface"), null)
                }
              />
            </EuiFormRow>
            <EuiFormRow
              isInvalid={
                !!touched.monitorAGraphInterface &&
                !!errors.monitorAGraphInterface
              }
              error={errors.monitorAGraphInterface}
            >
              <EuiSwitch
                disabled={!values.aGraphInterface}
                label="Lägg till larmövervakning för porten"
                checked={values.monitorAGraphInterface}
                onChange={(e) => {
                  setFieldValue("monitorAGraphInterface", e.target.checked);
                }}
              />
            </EuiFormRow>
          </>
        )}
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText>
          <h3>B-sida</h3>
        </EuiText>
        <EuiFormRow
          label="Platsnamn"
          helpText="T.ex. Småskolan, Vattentornet eller Nod 1234."
          isInvalid={!!touched.bLocationName && !!errors.bLocationName}
          error={errors.bLocationName}
        >
          <EuiFieldText {...getFieldProps<string>("bLocationName")} />
        </EuiFormRow>
        <EuiFormRow
          label="Adress"
          helpText="T.ex. Skolstigen 2C Lgh 1003, 234 56 Enköping."
          isInvalid={!!touched.bAddress && !!errors.bAddress}
          error={errors.bAddress}
        >
          <EuiFieldText {...getFieldProps<string>("bAddress")} />
        </EuiFormRow>
        <EuiFormRow
          label="Fastighetsbeteckning"
          isInvalid={
            !!touched.bPropertyDesignation && !!errors.bPropertyDesignation
          }
          error={errors.bPropertyDesignation}
        >
          <EuiFieldText {...getFieldProps<string>("bPropertyDesignation")} />
        </EuiFormRow>
        <EuiFormRow
          label="Mätresultat B"
          isInvalid={!!touched.bToAMeasurement && !!errors.bToAMeasurement}
          error={errors.bToAMeasurement}
        >
          <EuiTextArea
            rows={2}
            {...getFieldProps<string>(propertyOf<Fields>("bToAMeasurement"))}
          />
        </EuiFormRow>
        <EuiFormRow>
          <EuiCallOut
            iconType="help"
            title="B-sida behövs bara för produkter med två sidor."
            size="s"
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>

    <EuiSpacer />

    <EuiButton type="submit" fill isLoading={isSubmitting}>
      Skicka
    </EuiButton>
  </EuiForm>
);

interface Props {
  onSubmit: (params: Fields) => Promise<unknown>;
  orderAddService: OrderAddService;
  authState: AuthStateAuthed;
  userPreferences: UserPreferences;
  updateUserPreferences(values: Partial<UserPreferences>): void;
}

export const OrderCreateNewForm = withFormik<Props, Fields>({
  displayName: "OrderCreateNewForm",
  mapPropsToValues: ({ authState, userPreferences }) => ({
    organization: null,
    serviceCustomerRef: "",
    customerRef: "",
    customerMessage: "",
    requestedDeliveryDate: null,
    aGraphInterface: null,
    monitorAGraphInterface: isAdmin(authState),
    aLocationName: "",
    aAddress: "",
    aPropertyDesignation: "",
    aToBMeasurement: "",
    bLocationName: "",
    bAddress: "",
    bPropertyDesignation: "",
    bToAMeasurement: "",
    technicalContact: "",
    propertyOwnerContact: "",
    updatesEmail: null,
    sendUpdateEmail: isAdmin(authState)
      ? userPreferences.sendOrderUpdateEmail
      : true,
    assignedUserId: "",
  }),
  validate: (values, { authState }) => {
    const errors: FormikErrors<Fields> = {};

    // Only for admins. Users always use their own organization.
    if (isAdmin(authState) && !values.organization) {
      errors.organization = "Saknas";
    }

    if (values.serviceCustomerRef && values.serviceCustomerRef.length > 64) {
      const charsOver = values.serviceCustomerRef.length - 64;
      errors.serviceCustomerRef = `${charsOver} tecken för långt`;
    }

    if (values.customerRef && values.customerRef.length > 64) {
      const charsOver = values.customerRef.length - 64;
      errors.customerRef = `${charsOver} tecken för långt`;
    }

    if (!values.customerMessage) {
      errors.customerMessage = "Saknas";
    } else {
      const charsOver = values.customerMessage.length - 10240;
      if (charsOver > 0) {
        errors.customerMessage = `${charsOver} tecken för långt`;
      }
    }

    if (values.requestedDeliveryDate) {
      if (!values.requestedDeliveryDate.isValid()) {
        errors.requestedDeliveryDate = "Ogiltigt";
      } else if (values.requestedDeliveryDate.isBefore(moment(), "days")) {
        errors.requestedDeliveryDate = "Datum har redan passerat";
      }
    }

    if (values.aLocationName && values.aLocationName.length > 64) {
      const charsOver = values.aLocationName.length - 64;
      errors.aLocationName = `${charsOver} tecken för långt`;
    }

    if (values.aAddress && values.aAddress.length > 128) {
      const charsOver = values.aAddress.length - 128;
      errors.aAddress = `${charsOver} tecken för långt`;
    }

    if (
      values.aPropertyDesignation &&
      values.aPropertyDesignation.length > 128
    ) {
      const charsOver = values.aPropertyDesignation.length - 128;
      errors.aPropertyDesignation = `${charsOver} tecken för långt`;
    }

    const aToBMeasurementCharsOver = values.aToBMeasurement.length - 64;
    if (aToBMeasurementCharsOver > 0) {
      errors.aToBMeasurement = `${aToBMeasurementCharsOver} tecken för långt`;
    }

    if (values.bLocationName && values.bLocationName.length > 64) {
      const charsOver = values.bLocationName.length - 64;
      errors.bLocationName = `${charsOver} tecken för långt`;
    }

    if (values.bAddress && values.bAddress.length > 128) {
      const charsOver = values.bAddress.length - 128;
      errors.bAddress = `${charsOver} tecken för långt`;
    }

    if (
      values.bPropertyDesignation &&
      values.bPropertyDesignation.length > 128
    ) {
      const charsOver = values.bPropertyDesignation.length - 128;
      errors.bPropertyDesignation = `${charsOver} tecken för långt`;
    }

    const bToAMeasurementCharsOver = values.bToAMeasurement.length - 64;
    if (bToAMeasurementCharsOver > 0) {
      errors.bToAMeasurement = `${bToAMeasurementCharsOver} tecken för långt`;
    }

    if (values.technicalContact && values.technicalContact.length > 128) {
      const charsOver = values.technicalContact.length - 128;
      errors.technicalContact = `${charsOver} tecken för långt`;
    }

    if (
      values.propertyOwnerContact &&
      values.propertyOwnerContact.length > 128
    ) {
      const charsOver = values.propertyOwnerContact.length - 128;
      errors.propertyOwnerContact = `${charsOver} tecken för långt`;
    }

    if (values.updatesEmail !== null) {
      if (!values.updatesEmail) {
        errors.updatesEmail = "Saknas";
      } else if (values.updatesEmail.length > 128) {
        const charsOver = values.updatesEmail.length - 128;
        errors.updatesEmail = `${charsOver} tecken för långt`;
      } else if (!emailValidator.validate(values.updatesEmail)) {
        errors.updatesEmail = "Ogiltigt format";
      }
    }

    return errors;
  },
  handleSubmit: async (values, { props, setValues }) => {
    const normalized: typeof values = {
      organization: values.organization,
      serviceCustomerRef: values.serviceCustomerRef.trim(),
      customerRef: values.customerRef.trim(),
      customerMessage: values.customerMessage.trim(),
      requestedDeliveryDate: values.requestedDeliveryDate,
      aGraphInterface: values.aGraphInterface,
      monitorAGraphInterface: values.monitorAGraphInterface,
      aLocationName: values.aLocationName.trim(),
      aAddress: values.aAddress.trim(),
      aPropertyDesignation: values.aPropertyDesignation.trim(),
      aToBMeasurement: values.aToBMeasurement.trim(),
      bLocationName: values.bLocationName.trim(),
      bAddress: values.bAddress.trim(),
      bPropertyDesignation: values.bPropertyDesignation.trim(),
      bToAMeasurement: values.bToAMeasurement.trim(),
      technicalContact: values.technicalContact.trim(),
      propertyOwnerContact: values.propertyOwnerContact.trim(),
      updatesEmail: values.updatesEmail && values.updatesEmail.trim(),
      sendUpdateEmail: values.sendUpdateEmail,
      assignedUserId: values.assignedUserId,
    };

    setValues(normalized);
    props.updateUserPreferences({
      sendOrderUpdateEmail: normalized.sendUpdateEmail,
    });

    await props.onSubmit(normalized);
  },
})(InnerForm);

export default OrderCreateNewForm;
