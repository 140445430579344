import {
  EuiButton,
  EuiCallOut,
  EuiErrorBoundary,
  EuiFieldPassword,
  EuiFieldText,
  EuiFlexGroup,
  EuiForm,
  EuiFormRow,
  EuiPageTemplate,
  EuiSpacer,
} from "@elastic/eui";
import { FormikErrors, FormikProps, withFormik } from "formik";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { AuthState } from "../api/useAuthService";
import { ServiceErrorMessage } from "../utils/ErrorMessage";

interface Props {
  authState: AuthState;
  onLogin(username: string, password: string): Promise<unknown>;
}

export function Login({ authState, onLogin }: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Logga in - Portalen";
  });

  useEffect(() => {
    if (authState.status === "authed") {
      // Navigate to the requested page, if any
      navigate(location.state?.redirect || "/");
    } else if (authState.status === "require-2fa") {
      // Continue the 2fa login
      navigate("/logga-in-2fa", { state: location.state });
    } else if (authState.status === "password-expired") {
      // Navigate to password change page
      navigate("/byt-lösenord", { state: location.state });
    }
  }, [authState.status, location.state, navigate]);

  return (
    <EuiFlexGroup gutterSize="none" style={{ minHeight: "70vh" }}>
      <EuiErrorBoundary>
        <EuiPageTemplate>
          <EuiPageTemplate.EmptyPrompt>
            {authState.status === "unauthed" && authState.passwordIsChanged && (
              <EuiCallOut
                title="Lösenordet har ändrats"
                color="success"
                iconType="user"
                style={{ marginBottom: "1em" }}
              >
                <p>Du kan nu logga in med ditt nya lösenord.</p>
              </EuiCallOut>
            )}
            {authState.status === "error" &&
              authState.kind === "service-error" && (
                <EuiCallOut
                  title={<ServiceErrorMessage error={authState.serviceError} />}
                  color="danger"
                  iconType="alert"
                  style={{ marginBottom: "1em" }}
                />
              )}
            {authState.status === "error" &&
              authState.kind === "2fa-login-timeout" && (
                <EuiCallOut
                  title={"Giltighetstiden för SMS-koden har passerat."}
                  color="primary"
                  iconType="alert"
                  style={{ marginBottom: "1em" }}
                />
              )}
            <LoginForm authState={authState} onLogin={onLogin} />
          </EuiPageTemplate.EmptyPrompt>
        </EuiPageTemplate>
      </EuiErrorBoundary>
    </EuiFlexGroup>
  );
}

interface LoginFormFields {
  username: string;
  password: string;
}

type InnerLoginFormProps = {
  authState: AuthState;
} & FormikProps<LoginFormFields>;

function InnerLoginForm({
  authState,
  errors,
  touched,
  handleSubmit,
  isSubmitting,
  getFieldProps,
  setTouched,
}: InnerLoginFormProps) {
  useEffect(() => {
    if (!authState.isLoading && authState.status === "error") {
      // Setting to touched will force a revalidation and show backend errors
      setTouched({ username: true, password: true });
    }
  }, [authState.status, authState.isLoading, setTouched]);

  return (
    <EuiForm component="form" onSubmit={handleSubmit}>
      <EuiFormRow
        label="Användarnamn"
        isInvalid={!!touched.username && !!errors.username}
        error={errors.username}
      >
        <EuiFieldText
          icon="user"
          autoComplete="username"
          isInvalid={!!touched.username && !!errors.username}
          disabled={isSubmitting}
          {...getFieldProps<string>("username")}
        />
      </EuiFormRow>
      <EuiFormRow
        label="Lösenord"
        isInvalid={!!touched.password && !!errors.password}
        error={[errors.password]}
      >
        <EuiFieldPassword
          autoComplete="current-password"
          isInvalid={!!touched.password && !!errors.password}
          disabled={isSubmitting}
          type="dual"
          dualToggleProps={{ title: "Visa lösenord i klartext" }}
          {...getFieldProps<string>("password")}
        />
      </EuiFormRow>

      <EuiSpacer />

      <EuiButton type="submit" fill isLoading={isSubmitting}>
        Logga in
      </EuiButton>
    </EuiForm>
  );
}

interface LoginFormProps {
  onLogin(username: string, password: string): Promise<unknown>;
  authState: AuthState;
}

const LoginForm = withFormik<LoginFormProps, LoginFormFields>({
  displayName: "LoginForm",
  mapPropsToValues: () => ({
    username: "",
    password: "",
  }),
  handleSubmit: async (values, { props }) => {
    await props.onLogin(values.username, values.password);
  },
  validate: (values, { authState }) => {
    const errors: FormikErrors<LoginFormFields> = {};

    // Check errors from backend
    if (authState.status === "error") {
      if (
        authState.kind === "bad-username-or-password" &&
        authState.username === values.username &&
        authState.password === values.password
      ) {
        errors.username = "Felaktigt användarnamn...";
        errors.password = "...eller lösenord";
      }
    }

    if (!values.username) {
      errors.username = "Saknas";
    }

    if (!values.password) {
      errors.password = "Saknas";
    } else if (values.password.length < 4) {
      errors.password = "För kort";
    }

    return errors;
  },
})(InnerLoginForm);

export default Login;
