import {
  EuiButton,
  EuiCallOut,
  EuiForm,
  EuiFormRow,
  EuiSwitch,
  EuiTextArea,
} from "@elastic/eui";
import { FormikErrors, FormikProps, withFormik } from "formik";
import {
  ErrorCase,
  ErrorCaseUpdateAddPost,
  ErrorCaseUpdateAddService,
  ErrorCaseUpdateStatusChange,
} from "../../../api/resources/errorCase";
import { Organization } from "../../../api/resources/organization";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import { isLoaded, isLoading } from "../../../api/useService";
import { UserPreferences } from "../../../utils/useUserPreferences";

interface Fields {
  message: string;
  statusChange: ErrorCaseUpdateStatusChange;
  sendUpdateEmail: boolean;
}

type InnerFormProps = {
  authState: AuthStateAuthed;
  organization: Organization;
  errorCase: ErrorCase;
  addUpdateService: ErrorCaseUpdateAddService;
} & FormikProps<Fields>;

const InnerForm = ({
  authState,
  organization,
  addUpdateService,
  errors,
  touched,
  handleSubmit,
  isSubmitting,
  getFieldProps,
  errorCase,
  values,
  setFieldValue,
}: InnerFormProps) => (
  <EuiForm component="form" onSubmit={handleSubmit}>
    <EuiFormRow
      label="Meddelande"
      isInvalid={!!touched.message && !!errors.message}
      error={errors.message}
    >
      <EuiTextArea
        isInvalid={!!touched.message && !!errors.message}
        disabled={isSubmitting}
        {...getFieldProps<string>("message")}
      />
    </EuiFormRow>

    {isAdmin(authState) && (
      <>
        {errorCase.status === "open" && (
          <EuiFormRow>
            <EuiSwitch
              label="Avsluta ärendet"
              checked={values.statusChange === "closed"}
              onChange={(e) =>
                setFieldValue(
                  "statusChange",
                  e.target.checked ? "closed" : "no-change"
                )
              }
            />
          </EuiFormRow>
        )}
        {errorCase.status === "closed" && (
          <EuiFormRow>
            <EuiSwitch
              label="Återöppna ärendet"
              checked={values.statusChange === "open"}
              onChange={(e) =>
                setFieldValue(
                  "statusChange",
                  e.target.checked ? "open" : "no-change"
                )
              }
            />
          </EuiFormRow>
        )}
        {errorCase.updates_email || organization.error_case_emails.length ? (
          <EuiFormRow
            isInvalid={!!touched.sendUpdateEmail && !!errors.sendUpdateEmail}
            error={errors.sendUpdateEmail}
          >
            <EuiSwitch
              label={
                "Skicka uppdatering till " +
                (errorCase.updates_email ||
                  organization.error_case_emails.join(", "))
              }
              checked={values.sendUpdateEmail}
              onChange={(e) =>
                setFieldValue("sendUpdateEmail", e.target.checked)
              }
            />
          </EuiFormRow>
        ) : (
          <EuiFormRow>
            <EuiCallOut
              title="Statusuppdatering kan inte skickas"
              color="warning"
              iconType="iInCircle"
            >
              Ärendet och organisationen saknar mejladress.
            </EuiCallOut>
          </EuiFormRow>
        )}
      </>
    )}

    <EuiButton type="submit" fill isLoading={isLoading(addUpdateService)}>
      Lägg till
    </EuiButton>
  </EuiForm>
);

interface Props {
  authState: AuthStateAuthed;
  organization: Organization;
  errorCase: ErrorCase;
  onSubmit: ErrorCaseUpdateAddPost;
  addUpdateService: ErrorCaseUpdateAddService;
  userPreferences: UserPreferences;
  updateUserPreferences(values: Partial<UserPreferences>): void;
}

export const ErrorCaseUpdateAdd = withFormik<Props, Fields>({
  displayName: "ErrorCaseUpdateAddForm",
  mapPropsToValues: ({ authState, userPreferences }) => ({
    message: "",
    statusChange: "no-change",
    sendUpdateEmail: isAdmin(authState)
      ? userPreferences.sendErrorCaseUpdateEmail
      : true,
  }),
  validate: (values) => {
    const errors: FormikErrors<Fields> = {};

    if (!values.message) {
      errors.message = "Saknas";
    } else {
      const charsOver = values.message.length - 10240;
      if (charsOver > 0) {
        errors.message = `${charsOver} tecken för långt. Försök att korta ned texten.`;
      }
    }

    return errors;
  },
  handleSubmit: async (values, { props, setValues, resetForm }) => {
    const normalized: typeof values = {
      message: values.message.trim(),
      statusChange: values.statusChange,
      sendUpdateEmail: values.sendUpdateEmail,
    };

    setValues(normalized);
    props.updateUserPreferences({
      sendErrorCaseUpdateEmail: normalized.sendUpdateEmail,
    });

    const result = await props.onSubmit(normalized);

    if (isLoaded(result)) {
      resetForm();
    }
  },
})(InnerForm);

export default ErrorCaseUpdateAdd;
