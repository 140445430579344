import { EuiDelayRender, EuiLoadingSpinner } from "@elastic/eui";
import { useEffect } from "react";
import { To, useNavigate } from "react-router-dom";
import { useMonitoredInterfaceAddService } from "../api/resources/monitored_interface";
import { AuthStateAdmin } from "../api/useAuthService";
import { isError, isLoaded } from "../api/useService";
import { ServiceErrorMessage } from "../utils/ErrorMessage";
import useToasts from "../utils/useToasts";

interface Props {
  authState: AuthStateAdmin;
  serviceId: number;
  interfaceId: number;
  redirectTo: To; // Redirect here when done (error or not)
}

export function AddMonitoredInterface({
  authState,
  serviceId,
  interfaceId,
  redirectTo,
}: Props) {
  const navigate = useNavigate();
  const [addMonIfaceService, addMonIface] = useMonitoredInterfaceAddService(
    authState,
    serviceId
  );
  useEffect(() => {
    addMonIface(interfaceId);
  }, [addMonIface, interfaceId]);

  const { addToast } = useToasts();
  useEffect(() => {
    if (isError(addMonIfaceService)) {
      addToast({
        id: "failed-to-add-mon-iface",
        title: "Kunde inte lägga till övervakning av porten",
        text: <ServiceErrorMessage error={addMonIfaceService} />,
        color: "warning",
        iconType: "alert",
      });
    }
  }, [addMonIfaceService, addToast]);

  useEffect(() => {
    if (isError(addMonIfaceService)) {
      navigate(redirectTo);
    } else if (isLoaded(addMonIfaceService)) {
      navigate(redirectTo);
    }
  }, [addMonIfaceService, redirectTo, navigate]);

  return (
    <EuiDelayRender>
      <p>Lägger till övervakning för grafporten...</p>
      <EuiLoadingSpinner />
    </EuiDelayRender>
  );
}

export default AddMonitoredInterface;
