import {
  EuiDescriptionList,
  EuiDescriptionListDescription,
  EuiDescriptionListTitle,
  EuiLink,
  EuiText,
} from "@elastic/eui";
import React from "react";
import { Service } from "../api/resources/service";
import { AuthStateAuthed, isAdmin } from "../api/useAuthService";
import { organizationHref } from "../pages/organizations/view/OrganizationPage";
import {
  alarmPriorityToString,
  combinedAddressString,
  combinedProductString,
} from "../pages/services/service-utils";
import { serviceHref } from "../pages/services/view/ServicePage";
import RouterLinkAdapter from "../utils/RouterLinkAdapter";

interface Props {
  authState: AuthStateAuthed;
  service: Service;
}

const ServiceDescription = ({ authState, service }: Props) => (
  <>
    <EuiText>
      <h3>Förbindelseinfo</h3>
    </EuiText>
    <EuiDescriptionList>
      {isAdmin(authState) && (
        <>
          <EuiDescriptionListTitle>Organisation</EuiDescriptionListTitle>
          <EuiDescriptionListDescription>
            <RouterLinkAdapter to={organizationHref(service.organization_id)}>
              {(linkProps) => (
                <EuiLink {...linkProps}>{service.organization_name}</EuiLink>
              )}
            </RouterLinkAdapter>
          </EuiDescriptionListDescription>
        </>
      )}
      <EuiDescriptionListTitle>Förbindelse</EuiDescriptionListTitle>
      <EuiDescriptionListDescription>
        <RouterLinkAdapter to={serviceHref(service.id)}>
          {(linkProps) => <EuiLink {...linkProps}>{service.number}</EuiLink>}
        </RouterLinkAdapter>
      </EuiDescriptionListDescription>
      <EuiDescriptionListTitle>Förbindelsereferens</EuiDescriptionListTitle>
      <EuiDescriptionListDescription>
        {service.customer_ref || "-"}
      </EuiDescriptionListDescription>
      <EuiDescriptionListTitle>Produkt</EuiDescriptionListTitle>
      <EuiDescriptionListDescription>
        {combinedProductString(service) || "-"}
      </EuiDescriptionListDescription>
      <EuiDescriptionListTitle>Adress</EuiDescriptionListTitle>
      <EuiDescriptionListDescription>
        {combinedAddressString(service) || "-"}
      </EuiDescriptionListDescription>
      {isAdmin(authState) && (
        <>
          <EuiDescriptionListTitle>
            Intern förbindelseinformation
          </EuiDescriptionListTitle>
          <EuiDescriptionListDescription>
            <EuiText size="s" style={{ whiteSpace: "pre-wrap" }}>
              <p>{service.internal_note || "-"}</p>
            </EuiText>
          </EuiDescriptionListDescription>
          <EuiDescriptionListTitle>Larmprioritet</EuiDescriptionListTitle>
          <EuiDescriptionListDescription>
            {(service.alarm_priority &&
              alarmPriorityToString(service.alarm_priority)) ||
              "-"}
          </EuiDescriptionListDescription>
        </>
      )}
    </EuiDescriptionList>
  </>
);

export default ServiceDescription;
