import {
  EuiButton,
  EuiFieldPassword,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
} from "@elastic/eui";
import { FormikErrors, FormikProps, withFormik } from "formik";
import React, { useEffect } from "react";
import { AuthState } from "../../api/useAuthService";
import propertyOf from "../../utils/propertyOf";

interface Fields {
  newPassword: string;
}

type InnerFormProps = {
  authState: AuthState;
  onCancel(): void;
} & FormikProps<Fields>;

function InnerForm({
  authState,
  errors,
  touched,
  handleSubmit,
  isSubmitting,
  getFieldProps,
  setTouched,
  onCancel,
}: InnerFormProps) {
  useEffect(() => {
    if (!authState.isLoading && authState.status === "error") {
      // Setting to touched will force a revalidation and show backend errors
      setTouched({ newPassword: true });
    }
  }, [authState.status, authState.isLoading, setTouched]);

  return (
    <EuiForm component="form" onSubmit={handleSubmit} noValidate>
      {authState.status === "password-expired" && (
        // Help password managers to include the correct username
        <input
          id="username"
          name="username"
          type="text"
          value={authState.username}
          style={{ display: "none" }}
        />
      )}
      <EuiFormRow
        label="Nytt lösenord"
        isInvalid={!!touched.newPassword && !!errors.newPassword}
        error={errors.newPassword}
      >
        <EuiFieldPassword
          autoComplete="new-password"
          disabled={isSubmitting}
          isInvalid={!!touched.newPassword && !!errors.newPassword}
          type="dual"
          dualToggleProps={{ title: "Visa i klartext" }}
          {...getFieldProps<string>(propertyOf<Fields>("newPassword"))}
        />
      </EuiFormRow>

      <EuiSpacer />

      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiButton type="submit" fill isLoading={isSubmitting}>
            Spara
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButton type="button" iconType="arrowLeft" onClick={onCancel}>
            Börja om
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiForm>
  );
}

interface Props {
  authState: AuthState;
  onSubmit(code: string): Promise<unknown>;
  onCancel(): void;
}

export const PasswordChangeForm = withFormik<Props, Fields>({
  displayName: "Login2faForm",
  mapPropsToValues: () => ({
    newPassword: "",
  }),
  validate: (values) => {
    const errors: FormikErrors<Fields> = {};

    if (!values.newPassword) {
      errors.newPassword = "Saknas";
    } else if (values.newPassword.length < 4) {
      errors.newPassword = "För kort";
    }

    return errors;
  },
  handleSubmit: async (values, { props }) => {
    await props.onSubmit(values.newPassword);
  },
})(InnerForm);

export default PasswordChangeForm;
