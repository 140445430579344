import { EuiLink } from "@elastic/eui";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  ErrorCaseListItem,
  useErrorCasesService,
} from "../../../api/resources/errorCase";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import { isLoaded, isLoading } from "../../../api/useService";
import TableCellJson from "../../../table/TableCellJson";
import { parseDateAndFormatShort } from "../../../utils/date";
import RouterLinkAdapter from "../../../utils/RouterLinkAdapter";
import { Table, TableColumn } from "../../../widgets/Table";
import { organizationHref } from "../../organizations/view/OrganizationPage";
import CombinedAddressCell from "../../services/list/CombinedAddressCell";
import CombinedProductCell from "../../services/list/CombinedProductCell";
import {
  combinedAddressString,
  combinedProductString,
  serviceStatusToString,
} from "../../services/service-utils";
import { serviceHref } from "../../services/view/ServicePage";
import UserNameCell from "../../users/UserNameCell";
import { errorCaseHref, errorCaseStatusToString } from "../view/ErrorCasePage";

interface Props {
  authState: AuthStateAuthed;
}

function errorCaseToKeywords(errorCase: ErrorCaseListItem): string[] {
  return [
    [errorCase.number],
    errorCase.organization_name.split(/\s+/),
    errorCase.service
      ? [
          [errorCase.service.number],
          [serviceStatusToString(errorCase.service.status)],
          errorCase.service.customer_ref.split(/\s+/),
          errorCase.service.product_name.split(/\s+/),
          [errorCase.service.product_capacity],
          [errorCase.service.product_technology],
          errorCase.service.a_location_name.split(/\s+/),
          errorCase.service.a_address.split(/\s+/),
          errorCase.service.a_property_designation.split(/\s+/),
          errorCase.service.b_location_name.split(/\s+/),
          errorCase.service.b_address.split(/\s+/),
          errorCase.service.b_property_designation.split(/\s+/),
        ].flat()
      : [],
    errorCase.by_user.full_name.split(/\s+/),
    errorCase.assigned_user
      ? errorCase.assigned_user.full_name.split(/\s+/)
      : [],
    [errorCaseStatusToString(errorCase.status)],
    errorCase.error_description.split(/\s+/),
    [errorCase.creation_time],
    errorCase.close_time ? [errorCase.close_time] : [],
  ].flat();
}

type Column = TableColumn<ErrorCaseListItem> & {
  /** If true, column is only visible for user.group FB-PORTAL-ADMIN */
  adminOnly?: boolean;
};

const COLUMNS: Column[] = [
  {
    type: "field",
    field: "number",
    name: "Ärende",
    dataType: "string",
    render: (_, errorCase) => (
      <RouterLinkAdapter to={errorCaseHref(errorCase.id)}>
        {(linkProps) => <EuiLink {...linkProps}>{errorCase.number}</EuiLink>}
      </RouterLinkAdapter>
    ),
  },
  {
    type: "field",
    field: "organization_name",
    name: "Organisation",
    dataType: "string",
    render: (_, errorCase) => (
      <RouterLinkAdapter to={organizationHref(errorCase.organization_id)}>
        {(linkProps) => (
          <EuiLink {...linkProps}>{errorCase.organization_name}</EuiLink>
        )}
      </RouterLinkAdapter>
    ),
    adminOnly: true,
  },
  {
    type: "field",
    field: "assigned_user",
    name: "Ansvarig",
    render: (_, errorCase) =>
      errorCase.assigned_user ? (
        <UserNameCell user={errorCase.assigned_user} />
      ) : (
        ""
      ),
    csvValueFn: (errorCase) =>
      errorCase.assigned_user ? errorCase.assigned_user.full_name : "",
    dataType: "string",
    adminOnly: true,
  },
  {
    type: "field",
    field: "status",
    name: "Status",
    render: (_, errorCase) => errorCaseStatusToString(errorCase.status),
    csvValueFn: (errorCase) => errorCaseStatusToString(errorCase.status),
    dataType: "string",
  },
  {
    type: "field",
    field: "by_user.full_name",
    name: "Skapad av",
    render: (_, errorCase) => <UserNameCell user={errorCase.by_user} />,
    csvValueFn: (errorCase) => errorCase.by_user.full_name,
    dataType: "string",
  },
  {
    type: "field",
    field: "creation_time",
    name: "Skapad",
    dataType: "date",
    render: (_, errorCase) => parseDateAndFormatShort(errorCase.creation_time),
    csvValueFn: (errorCase) => parseDateAndFormatShort(errorCase.creation_time),
  },
  {
    type: "field",
    field: "close_time",
    name: "Stängd",
    dataType: "date",
    render: (_, errorCase) =>
      errorCase.close_time ? parseDateAndFormatShort(errorCase.close_time) : "",
    csvValueFn: (errorCase) =>
      errorCase.close_time
        ? parseDateAndFormatShort(errorCase.close_time)
        : null,
  },
  {
    type: "field",
    field: "error_description",
    name: "Felbeskrivning",
    dataType: "string",
    sortable: false,
  },
  {
    type: "field",
    field: "service.number",
    name: "Förbindelse",
    render: (_, errorCase) =>
      errorCase.service && (
        <RouterLinkAdapter to={serviceHref(errorCase.service.id)}>
          {(linkProps) => (
            <EuiLink {...linkProps}>{errorCase.service?.number}</EuiLink>
          )}
        </RouterLinkAdapter>
      ),
    dataType: "string",
  },
  {
    // Combined product description from name, capacity and technology
    type: "computed",
    field: "product_combined",
    name: "Produkt (kombinerad)",
    render: (errorCase) =>
      errorCase.service && (
        <CombinedProductCell service={errorCase.service} isDetails={false} />
      ),
    csvValueFn: (errorCase) =>
      errorCase.service && combinedProductString(errorCase.service),
  },
  {
    type: "field",
    field: "service.product_name",
    name: "Produkt",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "service.product_capacity",
    name: "Kapacitet",
    dataType: "string",
    hideByDefault: true,
  },
  {
    type: "field",
    field: "service.product_technology",
    name: "Teknik",
    dataType: "string",
    hideByDefault: true,
  },
  {
    // Combined a and b-side (if any) address
    type: "computed",
    field: "address_combined",
    name: "Adress (kombinerad)",
    render: (errorCase) =>
      errorCase.service && (
        <CombinedAddressCell service={errorCase.service} isDetails={false} />
      ),
    csvValueFn: (errorCase) =>
      errorCase.service && combinedAddressString(errorCase.service),
  },
  {
    type: "field",
    field: "service.customer_ref",
    name: "Förb.referens",
    dataType: "string",
  },
  {
    type: "field",
    field: "service.status",
    name: "Förb.status",
    render: (_, errorCase) =>
      errorCase.service ? serviceStatusToString(errorCase.service.status) : "",
    csvValueFn: (errorCase) =>
      errorCase.service && serviceStatusToString(errorCase.service.status),
    dataType: "string",
  },
  {
    type: "field",
    field: "json",
    name: "JSON",
    dataType: "string",
    adminOnly: true,
    hideByDefault: true,
    render: (_, errorCase) => <TableCellJson data={errorCase} />,
    csvValueFn: JSON.stringify,
    sortable: false,
  },
];

const empyErrorCases: ErrorCaseListItem[] = [];

export function ErrorCasesTable({ authState }: Props) {
  const errorCasesService = useErrorCasesService(authState);
  const errorCases = isLoaded(errorCasesService)
    ? errorCasesService.payload.items
    : empyErrorCases;

  // Apply filters from search params
  const { search } = useLocation();
  const filteredErrorCases = useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    let result = errorCases;

    // Filter for assigned_user_id
    const assignedUserFilter = urlSearchParams.getAll("assigned_user_id");
    if (assignedUserFilter.length > 0) {
      result = result.filter((errorCase) => {
        const id = errorCase.assigned_user
          ? errorCase.assigned_user.id.toString()
          : "null";
        return assignedUserFilter.includes(id);
      });
    }

    // Apply filter for organization
    const organizationFilter = urlSearchParams.getAll("organization_id");
    if (organizationFilter.length > 0) {
      result = result.filter((errorCase) =>
        organizationFilter.includes(errorCase.organization_id.toString())
      );
    }

    // Filter for status
    const statusFilter = urlSearchParams.getAll("status");
    if (statusFilter.length > 0) {
      result = result.filter((errorCase) =>
        statusFilter.includes(errorCase.status)
      );
    }

    return result;
  }, [errorCases, search]);

  // Filter out column that are only useful for admins
  const columns = useMemo(() => {
    if (isAdmin(authState)) {
      return COLUMNS;
    } else {
      return COLUMNS.filter((column) => !column?.adminOnly);
    }
  }, [authState]);

  return (
    <Table
      items={filteredErrorCases}
      itemToKeywords={errorCaseToKeywords}
      columns={columns}
      preferencesKey="error-cases"
      loading={isLoading(errorCasesService)}
    />
  );
}

export default ErrorCasesTable;
