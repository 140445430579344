import {
  EuiButton,
  EuiCallOut,
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSelectOption,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTextArea,
} from "@elastic/eui";
import { FormikErrors, FormikProps, withFormik } from "formik";
import moment, { Moment } from "moment";
import "moment/locale/sv";
import React from "react";
import { NetmonCoreInterface } from "../../../api/resources/netmon_core_interface";
import {
  AlarmPriority,
  Service,
  ServiceStatus,
} from "../../../api/resources/service";
import { AuthStateAdmin } from "../../../api/useAuthService";
import SelectRow from "../../../utils/form/SelectRow";
import propertyOf from "../../../utils/propertyOf";
import NetmonCoreInterfaceSelect from "../../../widgets/NetmonCoreInterfaceSelect";
import { alarmPriorityOptions, serviceStatusToString } from "../service-utils";

export interface ServiceEditFields {
  status: ServiceStatus;
  productName: string;
  productCapacity: string;
  productTechnology: string;
  internalNote: string;
  alarmPriority: AlarmPriority;
  customerRef: string;
  aGraphInterface: NetmonCoreInterface | null;
  monitorAGraphInterface: boolean;
  aLocationName: string;
  aAddress: string;
  aDemarcation: string;
  aPropertyDesignation: string;
  aToBMeasurement: string;
  bLocationName: string;
  bAddress: string;
  bDemarcation: string;
  bPropertyDesignation: string;
  bToAMeasurement: string;
  deliveryDate: Moment | null;
  terminationDate: Moment | null;
}

interface InnerFormProps extends FormikProps<ServiceEditFields> {
  authState: AuthStateAdmin;
  service: Service;
}

const statusOptions: EuiSelectOption[] = [
  { value: "active", text: serviceStatusToString("active") },
  { value: "inactive", text: serviceStatusToString("inactive") },
];

const InnerForm = ({
  authState,
  service,
  values,
  errors,
  handleSubmit,
  isSubmitting,
  getFieldProps,
  setFieldValue,
  setFieldTouched,
}: InnerFormProps) => (
  <EuiForm component="form" onSubmit={handleSubmit}>
    <EuiFlexGroup wrap>
      <EuiFlexItem style={{ minWidth: 300 }}>
        <EuiText>
          <h3>Förbindelseinfo</h3>
        </EuiText>
        <EuiFormRow label="Organisation">
          <EuiFieldText value={service.organization_name} readOnly />
        </EuiFormRow>
        <EuiFormRow label="Förbindelsenummer">
          <EuiFieldText value={service.number} readOnly />
        </EuiFormRow>
        <EuiFormRow
          label="Förbindelsereferens"
          helpText="Fyll i om ni vill ha en egen referens på förbindelsen."
          isInvalid={!!errors.customerRef}
          error={errors.customerRef}
        >
          <EuiFieldText {...getFieldProps<string>("customerRef")} />
        </EuiFormRow>
        <SelectRow
          name={propertyOf<ServiceEditFields>("status")}
          label="Status"
          options={statusOptions}
        />
        <EuiFormRow
          label="Produkt"
          isInvalid={!!errors.productName}
          error={errors.productName}
        >
          <EuiFieldText {...getFieldProps<string>("productName")} />
        </EuiFormRow>
        <EuiFormRow
          label="Kapacitet"
          isInvalid={!!errors.productCapacity}
          error={errors.productCapacity}
        >
          <EuiFieldText
            {...getFieldProps<string>(
              propertyOf<ServiceEditFields>("productCapacity")
            )}
          />
        </EuiFormRow>
        <EuiFormRow
          label="Teknik"
          isInvalid={!!errors.productTechnology}
          error={errors.productTechnology}
        >
          <EuiFieldText
            {...getFieldProps<string>(
              propertyOf<ServiceEditFields>("productTechnology")
            )}
          />
        </EuiFormRow>
        <EuiFormRow
          label="Startdatum"
          helpText="Dagen då förbindelsen levererades."
          isInvalid={!!errors.deliveryDate}
          error={errors.deliveryDate}
        >
          <EuiDatePicker
            selected={values.deliveryDate}
            onClear={() => setFieldValue("deliveryDate", null)}
            onChange={(v) => setFieldValue("deliveryDate", v)}
            onBlur={() => setFieldTouched("deliveryDate", true)}
            dateFormat="YYYY-MM-DD"
          />
        </EuiFormRow>
        <EuiFormRow
          label="Slutdatum"
          helpText="Dagen då förbindelsen kopplades ned (eller slutade faktureras)."
          isInvalid={!!errors.terminationDate}
          error={errors.terminationDate}
        >
          <EuiDatePicker
            selected={values.terminationDate}
            onClear={() => setFieldValue("terminationDate", null)}
            onChange={(v) => setFieldValue("terminationDate", v)}
            onBlur={() => setFieldTouched("terminationDate", true)}
            dateFormat="YYYY-MM-DD"
          />
        </EuiFormRow>
        <EuiFormRow
          label="Intern information"
          helpText="Extra information om förbindelsen. Ej synligt för kund."
          isInvalid={!!errors.internalNote}
          error={errors.internalNote}
        >
          <EuiTextArea {...getFieldProps<string>("internalNote")} />
        </EuiFormRow>
        <SelectRow
          name={propertyOf<ServiceEditFields>("alarmPriority")}
          label="Larmprioritet"
          options={alarmPriorityOptions}
        />
      </EuiFlexItem>

      <EuiFlexItem style={{ minWidth: 300 }}>
        <EuiText>
          <h3>A-sida</h3>
        </EuiText>
        <EuiFormRow
          label="Platsnamn"
          helpText="T.ex. Småskolan, Vattentornet eller Nod 1234."
          isInvalid={!!errors.aLocationName}
          error={errors.aLocationName}
        >
          <EuiFieldText {...getFieldProps<string>("aLocationName")} />
        </EuiFormRow>
        <EuiFormRow
          label="Adress"
          helpText="T.ex. Skolstigen 2C Lgh 1003, 234 56 Enköping."
          isInvalid={!!errors.aAddress}
          error={errors.aAddress}
        >
          <EuiFieldText {...getFieldProps<string>("aAddress")} />
        </EuiFormRow>
        <EuiFormRow
          label="Avlämning"
          isInvalid={!!errors.aDemarcation}
          error={errors.aDemarcation}
        >
          <EuiFieldText {...getFieldProps<string>("aDemarcation")} />
        </EuiFormRow>

        <EuiFormRow
          label="Fastighetsbeteckning"
          isInvalid={!!errors.aPropertyDesignation}
          error={errors.aPropertyDesignation}
        >
          <EuiFieldText {...getFieldProps<string>("aPropertyDesignation")} />
        </EuiFormRow>
        <EuiFormRow
          label="Mätresultat A"
          isInvalid={!!errors.aToBMeasurement}
          error={errors.aToBMeasurement}
        >
          <EuiTextArea
            rows={2}
            {...getFieldProps<string>(
              propertyOf<ServiceEditFields>("aToBMeasurement")
            )}
          />
        </EuiFormRow>
        <EuiFormRow
          label="Switchport för trafikgraf"
          isInvalid={!!errors.aGraphInterface}
          error={errors.aGraphInterface}
          fullWidth
        >
          <NetmonCoreInterfaceSelect
            authState={authState}
            selectedInterface={values.aGraphInterface}
            onSelect={(iface) =>
              setFieldValue(
                propertyOf<ServiceEditFields>("aGraphInterface"),
                iface
              )
            }
            onSelectNone={() =>
              setFieldValue(
                propertyOf<ServiceEditFields>("aGraphInterface"),
                null
              )
            }
          />
        </EuiFormRow>
        <EuiFormRow
          isInvalid={!!errors.monitorAGraphInterface}
          error={errors.monitorAGraphInterface}
        >
          <EuiSwitch
            disabled={!values.aGraphInterface}
            label="Lägg till larmövervakning för porten"
            checked={values.monitorAGraphInterface}
            onChange={(e) => {
              setFieldValue("monitorAGraphInterface", e.target.checked);
            }}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiSpacer />
      <EuiFlexItem style={{ minWidth: 300 }}>
        <EuiText>
          <h3>B-sida</h3>
        </EuiText>
        <EuiFormRow
          label="Platsnamn"
          helpText="T.ex. Småskolan, Vattentornet eller Nod 1234."
          isInvalid={!!errors.bLocationName}
          error={errors.bLocationName}
        >
          <EuiFieldText {...getFieldProps<string>("bLocationName")} />
        </EuiFormRow>
        <EuiFormRow
          label="Adress"
          helpText="T.ex. Skolstigen 2C Lgh 1003, 234 56 Enköping."
          isInvalid={!!errors.bAddress}
          error={errors.bAddress}
        >
          <EuiFieldText {...getFieldProps<string>("bAddress")} />
        </EuiFormRow>
        <EuiFormRow
          label="Avlämning"
          isInvalid={!!errors.bDemarcation}
          error={errors.bDemarcation}
        >
          <EuiFieldText {...getFieldProps<string>("bDemarcation")} />
        </EuiFormRow>
        <EuiFormRow
          label="Fastighetsbeteckning"
          isInvalid={!!errors.bPropertyDesignation}
          error={errors.bPropertyDesignation}
        >
          <EuiFieldText {...getFieldProps<string>("bPropertyDesignation")} />
        </EuiFormRow>
        <EuiFormRow
          label="Mätresultat B"
          isInvalid={!!errors.bToAMeasurement}
          error={errors.bToAMeasurement}
        >
          <EuiTextArea
            rows={2}
            {...getFieldProps<string>(
              propertyOf<ServiceEditFields>("bToAMeasurement")
            )}
          />
        </EuiFormRow>
        <EuiFormRow>
          <EuiCallOut
            iconType="help"
            title="B-sida behövs bara för produkter med två sidor."
            size="s"
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
    <EuiSpacer />

    <EuiButton iconType="save" type="submit" fill isLoading={isSubmitting}>
      Spara
    </EuiButton>
  </EuiForm>
);

interface Props {
  service: Service;
  onSubmit: (params: ServiceEditFields) => Promise<unknown>;
  authState: AuthStateAdmin;
}

export const ServiceEditForm = withFormik<Props, ServiceEditFields>({
  displayName: "ServiceEditForm",
  mapPropsToValues: ({ service }) => ({
    status: service.status,
    productName: service.product_name,
    productCapacity: service.product_capacity,
    productTechnology: service.product_technology,
    internalNote: service.internal_note || "",
    alarmPriority: service.alarm_priority || "high",
    customerRef: service.customer_ref,
    aGraphInterface: service.a_graph_interface,
    monitorAGraphInterface: true,
    aLocationName: service.a_location_name,
    aAddress: service.a_address,
    aDemarcation: service.a_demarcation,
    aPropertyDesignation: service.a_property_designation,
    aToBMeasurement: service.a_to_b_measurement,
    bLocationName: service.b_location_name,
    bAddress: service.b_address,
    bDemarcation: service.b_demarcation,
    bPropertyDesignation: service.b_property_designation,
    bToAMeasurement: service.b_to_a_measurement,
    deliveryDate: service.delivery_date ? moment(service.delivery_date) : null,
    terminationDate: service.termination_date
      ? moment(service.termination_date)
      : null,
  }),
  validate: (values) => {
    const errors: FormikErrors<ServiceEditFields> = {};

    if (values.productName) {
      if (values.productName.length > 64) {
        const charsOver = values.productName.length - 64;
        errors.productName = `${charsOver} tecken för långt`;
      }
    } else if (values.status === "active") {
      errors.productName = "Krävs när förbindelsestatus är aktiv";
    }

    if (values.productCapacity) {
      const charsOver = values.productCapacity.length - 64;
      if (charsOver > 0) {
        errors.productCapacity = `${charsOver} tecken för långt`;
      }
    }

    if (values.productTechnology) {
      const charsOver = values.productTechnology.length - 64;
      if (charsOver > 0) {
        errors.productTechnology = `${charsOver} tecken för långt`;
      }
    }

    if (values.internalNote.length > 2048) {
      const charsOver = values.internalNote.length - 2048;
      errors.internalNote = `${charsOver} tecken för långt`;
    }

    if (values.customerRef && values.customerRef.length > 64) {
      const charsOver = values.customerRef.length - 64;
      errors.customerRef = `${charsOver} tecken för långt`;
    }

    if (values.aLocationName && values.aLocationName.length > 64) {
      const charsOver = values.aLocationName.length - 64;
      errors.aLocationName = `${charsOver} tecken för långt`;
    }

    if (values.aAddress && values.aAddress.length > 128) {
      const charsOver = values.aAddress.length - 128;
      errors.aAddress = `${charsOver} tecken för långt`;
    }

    if (values.aDemarcation && values.aDemarcation.length > 256) {
      const charsOver = values.aDemarcation.length - 256;
      errors.aDemarcation = `${charsOver} tecken för långt`;
    }

    if (
      values.aPropertyDesignation &&
      values.aPropertyDesignation.length > 128
    ) {
      const charsOver = values.aPropertyDesignation.length - 128;
      errors.aPropertyDesignation = `${charsOver} tecken för långt`;
    }

    const aToBMeasurementCharsOver = values.aToBMeasurement.length - 64;
    if (aToBMeasurementCharsOver > 0) {
      errors.aToBMeasurement = `${aToBMeasurementCharsOver} tecken för långt`;
    }

    if (values.bLocationName && values.bLocationName.length > 64) {
      const charsOver = values.bLocationName.length - 64;
      errors.bLocationName = `${charsOver} tecken för långt`;
    }

    if (values.bAddress && values.bAddress.length > 128) {
      const charsOver = values.bAddress.length - 128;
      errors.bAddress = `${charsOver} tecken för långt`;
    }

    if (values.bDemarcation && values.bDemarcation.length > 256) {
      const charsOver = values.bDemarcation.length - 256;
      errors.bDemarcation = `${charsOver} tecken för långt`;
    }

    if (
      values.bPropertyDesignation &&
      values.bPropertyDesignation.length > 128
    ) {
      const charsOver = values.bPropertyDesignation.length - 128;
      errors.bPropertyDesignation = `${charsOver} tecken för långt`;
    }

    const bToAMeasurementCharsOver = values.bToAMeasurement.length - 64;
    if (bToAMeasurementCharsOver > 0) {
      errors.bToAMeasurement = `${bToAMeasurementCharsOver} tecken för långt`;
    }

    if (!values.deliveryDate && values.status === "active") {
      errors.deliveryDate = "Måste anges när förbindelsen är aktiv";
    }

    if (values.terminationDate && values.status === "active") {
      errors.terminationDate = "Får bara anges när förbindelsen är inaktiv";
    }

    return errors;
  },
  validateOnMount: true,
  handleSubmit: async (values, { props, setValues }) => {
    const normalized: typeof values = {
      status: values.status,
      productName: values.productName.trim(),
      productCapacity: values.productCapacity.trim(),
      productTechnology: values.productTechnology.trim(),
      internalNote: values.internalNote.trim(),
      alarmPriority: values.alarmPriority,
      customerRef: values.customerRef.trim(),
      aGraphInterface: values.aGraphInterface,
      monitorAGraphInterface: values.monitorAGraphInterface,
      aLocationName: values.aLocationName.trim(),
      aAddress: values.aAddress.trim(),
      aDemarcation: values.aDemarcation.trim(),
      aPropertyDesignation: values.aPropertyDesignation.trim(),
      aToBMeasurement: values.aToBMeasurement.trim(),
      bLocationName: values.bLocationName.trim(),
      bAddress: values.bAddress.trim(),
      bDemarcation: values.bDemarcation.trim(),
      bPropertyDesignation: values.bPropertyDesignation.trim(),
      bToAMeasurement: values.bToAMeasurement.trim(),
      deliveryDate: values.deliveryDate,
      terminationDate: values.terminationDate,
    };

    setValues(normalized);

    await props.onSubmit(normalized);
  },
})(InnerForm);

export default ServiceEditForm;
