import React from "react";
import * as graph from "../api/graph";
import { Service, useServiceGraphService } from "../api/resources/service";
import { AuthStateAuthed } from "../api/useAuthService";
import TrafficChart from "./TrafficChart";

interface Props {
  authState: AuthStateAuthed;
  service: Service;
  rangeName: graph.Range;
}

export function ServiceTrafficChart({ authState, service, rangeName }: Props) {
  const graphService = useServiceGraphService(authState, service.id, rangeName);

  return <TrafficChart graphService={graphService} rangeName={rangeName} />;
}

export default ServiceTrafficChart;
