import { EuiComboBox, EuiComboBoxOptionOption, EuiFormRow } from "@elastic/eui";
import { useField } from "formik";

export interface Props {
  /** Field name in Formik */
  name: string;
  label: string;
  placeholder: string;
  /** Options chown in the dropdown.
   * Dropdown is removed if excluded or undefined */
  suggestions?: string[] | undefined;
}

export function ComboRow({ label, name, placeholder, suggestions }: Props) {
  const [field, meta, helper] = useField<string[]>({ name, multiple: true });

  const comboOptions = suggestions
    ?.filter((suggestion) => !field.value.includes(suggestion))
    .map((label) => ({ label }) as EuiComboBoxOptionOption<string>);

  function onCreateOption(searchValue: string) {
    const trimmed = searchValue.trim();
    if (trimmed.length === 0 || field.value.includes(trimmed)) {
      return false;
    }
    helper.setValue([...field.value, trimmed]);
  }

  return (
    <EuiFormRow label={label} isInvalid={!!meta.error} error={meta.error}>
      <EuiComboBox<string>
        placeholder={placeholder}
        isClearable
        isInvalid={!!meta.error}
        noSuggestions={comboOptions === undefined}
        options={comboOptions}
        onChange={(options) => helper.setValue(options.map((o) => o.label))}
        selectedOptions={field.value.map((label) => ({ label }))}
        onCreateOption={onCreateOption}
        onBlur={field.onBlur}
      />
    </EuiFormRow>
  );
}

export default ComboRow;
