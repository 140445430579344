import { EuiErrorBoundary, EuiFlexGroup, EuiPageTemplate } from "@elastic/eui";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { AuthState } from "../../api/useAuthService";
import PasswordChangeForm from "./PasswordChangeForm";

interface Props {
  authState: AuthState;
  onPasswordChange: (newPassword: string) => Promise<unknown>;
  onCancel(): void;
}

export function PasswordChangePage({
  authState,
  onPasswordChange,
  onCancel,
}: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Byt lösenord - Portalen";
  });

  useEffect(() => {
    if (authState.status === "authed") {
      // Navigate to the requested page, if any
      navigate(location.state?.redirect || "/");
    } else if (authState.status === "require-2fa") {
      // Continue the 2fa login
      navigate("/logga-in-2fa", { state: location.state });
    } else if (
      authState.status === "unauthed" ||
      authState.status === "error"
    ) {
      // Needs a regular login first
      navigate("/logga-in", { state: location.state });
    }
  }, [authState.status, location.state, navigate]);

  return (
    <EuiFlexGroup gutterSize="none" style={{ minHeight: "70vh" }}>
      <EuiErrorBoundary>
        <EuiPageTemplate>
          <EuiPageTemplate.EmptyPrompt>
            <PasswordChangeForm
              authState={authState}
              onSubmit={onPasswordChange}
              onCancel={onCancel}
            />
          </EuiPageTemplate.EmptyPrompt>
        </EuiPageTemplate>
      </EuiErrorBoundary>
    </EuiFlexGroup>
  );
}

export default PasswordChangePage;
