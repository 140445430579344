import {
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiSwitch,
  EuiTextArea,
} from "@elastic/eui";
import emailValidator from "email-validator";
import { FormikErrors, FormikProps, withFormik } from "formik";
import React from "react";
import { ErrorCaseAddService } from "../../../api/resources/errorCase";
import { Organization } from "../../../api/resources/organization";
import { AuthStateAuthed, isAdmin } from "../../../api/useAuthService";
import DefaultOrTextRow from "../../../utils/form/DefaultOrTextRow";
import SelectUserRow from "../../../utils/form/SelectUserRow";
import propertyOf from "../../../utils/propertyOf";
import { UserPreferences } from "../../../utils/useUserPreferences";

export interface ErrorCaseCreateFields {
  errorDescription: string;
  updatesEmail: string | null;
  sendUpdateEmail: boolean;
  assignedUserId: string;
}

type InnerFormProps = {
  authState: AuthStateAuthed;
  organization: Organization;
} & FormikProps<ErrorCaseCreateFields>;

const InnerForm = ({
  authState,
  organization,
  setFieldValue,
  values,
  errors,
  touched,
  handleSubmit,
  isSubmitting,
  getFieldProps,
}: InnerFormProps) => (
  <EuiForm component="form" onSubmit={handleSubmit}>
    <EuiFormRow
      label="Felbeskrivning"
      isInvalid={!!touched.errorDescription && !!errors.errorDescription}
      error={errors.errorDescription}
    >
      <EuiTextArea
        isInvalid={!!touched.errorDescription && !!errors.errorDescription}
        disabled={isSubmitting}
        {...getFieldProps<string>("errorDescription")}
      />
    </EuiFormRow>
    {isAdmin(authState) && (
      <SelectUserRow
        authState={authState}
        label="Ansvarig"
        name={propertyOf<ErrorCaseCreateFields>("assignedUserId")}
        defaultUserId={authState.user.id}
      />
    )}
    <DefaultOrTextRow
      name={propertyOf<ErrorCaseCreateFields>("updatesEmail")}
      label="Mejladress för statusuppdateringar"
      labelDefault={`Organisationens mejladress: ${
        organization.error_case_emails.join(", ") || "saknas"
      }`}
      labelSpecify="Angiven:"
      inputMode="email"
    />
    {isAdmin(authState) && (
      <EuiFormRow
        isInvalid={!!touched.sendUpdateEmail && !!errors.sendUpdateEmail}
        error={errors.sendUpdateEmail}
      >
        <EuiSwitch
          label="Skicka bekräftelse till mejladressen ovan"
          checked={values.sendUpdateEmail}
          onChange={(e) => setFieldValue("sendUpdateEmail", e.target.checked)}
        />
      </EuiFormRow>
    )}

    <EuiSpacer />

    <EuiButton type="submit" fill isLoading={isSubmitting}>
      Felanmäl
    </EuiButton>
  </EuiForm>
);

interface CreateErrorCaseFormProps {
  onSubmit: (fields: ErrorCaseCreateFields) => Promise<unknown>;
  createErrorCaseService: ErrorCaseAddService;
  authState: AuthStateAuthed;
  organization: Organization;
  userPreferences: UserPreferences;
  updateUserPreferences(values: Partial<UserPreferences>): void;
}

export const ErrorCaseCreateForm = withFormik<
  CreateErrorCaseFormProps,
  ErrorCaseCreateFields
>({
  displayName: "CreateErrorCaseForm",
  mapPropsToValues: ({ authState, userPreferences }) => ({
    serviceId: "",
    errorDescription: "",
    updatesEmail: null,
    sendUpdateEmail: isAdmin(authState)
      ? userPreferences.sendErrorCaseUpdateEmail
      : true,
    assignedUserId: "",
  }),
  validate: (values) => {
    const errors: FormikErrors<ErrorCaseCreateFields> = {};

    if (!values.errorDescription) {
      errors.errorDescription = "Saknas";
    } else {
      const charsOver = values.errorDescription.length - 10240;
      if (charsOver > 0) {
        errors.errorDescription = `${charsOver} tecken för långt. Försök att korta ned texten.`;
      }
    }

    if (values.updatesEmail !== null) {
      if (!values.updatesEmail) {
        errors.updatesEmail = "Saknas";
      } else if (values.updatesEmail.length > 128) {
        const charsOver = values.updatesEmail.length - 128;
        errors.updatesEmail = `${charsOver} tecken för långt`;
      } else if (!emailValidator.validate(values.updatesEmail)) {
        errors.updatesEmail = "Ogiltigt format";
      }
    }

    return errors;
  },
  handleSubmit: async (values, { props, setValues }) => {
    const normalized: typeof values = {
      errorDescription: values.errorDescription.trim(),
      updatesEmail: values.updatesEmail && values.updatesEmail.trim(),
      sendUpdateEmail: values.sendUpdateEmail,
      assignedUserId: values.assignedUserId,
    };

    setValues(normalized);
    props.updateUserPreferences({
      sendErrorCaseUpdateEmail: normalized.sendUpdateEmail,
    });

    await props.onSubmit(normalized);
  },
})(InnerForm);

export default ErrorCaseCreateForm;
