import { useCallback, useEffect } from "react";
import useCache from "../../utils/useCache";
import { AuthStateAdmin } from "../useAuthService";
import {
  buildApiHref,
  isLoaded,
  useAutoGetService,
  useService,
} from "../useService";
import { NetmonCoreInterface } from "./netmon_core_interface";

export interface MonitoredInterface {
  interface: NetmonCoreInterface;
}

interface ListResponse {
  items: MonitoredInterface[];
}

export const monitoredInterfacesApiHref = (serviceId: number) =>
  buildApiHref(`services/${serviceId}/monitored_interfaces`);

export const useMonitoredInterfacesService = (
  authState: AuthStateAdmin,
  serviceId: number
) =>
  useAutoGetService<ListResponse>(
    authState,
    monitoredInterfacesApiHref(serviceId)
  );

export const monitoredInterfaceApiHref = (
  serviceId: number,
  interfaceId: number
) => buildApiHref(`services/${serviceId}/monitored_interfaces/${interfaceId}`);

export const useMonitoredInterfaceAddService = (
  authState: AuthStateAdmin,
  serviceId: number
) => {
  const { service, put: origPut } = useService(authState);

  const put = useCallback(
    (interfaceId: number) =>
      origPut(monitoredInterfaceApiHref(serviceId, interfaceId), undefined),
    [origPut, serviceId]
  );

  const { removeCache } = useCache();
  useEffect(() => {
    if (isLoaded(service)) {
      // Extra cache invalidation
      removeCache(monitoredInterfacesApiHref(serviceId));
    }
  }, [service, serviceId, removeCache]);

  return [service, put] as const;
};

export const useMonitoredInterfaceDeleteService = (
  authState: AuthStateAdmin,
  serviceId: number
) => {
  const { service, remove: origRemove } = useService(authState);

  const remove = useCallback(
    (interfaceId: number) =>
      origRemove(monitoredInterfaceApiHref(serviceId, interfaceId)),
    [origRemove, serviceId]
  );

  const { removeCache } = useCache<ListResponse>();
  useEffect(() => {
    if (isLoaded(service)) {
      // Extra cache invalidation
      removeCache(monitoredInterfacesApiHref(serviceId));
    }
  }, [service, serviceId, removeCache]);

  return [service, remove] as const;
};
