import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@elastic/eui";
import React, { useEffect } from "react";
import {
  TrustedDevice,
  useTrustedDeviceUpdateService,
} from "../../../api/resources/trusted_device";
import { AuthStateAuthed } from "../../../api/useAuthService";
import { isError, isLoaded, isLoading } from "../../../api/useService";
import { ServiceErrorMessage } from "../../../utils/ErrorMessage";
import TrustedDeviceEditForm from "./TrustedDeviceEditForm";

interface Props {
  authState: AuthStateAuthed;
  trustedDevice: TrustedDevice;
  onClose(): void;
}

export function TrustedDeviceEditModal({
  authState,
  trustedDevice,
  onClose,
}: Props) {
  const [updateService, update] = useTrustedDeviceUpdateService(
    authState,
    trustedDevice.id
  );

  // Close when update finished
  useEffect(() => {
    if (isLoaded(updateService)) {
      onClose();
    }
  }, [updateService, onClose]);

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Ändra betrodd enhet</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        {isError(updateService) && (
          <EuiCallOut title="Misslyckades" color="danger" iconType="alert">
            <ServiceErrorMessage error={updateService} />
          </EuiCallOut>
        )}
        <TrustedDeviceEditForm
          authState={authState}
          trustedDevice={trustedDevice}
          form="editTrustedDeviceFormId"
          onSubmit={update}
        />
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Avbryt</EuiButtonEmpty>
        <EuiButton
          type="submit"
          form="editTrustedDeviceFormId"
          fill
          isLoading={isLoading(updateService)}
        >
          Spara
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

export default TrustedDeviceEditModal;
